import React, { Fragment } from 'react';
import { logo } from '../data/index';
import { Link } from 'react-router-dom';
const Logo = () => {
  return (
    <Fragment>
      <Link id="href-logo" to="/home" className="navbar-brand">
        <img alt="logo" src={logo} />
        <span className="rewards-title-logo">Rewards</span>
      </Link>
    </Fragment>
  );
};
export default Logo;
