import React from 'react';
import { useSelector } from 'react-redux';
import 'react-multi-carousel/lib/styles.css';
import PortalPointList from '../../components/PortalPointList';
import EligibleMoviesNew from '../../components/EligibleMoviesNew';
import { Container, Row, Col } from 'react-bootstrap';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import CarouselCustom from '../../components/CarouselCustom';
import { Link } from 'react-router-dom';
import FAQ from '../../components/FAQ';
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function Home(props) {
  const user = useSelector(({ user }) => user);

  if (user.accessToken) {
    return (
      <>
        {/* To Banner : Start */}
        <div className="banner-wrapper">
          <CarouselCustom />
        </div>
        {/* To Banner : End */}

        <div className="wahtsnew_section page-section">
          {/* What's New : Start */}
          <div className="mb-5">
            <PortalPointList pagesize="500" section="WhatsNew" page="home" />
          </div>
          <div className="mb-5">
            <EligibleMoviesNew />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        {/* <CarouselCustom />
			<PortalPointList pagesize={4} />
			<Contests pagesize={4} /> */}

        <div className="banner-wrapper">
          <div className="hero-banner white">
            <img alt="Universal" style={{ maxWidth: '100%', height: 'auto' }} src="/public/assets/images/main-banner.webp" />
          </div>
          {/* <CarouselCustom /> */}
        </div>
        <div className="page-section dw-body">
          <Container fluid className="text-center">
            <h1 className="home-heading text-center">USE POINTS TO GET MORE MOVIES</h1>
            <Link to="/register" className="btn btn-primary" tabindex="0">
              START NOW
            </Link>
            <p className="text-white">
              Already registered?{' '}
              <Link to="/login" className="text-white underline">
                Login now
              </Link>
            </p>
          </Container>
          <div>
            <Row>
              <Col md={4} sm={12} xs={12} className="img-perks">
                <img alt="" src="/public/assets/images/boy-with-dragon.webp" />
              </Col>
              <Col md={7} sm={12} xs={12}>
                <div className="perks-content-un perks-content-4">
                  <h1>New Movies Every Month!</h1>
                  <p className="font-weight-bold">Select from a wide assortment of movies to expand your digital movie collection – everything from recent releases to unforgettable classics to fun family flicks.</p>
                  <span className="mt-2 mb-2">
                    <a className="btn btn-default" href="/register">
                      <span>Register</span>
                    </a>
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} xs={12}>
                <div className="img-perks img-perks-5">
                  <img alt="" src="/public/assets/images/3pair-image.png" />
                </div>
                <div className="perks-content-5 perks-content-un perks-content-un-s">
                  <h1>
                    Three Ways
                    <br />
                    To Earn Points When You Buy
                  </h1>
                  <p className="font-weight-bold">
                    <img src="/public/assets/images/icons8-level-1-48.png" />
                    Link your <span className="box-text">free</span> Movies Anywhere™ account, then buy digital movies at participating retailers like AppleTV, Prime Video, Vudu, Xfinity and others.
                    <br />
                    <br />
                    <img src="/public/assets/images/icons8-circled-2-c-48.png" />
                    Enter codes from inside <span className="box-text">select DVDs, Blu-rays™ or 4k Ultra HDs.</span>
                    <br />
                    <br />
                    <img src="/public/assets/images/icons8-circled-3-48.png" />
                    Submit receipts of <span className="box-text">eligible purchased DVDs, Blu-rays™ or 4k Ultra HDs.</span>
                  </p>

                  <spen>
                    <a className="btn btn-default" href="/register">
                      <span>Register</span>
                    </a>
                  </spen>
                </div>
              </Col>
            </Row>
            <h1 className="home-heading home-heading-sm text-center pt-5">
              <b>FAQ</b>
            </h1>
            <p className="text-center p-sm">For more information, please view our frequently asked questions below.</p>
            <Row>
              <Col md={3} className="img-perks img-perks-7">
                <img alt="dino" src="/public/assets/images/dino-faq.webp" />
              </Col>
              <Col md={6}>
                <FAQ />
              </Col>
              <Col md={3} className="img-perks img-perks-6">
                <img src="/public/assets/images/dino-2.png" alt="dino" />
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default IsLoadingHOC(Home, 'Wait .....');
