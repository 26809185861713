import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import UploadReceiptImage from '../../components/ValidatePurchase/UploadReceiptImage';
const UploadReceipt = props => {
  const history = useHistory();
  useEffect(() => {
    toast.error(`This webpage is not available.`);
    history.push('/home');
  }, []);

  return (
    <>
      <UploadReceiptImage />
    </>
  );
};

export default UploadReceipt;
