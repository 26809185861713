import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import PortalPointList from '../../components/PortalPointList';
import { Button, Container, Col, Row, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { portalPointAction, activityStatusAction, getUserDetails } from '../../redux/actions';
import IsLoadingHOC from '../../components/IsLoadingHOC';

const EarnPoints = props => {
  const history = useHistory();
  const [popupShow, setPopupShow] = useState(false);
  const user = useSelector(state => state.user);
  const [referalURL, setReferalURL] = useState('');
  useEffect(() => {
    toast.error(`This webpage is not available.`);
    history.push('/home');
    if (user.id) {
      props.setLoading(true);
      props
        .getUserDetails(user.id)
        .then(response => {
          props.setLoading(false);
          if (response.data) {
            let { refCode } = response.data;
            let url = `${window.location.protocol}//${window.location.host}/register?refCode=${refCode}`;
            setReferalURL(url);
          }
        })
        .catch(error => {
          props.setLoading(false);
          console.log('error', error);
        });
    }
  }, []);

  const handlePopupClose = () => {
    setPopupShow(false);
  };
  const handleCopy = txt => {
    navigator.clipboard.writeText(txt);
    toast.success('Copied!');
  };
  return (
    <>
      {/* To Banner : Start */}
      <div className="w-100 position-relative">
        <div className="banner-wrapper earnpoint">
          <div className="top-banner">
            <div className="">
              {/*commenting for the ticket P3-5415 */}
              {/*<img className="banner-img d-none d-sm-block" src="/public/assets/images/earn-points/EarnPts_HeroBn-Desktop.png" />
                            <img className="w-100 banner-img d-block d-sm-none" src="/public/assets/images/earn-points/EarnPts_HeroBnr-Mobile.png" />*/}
              {/* <img className="banner-img d-none d-sm-block" src="/public/assets/images/earn-points/Refer a Friend_WebsiteBanner_Desktop.png" />
              <img className="w-100 banner-img d-block d-sm-none" src="/public/assets/images/earn-points/Refer a Friend_MobileBanner_Mobile.png" /> */}
              <img className="banner-img d-none d-sm-block" src="/public/assets/images/homepage/desktop_banner_without_button.png" />
              <img className="w-100 banner-img d-block d-sm-none" src="/public/assets/images/homepage/mobile_banner_without_button.png" />
              {/* <img className="w-100 banner-img" src="/public/assets/images/earn-points/EarnPts_HeroBn-Desktop.png"
                            sizes="(min-width: 300px) 80vw, 100vw"
                            srcSet="/public/assets/images/earn-points/EarnPts_HeroBnr-Mobile.png 375w, /public/assets/images/earn-points/EarnPts_HeroBnr-Mobile.png 768w, /public/assets/images/earn-points/EarnPts_HeroBn-Desktop.png 1280w" alt="Refer a Friend, earn 500 points" /> */}
              <div className="container">
                <div className="d-sm-flex card-img-overlay">
                  <div className="col-sm-6 earnpoint-hero-banner white">
                    {/* adding the new refer a friend activity for ticket P3-5415*/}
                    {/*<h1>Refer a Friend</h1>
                                        <h2 className=" text-left"><strong>IT'S FAST. IT'S EASY. IT'S 150 POINTS IN YOUR POCKET</strong></h2> */}
                    {/* <h1>Sharing has its perks</h1>
                    <h2 className=" text-left">
                      <strong>Refer a friend to Plaid Perks and earn 150 points!</strong>
                    </h2>
                    <div className="mt-3 mt-md-5">
                      <a href="/referfriend" className="btn btn-primary">
                        Get Started
                      </a>
                    </div> */}
                    <h1 className="header-1">Plaid Perks™ is being discontinued.</h1>
                    <h4>
                      <strong>
                        Members have until August 21, 2023, to submit valid receipts or points codes to earn points and until September 5, 2023 to redeem their points for rewards at PlaidPerks.com. Click{' '}
                        <Link to="/faq" className="white-link">
                          here
                        </Link>{' '}
                        to learn more.
                      </strong>
                    </h4>
                    <p>Members will receive an email with instructions for how to redeem points after September 5, 2023.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="banner-wrapper">
                <div className="home-hero-banner" id="earn-points-top-header">
                    <Container>
                        <div className="row">
                            <div className="col-sm-7">
                                <h1>Refer a Friend</h1>
                                <h2><strong>IT'S FAST. IT'S EASY. IT'S 150 POINTS IN YOUR POCKET</strong></h2>
                                <div className="mt-5">
                                    <a href={referFriend?`/earnpoints-action-preview/${referFriend}`:'#'} className="btn btn-primary">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div> */}
      <div className="earnpoint-page">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 goto_faq-section d-sm-flex d-block align-items-center">
              <div className="row">
                <div className="col-md-4 d-flex align-items-center">
                  <img src="/public/assets/images/icons/Nut-PPsite-icons_receipt.png" className="img-fluid" alt="receipt" />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h4 className="card-title">Upload & Manage Receipts</h4>
                    <a href="/upload-receipt" className="btn btn-primary">
                      Upload
                    </a>
                    {/* <p className="mt-3 text-center text-sm-left">
                                            <a href="/public/assets/images/earn-points/receipt-sample.jpg" className="span-text-link" target="_blank">Receipt Upload Guide</a>
                                        </p> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 start_earning_section d-sm-flex d-block align-items-center">
              <div className="row align-items-center">
                <div className="col-md-4 d-flex align-items-center">
                  <img src="/public/assets/images/icons/Nut-PPsite-icons_redeem-code.png" className="img-fluid" alt="Redeem Code" />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h4 className="card-title">Redeem a Code</h4>
                    <a href="/validate-code" className="btn btn-primary">
                      Enter a Code
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* To Banner : End */}
      <div className="page-section">
        <div className="">
          <PortalPointList page="earnpoints" pagesize={100} title="" />
        </div>
        {/* Bottom Banner : Start */}
      </div>

      <div className="w-100">
        <div className="earnpoint-bottom-banner">
          <div className="faq-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-sm-5">
                  <div className="mt-5 pt-md-5">
                    <h2 className="text-left">Ready to reward yourself?</h2>
                    <a href="/rewards" className="btn btn-primary mt-4">
                      Go to Rewards
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Bottom Banner : End */}
      <Modal show={popupShow} onHide={() => setPopupShow(false)} size="lg">
        <Modal.Header closeButton>Refer a Friend</Modal.Header>
        <Modal.Body>
          <div className="justify-content-center mx-4">
            <p className="mb-4">Earn 150 points for every friend you refer who joins Plaid Perks and uploads a qualifying receipt for purchase of Nutrena products. Limit two per calendar month. </p>
            <h5>Share This Custom Link Anywhere</h5>
            <div className="my-3">
              <a className="refer-custom-link-text">{referalURL}</a>
              <button className="btn btn-primary mt-2 d-flex" onClick={() => handleCopy(referalURL)}>
                <span className="material-icons mr-2">content_copy</span> Copy
              </button>
            </div>
            <p className="mb-4">Share your custom link in an email, instant message, or anywhere you want.</p>
            <p className="mb-4">Points will be awarded to your account once a qualifying receipt upload has been approved in your friend’s account.</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

//export default EarnPoints;
export default connect(null, { portalPointAction, getUserDetails })(IsLoadingHOC(EarnPoints, 'Loading....'));
