import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from 'react-bootstrap';
import { Select, MenuItem } from '@material-ui/core';

const { SearchBar } = Search;
const TableReport = props => {
  const { t } = useTranslation();
  const searchTextboxEnable = props.searchTextboxEnable;
  const options = {
    custom: true,
    sizePerPage: 10,
    pageStartIndex: 1,
    //alwaysShowAllBtns: false, // Always show next and previous button
    //withFirstAndLast: false, // Hide the going to First and Last page button
    //hideSizePerPage: true, // Hide the sizePerPage dropdown always
    //hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    //firstPageText: () => <span class="material-symbols-outlined">first_page</span>,
    // prePageText: "Prev",
    // nextPageText: "Next",
    // lastPageText: "Last",
    // nextPageTitle: `${t('Next')} ${t('page')}`,
    // prePageTitle: `${t('Prev')} ${t('page')}`,
    // firstPageTitle: `${t('First')} ${t('page')}`,
    // lastPageTitle: `${t('Last')} ${t('page')}`,
    //showTotal: false,
    disablePageTitle: false,
    hideSizePerPage: true,
  };
  const handleNextPage = pagData => {
    let { page, onPageChange, dataSize, sizePerPage } = pagData;
    let lastPage = page;
    if (dataSize > sizePerPage) {
      lastPage = Math.ceil(dataSize / sizePerPage);
    }
    if (page < lastPage) {
      onPageChange(page + 1);
    }
  };
  const handleFirstPage = ({ onPageChange }) => {
    onPageChange(1);
  };
  const handleLastPage = pagData => {
    let { onPageChange, dataSize, sizePerPage } = pagData;
    if (dataSize > sizePerPage) {
      let lastPage = Math.ceil(dataSize / sizePerPage);
      onPageChange(lastPage);
    }
  };
  const handlePrevPage = ({ page, onPageChange }) => {
    if (page > 1) {
      onPageChange(page - 1);
    }
  };

  const handleSizePerPage = ({ page, onSizePerPageChange, onPageChange }, newSizePerPage) => {
    onPageChange(1);
    onSizePerPageChange(newSizePerPage, page);
  };
  const MySearch = props2 => {
    const handleChange = e => {
      props2.onSearch(e.target.value);
    };
    return (
      <Container fluid>
        <Row className="justify-content-center pb-5">
          <Col md={9} sm={12}>
            <div className="input-group mb-2 search-bar-table">
              <div className="input-group-prepend">
                <div className="input-group-text">Search Movies</div>
              </div>
              <input type="text" className="form-control" id="inlineFormInputGroup" onChange={handleChange} />
            </div>
          </Col>
        </Row>
      </Container>
    );
  };
  return (
    <ToolkitProvider data={props.data} columns={props.columns} keyField={props.keyField} search>
      {props => (
        <div className="table-report-div">
          {searchTextboxEnable && <MySearch {...props.searchProps} />}
          <PaginationProvider pagination={paginationFactory(options)}>
            {({ paginationProps, paginationTableProps }) => (
              <div>
                <BootstrapTable {...props.baseProps} {...paginationTableProps} striped />
                <Container fluid>
                  <Row className="justify-content-end react-bootstrap-table-pagination-custom pt-2">
                    <Col md={6} xs={6} className="right-align">
                      Items per page:{'  '}
                      <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" defaultValue={10} onChange={e => handleSizePerPage(paginationProps, e.target.value)}>
                        <MenuItem value={10}>10 </MenuItem>
                        <MenuItem value={20}>20 </MenuItem>
                        <MenuItem value={30}>40 </MenuItem>
                      </Select>{' '}
                      {paginationProps.page * paginationProps.sizePerPage - paginationProps.sizePerPage + 1} – {parseInt(paginationProps.page * paginationProps.sizePerPage) >= parseInt(paginationProps.dataSize) ? paginationProps.dataSize : paginationProps.page * paginationProps.sizePerPage} of {paginationProps.dataSize}
                    </Col>

                    <Col md={2} xs={6}>
                      <button className="btn btn-link" disabled={paginationProps.page === 1} onClick={() => handleFirstPage(paginationProps)}>
                        <span class="material-symbols-outlined">first_page</span>
                      </button>
                      <button className="btn btn-link" disabled={paginationProps.page === 1} onClick={() => handlePrevPage(paginationProps)}>
                        <span class="material-symbols-outlined">chevron_left</span>
                      </button>
                      <button className="btn btn-link" disabled={Math.ceil(paginationProps.dataSize / paginationProps.sizePerPage) <= paginationProps.page} onClick={() => handleNextPage(paginationProps)}>
                        <span class="material-symbols-outlined">chevron_right</span>
                      </button>
                      <button className="btn btn-link" disabled={Math.ceil(paginationProps.dataSize / paginationProps.sizePerPage) <= paginationProps.page} onClick={() => handleLastPage(paginationProps)}>
                        <span class="material-symbols-outlined">last_page</span>
                      </button>
                    </Col>
                  </Row>
                </Container>
              </div>
            )}
          </PaginationProvider>
        </div>
      )}
    </ToolkitProvider>
  );
};
export default React.memo(TableReport);
