import React, { useState, useEffect } from 'react';
import { Card, Container } from 'react-bootstrap';
import { oAuthRegistrationAction } from '../redux/actions';
import { connect } from 'react-redux';
import IsLoadingHOC from './IsLoadingHOC';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GooglePlaces from './GooglePlaces';
import Required from './Required';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
var validator = require('validator');

const OAuthRegistration = props => {
  const { t } = useTranslation();
  const confige = useSelector(state => state.app.config);
  const history = useHistory();
  const { oAuthRegistrationAction, setLoading } = props;
  const [place, setPlace] = useState({});
  /*[START:INITIALIZE_DECLARATION]*/
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    birth: '',
    homePhone: '',
    cellPhone: '',
    address: '',
    address2: '',
    country: '',
    state: '',
    suite: '',
    city: '',
    zip: '',
    shippingInstructions: '',
    gender: '',
    receiveEmail: '',
    receiveSms: '',
    companyName: '',
    instagramHandle: '',
    facebookHandle: '',
    tiktokHandle: '',
    twitterHandle: '',
    socialSecurityNumber: '',
    latitude: 0,
    longitude: 0,
    additionalInfo: { visitorId: '', requestId: '' },
  });
  /*[END:INITIALIZE_DECLARATION]*/
  const [validation, setValidation] = useState({});
  const [error, setError] = useState({ firstName: '', email: '', homePhone: '', cellPhone: '', lastName: '', username: '', address: '', address2: '', country: '', state: '', suite: '', zip: '', city: '', shippingInstructions: '', receiveEmail: '', receiveSms: '', instagramHandle: '', facebookHandle: '', tiktokHandle: '', twitterHandle: '', socialSecurityNumber: '' });
  useEffect(() => {
    let items = {};
    if (confige.uiConfig) {
      confige.uiConfig.userFields.forEach(element => {
        items[element.name] = element.validation;
      });
    }
    if (confige.fingerprinting) {
      if (confige.fingerprinting.enable) {
        const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
        // Get the visitor identifier when you need it.
        fpPromise
          .then(fp => fp.get())
          .then(result => {
            let fdata = JSON.parse(JSON.stringify(data));
            fdata.additionalInfo.visitorId = result.visitorId;
            fdata.additionalInfo.requestId = result.requestId;
            setData(fdata);
          });
      }
    }
    setValidation(items);
    // eslint-disable-next-line
  }, [confige, confige.uiConfig]);
  useEffect(() => {
    let userData = props.userData;
    let oldStat = JSON.parse(JSON.stringify(data));
    oldStat.id = userData.id ? userData.id : '';
    if (oldStat.hasOwnProperty('firstName')) {
      oldStat.firstName = userData.firstName ? userData.firstName : '';
    }
    if (oldStat.hasOwnProperty('lastName')) {
      oldStat.lastName = userData.lastName ? userData.lastName : '';
    }
    if (oldStat.hasOwnProperty('email')) {
      oldStat.email = userData.email ? userData.email : '';
    }
    if (oldStat.hasOwnProperty('username')) {
      oldStat.username = userData.username ? userData.username : '';
    }
    if (oldStat.hasOwnProperty('birth')) {
      oldStat.birth = userData.birth ? userData.birth : '';
    }
    if (oldStat.hasOwnProperty('homePhone')) {
      oldStat.homePhone = userData.homePhone ? userData.homePhone : '';
    }
    if (oldStat.hasOwnProperty('cellPhone')) {
      oldStat.cellPhone = userData.cellPhone ? userData.cellPhone : '';
    }
    if (oldStat.hasOwnProperty('address')) {
      oldStat.address = userData.address ? userData.address : '';
    }
    if (oldStat.hasOwnProperty('address2')) {
      oldStat.address2 = userData.address2 ? userData.address2 : '';
    }
    if (oldStat.hasOwnProperty('country')) {
      oldStat.country = userData.country ? userData.country : '';
    }
    if (oldStat.hasOwnProperty('state')) {
      oldStat.state = userData.state ? userData.state : '';
    }
    if (oldStat.hasOwnProperty('suite')) {
      oldStat.suite = userData.suite ? userData.suite : '';
    }
    if (oldStat.hasOwnProperty('city')) {
      oldStat.city = userData.city ? userData.city : '';
    }
    if (oldStat.hasOwnProperty('zip')) {
      oldStat.zip = userData.zip ? userData.zip : '';
    }
    if (oldStat.hasOwnProperty('shippingInstructions')) {
      oldStat.shippingInstructions = userData.shippingInstructions ? userData.shippingInstructions : '';
    }
    if (oldStat.hasOwnProperty('gender')) {
      oldStat.gender = userData.gender ? userData.gender : '';
    }
    if (oldStat.hasOwnProperty('receiveEmail')) {
      oldStat.receiveEmail = userData.receiveEmail ? userData.receiveEmail : '';
    }
    if (oldStat.hasOwnProperty('receiveSms')) {
      oldStat.receiveSms = userData.receiveSms ? userData.receiveSms : '';
    }
    if (oldStat.hasOwnProperty('companyName')) {
      oldStat.companyName = userData.companyName ? userData.companyName : '';
    }
    if (oldStat.hasOwnProperty('instagramHandle')) {
      oldStat.instagramHandle = userData.instagramHandle ? userData.instagramHandle : '';
    }
    if (oldStat.hasOwnProperty('twitterHandle')) {
      oldStat.twitterHandle = userData.twitterHandle ? userData.twitterHandle : '';
    }
    if (oldStat.hasOwnProperty('facebookHandle')) {
      oldStat.facebookHandle = userData.facebookHandle ? userData.facebookHandle : '';
    }
    if (oldStat.hasOwnProperty('tiktokHandle')) {
      oldStat.tiktokHandle = userData.tiktokHandle ? userData.tiktokHandle : '';
    }
    if (oldStat.hasOwnProperty('socialSecurityNumber')) {
      oldStat.socialSecurityNumber = userData.socialSecurityNumber ? userData.socialSecurityNumber : '';
    }
    if (oldStat.hasOwnProperty('latitude')) {
      oldStat.latitude = userData.latitude ? userData.latitude : 0;
    }
    if (oldStat.hasOwnProperty('longitude')) {
      oldStat.longitude = userData.longitude ? userData.longitude : 0;
    }
    setData(oldStat);
  }, [props.userData]);
  const handleChange = e => {
    const { name, value } = e.target;
    let errorMessage = '';
    let validationArray = validation[name];
    let check = false;
    for (let key in validationArray) {
      if (key === 'minLength') {
        if (value.length < validationArray[key] && value.length !== 0) {
          errorMessage = t('Should not be less then', { count: validationArray[key] });
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'maxLength') {
        if (value.length > validationArray[key]) {
          errorMessage = t('Should not be greater then', { count: validationArray[key] });
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'mandatory' && validationArray[key]) {
        if (!value.length) {
          errorMessage = t('Should not be empty');
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'email' && validationArray[key]) {
        if (!validator.isEmail(value)) {
          errorMessage = t('Please enter a valid email');
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'upperCaseReq' && validationArray[key]) {
        if (value.search(/[A-Z]/) < 0) {
          errorMessage = t('Should have at least one Upper Case latter');
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'lowerCaseReq' && validationArray[key]) {
        if (value.search(/[a-z]/) < 0) {
          errorMessage = t('Should have at least one Lower Case latter');
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'specialCharReq' && validationArray[key]) {
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (!format.test(value)) {
          errorMessage = t('Should have at least one special letter');
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
    }

    if (!check) {
      setError({ ...error, [name]: '' });
    }
    setData({ ...data, [name]: value });
  };
  const onSubmit = () => {
    let errorArray = {};
    for (let name in data) {
      if (name === 'email' || name === 'username') {
        continue;
      }
      let value = data[name];
      let validationArray = validation[name];
      let errorMessage = '';
      if (name === 'birth' && isNaN(new Date(value)) && value.length !== 0) {
        errorMessage = `${t('Invalid')} ${t('Birth Date')}`;
      } else if (name === 'birth') {
        errorMessage = '';
      }
      for (let key in validationArray) {
        if (key === 'email' && validationArray[key] && !validator.isEmail(value)) {
          errorMessage = t('Please enter a valid email');
        } else if (key === 'minLength' && value.length < validationArray[key] && value.length !== 0) {
          errorMessage = t('Should not be less then', { count: validationArray[key] });
        } else if (key === 'maxLength' && value.length > validationArray[key]) {
          errorMessage = t('Should not be greater then', { count: validationArray[key] });
        } else if (key === 'mandatory' && validationArray[key] && !value.length) {
          errorMessage = t('Should not be empty');
        } else if (key === 'upperCaseReq' && value.search(/[A-Z]/) < 0 && value.length !== 0) {
          errorMessage = t('Should have at least one Upper Case latter');
        } else if (key === 'lowerCaseReq' && value.search(/[a-z]/) < 0 && value.length !== 0) {
          errorMessage = t('Should have at least one Lower Case latter');
        } else if (key === 'specialCharReq' && value.length !== 0) {
          var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
          if (!format.test(value)) {
            errorMessage = t('Should have at least one special letter');
          }
        }
      }
      errorArray[name] = errorMessage;
    }
    setError(errorArray);
    let check = false;
    for (let key in errorArray) {
      if (errorArray[key]) {
        check = true;
      }
    }
    const newData = { ...data };
    if (!check) {
      if (newData.birth) {
        let datevalue = new Date(newData.birth);
        const dd = String(datevalue.getDate()).padStart(2, '0');
        const mm = String(datevalue.getMonth() + 1).padStart(2, '0');
        const yyyy = datevalue.getFullYear();
        const formatedDate = yyyy + '-' + mm + '-' + dd;
        newData.birth = formatedDate;
      }
      setLoading(true);
      oAuthRegistrationAction(newData, props.updateId)
        .then(response => {
          toast.success(t('User login successfully'));
          history.push('/home');
          setLoading(false);
        })
        .catch(error => {
          error.map(item => {
            toast.error(item.message);
          });
          setLoading(false);
        });
    }
  };
  const handleDate = value => {
    setData({ ...data, birth: value });
  };
  useEffect(() => {
    if (place.hasOwnProperty('address')) {
      let curStateData = Object.assign({}, data);
      if (curStateData.hasOwnProperty('address')) {
        curStateData.address = place.address;
      }
      if (curStateData.hasOwnProperty('country')) {
        curStateData.country = place.country;
      }
      if (curStateData.hasOwnProperty('state')) {
        curStateData.state = place.state;
      }
      if (curStateData.hasOwnProperty('city')) {
        curStateData.city = place.city;
      }
      if (curStateData.hasOwnProperty('suite')) {
        curStateData.suite = place.street;
      }
      if (curStateData.hasOwnProperty('zip')) {
        curStateData.zip = place.zip;
      }
      if (curStateData.hasOwnProperty('latitude')) {
        curStateData.latitude = place.latitude;
      }
      if (curStateData.hasOwnProperty('longitude')) {
        curStateData.longitude = place.longitude;
      }
      setData(curStateData);
    }
  }, [place]);
  const googleSelectedAddressHandler = place => {
    setPlace(place);
  };
  return (
    <Container fluid>
      <div className="register-page">
        <Card>
          <form>
            <h1 className="main-heading text-center">{t('Complete your registration')}</h1>
            {/*[START:FIRSTNAME]*/}
            <div className="form-group row theme-signup-firstname">
              <label htmlFor="first-name" className="col-sm-4 col-form-label">
                <Trans>lang_key_fname</Trans>
                {validation['firstName'] && validation['firstName'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-8">
                <input type="text" value={data.firstName} data-test="register-firstName" name="firstName" onChange={handleChange} className="form-control" id="first-name" placeholder={t('lang_key_fname')} />
                <h5 className="input-error">{error.firstName}</h5>
              </div>
            </div>
            {/*[END:FIRSTNAME]*/}

            {/*[START:LASTNAME]*/}
            <div className="form-group row theme-signup-lastname">
              <label htmlFor="last-name" className="col-sm-4 col-form-label">
                <Trans>lang_key_lname</Trans>
                {validation['lastName'] && validation['lastName'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-8">
                <input type="text" value={data.lastName} data-test="register-lasttName" name="lastName" onChange={handleChange} className="form-control" id="last-name" placeholder={t('lang_key_lname')} />
                <h5 className="input-error">{error.lastName}</h5>
              </div>
            </div>
            {/*[END:LASTNAME]*/}

            {/*[START:USERNAME]*/}
            <div className="form-group row theme-signup-username">
              <label htmlFor="user-name" className="col-sm-4 col-form-label">
                <Trans>lang_key_username</Trans>
                {validation['username'] && validation['username'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-8">
                <input type="text" data-test="register-username" value={data.username} name="username" disabled={true} className="form-control" id="user-name" placeholder={t('lang_key_username')} />
                <h5 className="input-error">{error.username}</h5>
              </div>
            </div>
            {/*[END:USERNAME]*/}

            {/*[START:EMAIL]*/}
            <div className="form-group row theme-signup-email">
              <label htmlFor="email" className="col-sm-4 col-form-label">
                <Trans>lang_key_email</Trans>
                {validation['email'] && validation['email'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-8">
                <input type="email" data-test="register-email" value={data.email} name="email" disabled={true} className="form-control" id="email" placeholder={t('lang_key_email')} />
                <h5 className="input-error">{error.email}</h5>
              </div>
            </div>
            {/*[END:EMAIL]*/}
            {/*[START:TWITTERHANDLE]*/}
            <div className="form-group row theme-signup-twitterHandle">
              <label htmlFor="twitterHandle" className="col-sm-2 col-form-label">
                <Trans>lang_key_twitterHandle</Trans>
                {validation['twitterHandle'] && validation['twitterHandle'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-10">
                <input type="text" value={data.twitterHandle} data-test="register-twitterHandle" name="twitterHandle" onChange={handleChange} className="form-control" id="twitterHandle" placeholder={t('lang_key_twitterHandle')} />
                <h5 className="input-error">{error.twitterHandle}</h5>
              </div>
            </div>
            {/*[END:TWITTERHANDLE]*/}
            {/*[START:INSTAGRAMHANDLE]*/}
            <div className="form-group row theme-signup-instagramHandle">
              <label htmlFor="instagramHandle" className="col-sm-2 col-form-label">
                <Trans>lang_key_instaHandle</Trans>
                {validation['instagramHandle'] && validation['instagramHandle'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-10">
                <input type="text" value={data.instagramHandle} data-test="register-instagramHandle" name="instagramHandle" onChange={handleChange} className="form-control" id="instagramHandle" placeholder={t('lang_key_instaHandle')} />
                <h5 className="input-error">{error.instagramHandle}</h5>
              </div>
            </div>
            {/*[END:INSTAGRAMHANDLE]*/}
            {/*[START:FACEBOOKHANDLE]*/}
            <div className="form-group row theme-signup-facebookHandle">
              <label htmlFor="facebookHandle" className="col-sm-2 col-form-label">
                <Trans>lang_key_facebookHandle</Trans>
                {validation['facebookHandle'] && validation['facebookHandle'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-10">
                <input type="text" value={data.facebookHandle} data-test="register-facebookHandle" name="facebookHandle" onChange={handleChange} className="form-control" id="facebookHandle" placeholder={t('lang_key_facebookHandle')} />
                <h5 className="input-error">{error.facebookHandle}</h5>
              </div>
            </div>
            {/*[END:FACEBOOKHANDLE]*/}
            {/*[START:TIKTOKHANDLE]*/}
            <div className="form-group row theme-signup-tiktokHandle">
              <label htmlFor="tiktokHandle" className="col-sm-2 col-form-label">
                <Trans>lang_key_tiktokHandle</Trans>
                {validation['tiktokHandle'] && validation['tiktokHandle'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-10">
                <input type="text" value={data.tiktokHandle} data-test="register-tiktokHandle" name="tiktokHandle" onChange={handleChange} className="form-control" id="tiktokHandle" placeholder={t('lang_key_tiktokHandle')} />
                <h5 className="input-error">{error.tiktokHandle}</h5>
              </div>
            </div>
            {/*[END:TIKTOKHANDLE]*/}

            {/*[START:ADDRESS]*/}
            <div className="form-group row theme-signup-address-finder">
              <label htmlFor="addressFinder" className="col-sm-2 col-form-label">
                <Trans>lang_key_select_address</Trans>
              </label>
              <div className="col-sm-10">
                <GooglePlaces getPlaceHandler={googleSelectedAddressHandler} placeholder={t('lang_key_select_address')} />
              </div>
            </div>
            <div className="form-group row theme-signup-address">
              <label htmlFor="address" className="col-sm-4 col-form-label">
                <Trans>lang_key_add_address</Trans>
                {validation['address'] && validation['address'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-8">
                <input type="text" data-test="register-address" value={data.address} name="address" className="form-control" id="address" placeholder={t('lang_key_add_address')} />
                <h5 className="input-error">{error.address}</h5>
              </div>
            </div>
            {/*[END:ADDRESS]*/}
            {/*[START:ADDRESS2]*/}
            <div className="form-group row theme-signup-address">
              <label htmlFor="address2" className="col-sm-3 col-form-label">
                <Trans>lang_key_add_address2</Trans>
                {validation['address2'] && validation['address2'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-9">
                <input type="text" data-test="register-address2" value={data.address2} name="address2" onChange={handleChange} className="form-control" id="address2" placeholder={t('lang_key_add_address2')} />
                <h5 className="input-error">{error.address2}</h5>
              </div>
            </div>
            {/*[END:ADDRESS2]*/}
            {/*[START:COUNTRY]*/}
            <div className="form-group row theme-signup-country">
              <label htmlFor="country" className="col-sm-4 col-form-label">
                <Trans>lang_key_add_country</Trans>
                {validation['country'] && validation['country'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-8">
                <input type="text" data-test="register-country" value={data.country} name="country" onChange={handleChange} className="form-control" id="country" placeholder={t('lang_key_add_country')} />
                <h5 className="input-error">{error.country}</h5>
              </div>
            </div>
            {/*[END:COUNTRY]*/}

            {/*[START:STATE]*/}
            <div className="form-group row theme-signup-state">
              <label htmlFor="state" className="col-sm-4 col-form-label">
                {t('lang_key_add_state')}
                {validation['state'] && validation['state'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-8">
                <input type="text" data-test="register-state" value={data.state} name="state" onChange={handleChange} className="form-control" id="state" placeholder={t('lang_key_add_state')} />
                <h5 className="input-error">{error.state}</h5>
              </div>
            </div>
            {/*[END:STATE]*/}

            {/*[START:SUITE]*/}
            <div className="form-group row theme-signup-suite">
              <label htmlFor="suite" className="col-sm-4 col-form-label">
                <Trans>lang_key_add_suite</Trans>
                {validation['suite'] && validation['suite'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-8">
                <input type="text" data-test="register-state" value={data.suite} name="suite" onChange={handleChange} className="form-control" id="suite" placeholder={t('lang_key_add_suite')} />
                <h5 className="input-error">{error.suite}</h5>
              </div>
            </div>
            {/*[END:SUITE]*/}

            {/*[START:CITY]*/}
            <div className="form-group row theme-signup-city">
              <label htmlFor="city" className="col-sm-4 col-form-label">
                <Trans>City</Trans>
                {validation['city'] && validation['city'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-8">
                <input type="text" data-test="signup-city" value={data.city} name="city" onChange={handleChange} className="form-control" id="city" placeholder={t('City')} />
                <h5 className="input-error">{error.city}</h5>
              </div>
            </div>
            {/*[END:CITY]*/}

            {/*[START:ZIP]*/}
            <div className="form-group row theme-signup-zip">
              <label htmlFor="zip" className="col-sm-4 col-form-label">
                <Trans>Zip</Trans>
                {validation['zip'] && validation['zip'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-8">
                <input type="text" value={data.zip} data-test="signup-zip" name="zip" onChange={handleChange} className="form-control" id="zip" placeholder={t('Zip')} />
                <h5 className="input-error">{error.zip}</h5>
              </div>
            </div>
            {/*[END:ZIP]*/}

            {/*[START:SHIPPING]*/}
            <div className="form-group row theme-signup-shippingInstructions">
              <label htmlFor="shippingInstructions" className="col-sm-4 col-form-label">
                <Trans>Shipping Instructions</Trans>
                {validation['shippingInstructions'] && validation['shippingInstructions'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-8">
                <input type="text" value={data.shippingInstructions} data-test="signup-shippingInstructions" name="shippingInstructions" onChange={handleChange} className="form-control" id="shippingInstructions" placeholder={t('Shipping Instructions')} />
                <h5 className="input-error">{error.shippingInstructions}</h5>
              </div>
            </div>
            {/*[END:SHIPPING]*/}

            {/*[START:GENDER]*/}
            <div className="form-group row theme-signup-gender">
              <label htmlFor="gender" className="col-sm-4 col-form-label theme-signup-label-gender">
                <Trans>Gender</Trans>
                {validation['gender'] && validation['gender'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-8">
                <select className="form-control" value={data.gender} data-test="signup-gender" name="gender" onChange={handleChange} id="gender" placeholder={t('Gender')}>
                  <option value="">
                    {t('Select')} {t('Gender')}
                  </option>
                  <option value="m">{t('Male')}</option>
                  <option value="f">{t('Female')}</option>
                </select>
                <h5 className="input-error">{error.gender}</h5>
              </div>
            </div>
            {/*[END:GENDER]*/}

            {/*[START:BIRTH]*/}
            <div className="form-group row theme-signup-birth">
              <label htmlFor="birth" className="col-sm-4 col-form-label theme-signup-label-birth">
                <Trans>Birth Date</Trans>
                {validation['birth'] && validation['birth'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-8">
                <input type="date" value={data.birth} className="form-control" data-test="signup-gender" name="birth" id="birth" placeholder={t('Birth Date')} onChange={e => handleDate(e.target.value)} />

                <h5 className="input-error">{error.birth}</h5>
              </div>
            </div>
            {/*[END:BIRTH]*/}

            {/*[START:HOMEPHONE]*/}
            <div className="form-group row theme-signup-homePhone">
              <label htmlFor="homePhone" className="col-sm-4 col-form-label">
                <Trans>Home Phone</Trans>
                {validation['homePhone'] && validation['homePhone'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-8">
                <input type="number" value={data.homePhone} data-test="signup-homePhone" name="homePhone" onChange={handleChange} className="form-control" id="homePhone" placeholder={t('Home Phone')} />
                <h5 className="input-error">{error.homePhone}</h5>
              </div>
            </div>
            {/*[END:HOMEPHONE]*/}

            {/*[START:CELLPHONE]*/}
            <div className="form-group row theme-signup-homePhone">
              <label htmlFor="cellPhone" className="col-sm-4 col-form-label">
                <Trans>Cell Phone</Trans>
                {validation['cellPhone'] && validation['cellPhone'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-8">
                <input type="number" value={data.cellPhone} data-test="signup-cellPhone" name="cellPhone" onChange={handleChange} className="form-control" id="cellPhone" placeholder={t('Cell Phone')} />
                <h5 className="input-error">{error.cellPhone}</h5>
              </div>
            </div>
            {/*[END:CELLPHONE]*/}

            {/*[START:RECEIVEEMAIL]*/}
            <div className="form-group row theme-signup-receiveEmail">
              <label htmlFor="receiveEmail" className="col-sm-4 col-form-label theme-signup-label-receiveEmail">
                <Trans>Receive Email</Trans>
                {validation['receiveEmail'] && validation['receiveEmail'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-8">
                <select className="form-control" value={data.receiveEmail} data-test="signup-email" name="receiveEmail" onChange={handleChange} id="receiveEmail" placeholder={t('Receive Email')}>
                  <option value="">{t('Select')}</option>
                  <option value={true}>{t('Yes')}</option>
                  <option value={false}>{t('No')}</option>
                </select>
                <h5 className="input-error">{error.receiveEmail}</h5>
              </div>
            </div>
            {/*[END:RECEIVEEMAIL]*/}

            {/*[START:RECEIVESMS]*/}
            <div className="form-group row theme-signup-receiveSms">
              <label htmlFor="receiveSms" className="col-sm-4 col-form-label theme-signup-label-receiveSms">
                <Trans>Receive Sms</Trans>
                {validation['receiveSms'] && validation['receiveSms'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-8">
                <select className="form-control" value={data.receiveSms} data-test="signup-receiveSms" name="receiveSms" onChange={handleChange} id="receiveSms" placeholder={t('Receive Sms')}>
                  <option value="">{t('Select')}</option>
                  <option value={true}>{t('Yes')}</option>
                  <option value={false}>{t('No')}</option>
                </select>
                <h5 className="input-error">{error.receiveSms}</h5>
              </div>
            </div>
            {/*[END:RECEIVESMS]*/}

            {/*[START:COMPANYNAME]*/}
            <div className="form-group row theme-signup-companyName">
              <label htmlFor="companyName" className="col-sm-4 col-form-label">
                <Trans>Company Name</Trans>
                {validation['companyName'] && validation['companyName'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-8">
                <input type="text" value={data.companyName} data-test="signup-companyName" name="companyName" onChange={handleChange} className="form-control" id="companyName" placeholder={t('Company Name')} />
                <h5 className="input-error">{error.companyName}</h5>
              </div>
            </div>
            {/*[END:COMPANYNAME]*/}
            {/*[START:SSN]*/}
            <div className="form-group row theme-signup-socialSecurityNumber">
              <label htmlFor="companyName" className="col-sm-3 col-form-label">
                <Trans>Social Security Number</Trans>
                {validation['socialSecurityNumber'] && validation['socialSecurityNumber'].mandatory ? <Required /> : ''}
              </label>
              <div className="col-sm-9">
                <input type="text" value={data.socialSecurityNumber} data-test="signup-companyName" name="socialSecurityNumber" onChange={handleChange} className="form-control" id="socialSecurityNumber" placeholder={t('Social Security Number')} />
                <h5 className="input-error">{error.socialSecurityNumber}</h5>
              </div>
            </div>
            {/*[END:SSN]*/}
            <div className="row submit">
              <button type="button" id="register-submit-btn" data-test="register-submit" onClick={onSubmit} className="btn">
                <Trans>Register Now</Trans>
              </button>
            </div>
          </form>
        </Card>
      </div>
    </Container>
  );
};

export default connect(null, { oAuthRegistrationAction })(IsLoadingHOC(OAuthRegistration, 'Wait .....'));
