import React, { useEffect } from 'react';
import RewardsList from '../../components/rewards/RewardsList';
import { rewardsListTagWiseAction } from '../../redux/actions';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { Link } from 'react-router-dom';

const Rewards = props => {
  const [showBanner, setShowwBanner] = React.useState(0);
  const [data, setData] = React.useState({});

  const tags = 'cool_7-13_7-15';
  const handleScrollTo = () => {
    const violation = document.getElementById('donetion_section');
    if (violation) {
      window.scrollTo({
        top: violation.offsetTop - 100,
        behavior: 'smooth',
      });
    }
  };
  useEffect(() => {
    props.setLoading(true);
    rewardsListTagWiseAction(tags)
      .then(response => {
        if (typeof response.data === 'object') {
          let rdata = Array.isArray(response.data) ? response.data : [response.data];
          let fltData = rdata.filter(v => v?.tags?.indexOf(tags) >= 0 || false);
          if (fltData.length > 0) {
            setShowwBanner(2);
            setData(fltData[0]);
          } else {
            setShowwBanner(1);
          }
        } else {
          setShowwBanner(1);
        }
        props.setLoading(false);
      })
      .catch(err => {
        setShowwBanner(1);
        props.setLoading(false);
      });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {/* <CarouselCustom /> */}

      <div className="w-100 mb-5 position-relative" id="rd">
        <div className="">
          <div className="reward-banner">
            {showBanner === 1 && (
              <div className="top-banner">
                {/* <img className="banner-img d-none d-sm-block" src="/public/assets/images/reward-assets/Hero_FIFBnr-Desktop.png" alt="Feed it Forward" />
                <img className="w-100 banner-img d-block d-sm-none" src="/public/assets/images/reward-assets/Hero_FIFBnr-Mobile.png" alt="Feed it Forward" />
                <div className="d-sm-flex card-img-overlay">
                  <div className="col-md-6 white reward-hero-banner reward-hero-banner2">
                    <h1>Refer a Friend</h1>
                    <h2 className="white text-left">
                      <strong>Donate points. Support life-changing animal organizations.</strong>
                    </h2>
                    <div className="">
                      <a href="https://www.feeditforward.org/" target="_blank" className="btn btn-primary mr-0 mr-sm-3 mt-2 mt-sm-2 mt-md-4">
                        See how we Feed It Forward
                      </a>
                      <button onClick={handleScrollTo} className="btn btn-primary mt-2 mt-sm-2 mt-md-4">
                        Donate some points
                      </button>
                    </div>
                  </div>
                </div> */}
                <img className="banner-img d-none d-sm-block" src="/public/assets/images/homepage/desktop_banner_without_button.png" />
                <img className="w-100 banner-img d-block d-sm-none" src="/public/assets/images/homepage/mobile_banner_without_button.png" />
                <div className="container">
                  <div className="d-sm-flex card-img-overlay">
                    <div className="col-sm-6 earnpoint-hero-banner white">
                      <h1 className="header-1">Plaid Perks™ is being discontinued.</h1>
                      <h4>
                        <strong>
                          We are no longer accepting new receipt submissions. You may use your available points to redeem rewards at PlaidPerks.com, while supplies last, until September 5, 2023. Click{' '}
                          <Link to="/faq" className="white-link">
                            here
                          </Link>{' '}
                          to learn more.
                        </strong>
                        {/* <strong>
                          Members have until August 21, 2023, to submit valid receipts or points codes to earn points and until September 5, 2023 to redeem their points for rewards at PlaidPerks.com. Click{' '}
                          <Link to="/faq" className="white-link">
                            here
                          </Link>{' '}
                          to learn more.
                        </strong> */}
                      </h4>
                      <p>Members will receive an email with instructions for how to redeem points after September 5, 2023.</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showBanner === 2 && (
              <div className="top-banner">
                <img className="banner-img d-none d-sm-block" src="/public/assets/images/reward-assets/0383_NUT_PP_CpnJuly_WebBnnr_1920x920.jpg" alt="Feed it Forward" />
                <img className="w-100 banner-img d-block d-sm-none" src="/public/assets/images/reward-assets/0383_NUT_PP_CpnJuly_WebBnnrMbl_720x920.jpg" alt="Feed it Forward" />
                <div className="d-sm-flex card-img-overlay">
                  <div className="col-md-6 white reward-hero-banner reward-hero-banner2">
                    <h2 className="black text-left">
                      <strong>
                        Cool off with a<br /> coupon discount
                      </strong>
                    </h2>
                    <h4 style={{ color: '#605c5c' }} className="text-left">
                      Redeem a <strong className="black">$10 coupon</strong> for
                      <br /> less points from 7/13-7/15.
                    </h4>
                    <div className="">
                      <a href={`/reward-preview/${data.id}`} className="btn btn-primary mr-0 mr-sm-3 mt-2 mt-sm-2 mt-md-4">
                        Redeem now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <RewardsList showCouponBlock={true} showApparelsBlock={true} showPladGearBlock={true} showHeadWearBlock={true} showBlockTitle={true} showFeedItForwardBlock={true} />

      <div className="profile-complete">
        <div className="py-4 container text-center">
          <h3 className="text-center">Donate points. Support life-changing animal organizations.</h3>
          <div className="row justify-content-center">
            <div className="col-sm-4 col-8 mt-2">
              <img className="img-fluid" src="/public/assets/images/reward-assets/feeditforward.png" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="bg-reward-pro py-5">
        <RewardsList showTagsBlock={true} limit="4" />
      </div> */}

      {/* bottom banner */}
      {/* <div className="w-100">
        <div className="reward-bottom-banner">
          <div className="faq-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-sm-8 col-md-5">
                  <div className="">
                    <h2 className="text-left white">Want to reach your favorite reward faster?</h2>
                    <a href="/earnpoints" className="btn btn-primary mt-4">
                      See Ways to Earn
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* end bottom banner */}
    </>
  );
};

export default IsLoadingHOC(Rewards, 'Loading....');
