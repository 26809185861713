import React from 'react';

const TermsOfUse = () => {
  return (
    <div className="container-fluid terms-block bg-white">
      <div className="row pb-5">
        <div className="col-sm-12">
          <h1 className="terms-main-heading text-center">The Universal All-Access Rewards</h1>
          <h1 className="terms-main-heading text-center">Program Terms and Conditions</h1>
          <p className="terms-description pt-2">
            {' '}
            Welcome to the Universal All-Access Rewards Program (the <b>“Program”</b>) sponsored by Universal Pictures Home Entertainment, LLC (<b>“UPHE”, “Sponsor”, “we”, “our”</b> or <b>“us”</b>). Your use of the Program and/or the Program website{' '}
            <a className="terms-link" href="/home">
              www.myuniversalrewards.com
            </a>{' '}
            (the <b>“Website”</b>) is subject at all times to these Terms and Conditions (<b>“Terms”</b>) and all applicable federal, state and local laws, rules and regulations. These Terms are subject to, and shall also include, the{' '}
            <a href="https://www.nbcuniversal.com/terms" target="_blank" className="terms-link">
              NBC Universal Media, LLC Terms of Use
            </a>{' '}
            which are incorporated by reference and to which you agree to be bound (click{' '}
            <a href="https://www.nbcuniversal.com/terms?utm_source=nbc-terms" target="_blank" className="terms-link">
              here
            </a>{' '}
            to read in full). If any term in the{' '}
            <a href="https://www.nbcuniversal.com/terms" target="_blank" className="terms-link">
              NBC Universal Media, LLC Terms of Use
            </a>{' '}
            conflicts with or contradicts these Terms, then these Terms govern with respect to the Program and elements thereof only.{' '}
          </p>
          <p className="terms-description">
            <b>
              PLEASE READ THESE TERMS, THE NBCUNIVERSAL MEDIA, LLC TERMS OF USE, AND THE NBCUNIVERSAL PRIVACY POLICY{' '}
              <a href="https://www.nbcuniversal.com/privacy" target="_blank" className="terms-link">
                HTTP://WWW.NBCUNIVERSAL.COM/PRIVACY
              </a>{' '}
              (THE “PRIVACY POLICY”) CAREFULLY BEFORE USING THE PROGRAM OR THE WEBSITE. THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS INCLUDING MANDATORY ARBITRATION, NO CLASS RELIEF, AND WAIVER OF YOUR RIGHT TO A JURY TRIAL.
            </b>{' '}
            In these Terms, <b>“Participant”</b>, <b>“user”</b> or <b>“you”</b> means any person accessing or using the Program and/or the Website.{' '}
          </p>
          <p className="terms-description">
            {' '}
            By registering for, accessing and/or using the Program or the Website in any manner whatsoever, you acknowledge that you have read, understood and agree to be bound by these Terms, the{' '}
            <a href="https://www.nbcuniversal.com/privacy" target="_blank" className="terms-link">
              Privacy Policy
            </a>
            , and all other applicable operating rules, policies and/or procedures that may be published by us from time to time on the Website, each of which is incorporated by reference and each of which may be updated from time to time without notice to you. It is your responsibility to check these Terms regularly for any updates. If you object to the Terms or any changes hereto, or at any time become dissatisfied with the Program, your sole recourse shall be to cease using the Program. Your
            continued use of the Program after notification of any change(s) (including notification by posting) constitutes conclusive evidence of your acknowledgement of such change(s) and your agreement to be bound by such change(s). If any term or change to these Terms is found to be invalid, void, or for any reasons unenforceable that term or change is severable and does not affect the validity and enforceability of any remaining Terms or changes, all of which will remain in full force and
            effect. Sponsor shall not be liable to you in any way for any value or benefits you may lose as a result of any changes made to the Program, including, without limitation, any Points (as defined below) or Rewards (as defined below).{' '}
          </p>
          <p className="terms-description">
            <b>IF YOU ARE NOT ELIGIBLE FOR THE PROGRAM, OR YOU DO NOT AGREE TO THESE TERMS, THEN PLEASE DO NOT ACCESS, USE OR PARTICIPATE IN THE PROGRAM AND/OR WEBSITE.</b>
          </p>
          <p className="terms-description">
            <b>1. ELIGIBILITY.</b> To be eligible for the Program, you must be a legal resident of the fifty (50) United States and 18 years of age or older. The Program is provided to individuals only. Corporations, associations, or other groups may not participate in the Program. It is fraudulent for any individual or company, association, or group to direct, encourage, or allow individuals to use a single Program account for the purpose of accumulating points for combined use. You must
            provide true, accurate and complete information as required, and you must keep your account information up to date at all times. You may not create an account using another person’s name or information, including email address and/or phone number. You may create only one account for yourself. If more than one account is created by You, Sponsor reserves the right to terminate any multiple account(s) created and withdraw any Points earned and associated with such account(s). You are
            solely responsible for the activity that occurs on your account and for keeping your account password secure. You may never use another person’s account or registration information for the Program without permission. You must notify us immediately of any breach of security or unauthorized use of your account. You should never publish, distribute, or post login information for your account.{' '}
          </p>
          <p className="terms-description">
            <b>2. PROGRAM AND PROGRAM POINTS.</b>
          </p>
          <ol type="A">
            <li>
              <p className="terms-description">
                {' '}
                A. <u>Registering for the Program</u>. You may participate in the Program by making a Qualifying Purchase (defined below) and then visiting the Program Website (<a href="/home">www.myuniversalrewards.com</a> A “Qualifying Purchase” is any one (1) eligible Universal Pictures Home Entertainment title. To register for the Program, you must first make a Qualifying Purchase and then visit the Website. You must then input your information onto the registration page, including your full
                name (no initials), date of birth, gender, and current valid email address. You may either enter the PIN code found on the insert inside the title’s packaging (“PIN Code”) or connect your MOVIES ANYWHERE Account. You must have made a purchase within fourteen days of registrations and have your digital retailer connected to your MOVIES ANYWHERE Account. By registering for the Program, Participant agrees to be added to the Universal Pictures Home Entertainment email marketing list.
                Participant may opt-out at any time after receiving their first email from Universal Pictures Home Entertainment.{' '}
              </p>
            </li>
            <li>
              <p className="terms-description">
                {' '}
                B.{' '}
                <u>
                  Earning Points and/or Prizes <b>(“Rewards”)</b>
                </u>
                . Through the Program, Participants can earn points (<b>“Points”</b>) which can be redeemed for Rewards. Rewards may include, without limitation, Digital Movies, and other prizes. Points are earned through various eligible activities (each a <b>“Qualifying Activity”</b>) as detailed below and on the Website, and may include, without limitation, purchasing an eligible New Release Title, Classic Title, or Movies Anywhere Title (each a <b>“Qualifying Purchase”</b>). You agree that
                Sponsor may change any Qualifying Activity from time to time.{' '}
              </p>
              <ol>
                <li>
                  <p className="terms-description">
                    {' '}
                    i. <i>Purchase of one New Release Title</i>. Participants may earn Points through the purchase of one (1) eligible New Release Title by going to the Website and either: i) entering the PIN code or ii) uploading an image of the purchase receipt (“Receipt”) of their Qualifying Purchase. Participants may upload a photo of their Receipt on the Website by clicking the “Receipt Upload” link under “Validate Purchase” and then clicking the “Upload Receipt” button. You may upload an
                    image of either a physical or online Receipt. However, an eligible Receipt must be uploaded within six (6) months of purchase of the applicable New Release Title, there is a limit of two (2) purchase submissions for New Release Titles per Participant per day and your image must be clear, legible, in a file size and type as stated on the Website. Receipts (and/or images thereof) that do not meet the foregoing requirements will be deemed invalid and ineligible for Points.
                    Images of uploaded Receipts are subject to validation.{' '}
                  </p>
                </li>
                <li>
                  <p className="terms-description">
                    {' '}
                    ii. <i>Purchase of a Classic Title</i>. Participants may earn Points through the purchase of two (2) eligible classic titles by going to the Website and either: i) entering the PIN code from each of the two Classic Titles, or ii) uploading an image of the single Receipt of their Qualifying Purchase or of two separate Receipts constituting their Qualifying Purchase.{' '}
                  </p>
                  <p className="terms-description">Participants may upload a photo of their Receipt on the Website by clicking the “Receipt Upload” link under “Validate Purchase” and then clicking the “Upload Receipt” button.</p>
                  <p className="terms-description">
                    {' '}
                    You may upload an image of either a physical or online Receipt. However, an eligible Receipt must be uploaded within six (6) months of purchase of the applicable Classic Titles, there is a limit of four (4) purchase submissions for Classic Titles per Participant per day, and your image must be clear, legible, in a file size and type as stated on the Website. Receipts (and/or images thereof) that do not meet the foregoing requirements will be deemed invalid and ineligible for
                    Points. Images of uploaded Receipts are subject to validation.{' '}
                  </p>
                </li>
                <li>
                  <p className="terms-description">
                    {' '}
                    iii. <i>Movies Anywhere Purchases</i>. Participants who connect their Movies Anywhere (<b>“MA”</b>) account to the Program will be eligible to earn points for any purchases made after the account sync is complete. Notwithstanding the foregoing, with respect to the two (2) week period prior to the date that such MA account sync is completed, Participants may earn points in connection with one (1) Qualifying Purchase made within such two (2) week period. For clarity,
                    Participants will not be eligible to (i) earn points in connection with more than one (1) Qualifying Purchase during such two (2) week period, and/or (ii) earn points for any purchases made prior to such two (2) week period. Participants must be responsible for ensuring the email address used on the Program is the same they are using for MA in order to be credited properly for their purchases. Points for purchases may not be available on all titles or formats.{' '}
                  </p>
                  <p className="terms-description">For clarity, Qualifying Purchases shall be based on a format-basis rather than on a title-basis. By way of example only, if a Participant purchases two (2) of the same Classic Titles on the same format (e.g. on DVD only), such purchase shall be considered only one.</p>
                  <p className="terms-description"> (1) Qualifying Purchase and Participant will obtain Points for only one (1) purchase. However, if a Participant purchases the same Classic Title on different formats (e.g. on DVD, 4K UHD, digital SD 480, HD 720, and HD 1080 formats) then such purchases shall be considered five (5) separate Qualifying Purchases and Participant will obtain Points for such five (5) purchases. </p>
                </li>
              </ol>
            </li>
            <p className="terms-description">
              {' '}
              Qualifying Activities and the Point values for such Activities are subject to change at any time in Sponsor’s sole discretion, but no change will be considered effective until such changes have been published on the Website with at least thirty (30) days notice. You expressly agree that Sponsor shall not be responsible for the loss of any Points and/or Digital Movies you earn through the Program, regardless of monetary value, in the event there is any significant data or server
              error, criminal act, vandalism, cyber-attack or other events which make it impossible or impracticable for Sponsor to determine the number of Digital Movies and/or Point totals for any account or accounts.{' '}
            </p>
          </ol>
          <p className="terms-description">
            {' '}
            3. <b>DIGITAL MOVIE</b>. Digital Movies are available at{' '}
            <a routerlink="/rewards" className="terms-link" href="/rewards">
              www.myuniversalrewards.com/redeemrewards
            </a>{' '}
            (<b>“Rewards Site”</b>) to Participants. To redeem a digital movie, follow the instructions provided on the Rewards Site upon accessing the Program and choosing the Digital Movie. The Digital Movie is subject to terms and conditions available at{' '}
            <a href="/termsofuse" className="terms-link">
              www.myuniversalrewards.com/termsofuse
            </a>
            . Digital Movies are not redeemable or refundable for cash and cannot be exchanged. Access to and streaming of any Digital Movie is subject to applicable law. Digital Movies are non-transferrable and may not be resold. Any attempt to sell a Digital Movie will result in forfeiture of access to the Digital Movie. No substitution or exchange will be allowed, except by Sponsor, who reserves the right to substitute a Digital Movie with an offer item of equal or greater value in case of
            unavailability of any Digital Movie or of a force majeure event. Digital Movies are a gift with purchase offer and Sponsor may change the gift with purchase available in the Program at any time without notice.{' '}
          </p>
          <p className="terms-description">
            {' '}
            4. <b>POINTS REDEMPTION FOR REWARDS</b>. In addition to the Digital Movies that Participants can receive through the Program, there are also Digital Content Rewards. Participants may use Points to redeem digital content including, without limitation, digital wallpapers, video conferencing backgrounds, and printable activity and coloring sheets (<b>“Digital Content”</b>) solely for personal, non-commercial use on your mobile device, tablet, desktop or laptop computer systems.
            Downloading of the video conferencing backgrounds is subject to the Supplemental Universal All-Access Rewards Program Terms and Conditions (click{' '}
            <a href="/public/UniversalAllAccess_TermsandConditionsForDownloadableConferenceBackgrounds.pdf" target="_blank" className="terms-link">
              here
            </a>{' '}
            to read in full). You agree that you will not use the Digital Content for any purpose that is unlawful, commercial in nature (including but not limited to promoting a product or service), prohibited by the Terms, or any other purpose not reasonably intended by Universal. Sponsor makes no warranty in any respect as to any Digital Content available within the Program{' '}
          </p>
          <p className="terms-description">
            {' '}
            5. <b>PROGRAM ACCOUNT</b>. Sponsor may, in its sole and absolute discretion, suspend, terminate, change, or modify your account, and/or any aspect of the Program, Points, and/or Digital Movies at any time, including your access to the Program or the availability of any Digital Movie, Prize, or other Program gift or reward, with no less than thirty (30) days notice of any material change. Sponsor also has the right to withdraw Points associated with cancellation of pre-orders of
            eligible films through digital retailers and/or returns of eligible films. Sponsor reserves the right to adjudicate all Point discrepancies in its sole discretion, and the Participants agree to abide with any such adjudication. Digital Movies and Points will be available immediately to Participants who upload PIN Codes in compliance with these Terms. Membership in the Program, any Points earned, any Digital Movies, and any other related membership benefits are not transferable and
            cannot be combined or merged with any other Participant’s account or with any other NBCUniversal affiliate’s loyalty program(s). Submission of Receipts will be subject to verification and may result in Digital Movies and/or Points not being available for up to 48 hours following the successful submission of the Receipt(s) from Qualifying Purchase(s). Once posted, all Digital Movies and/or Points will be immediately available for redemption. Sponsor may freeze or eliminate
            Participant’s account if fraudulent activity is suspected and/or if PIN Codes or Receipts submitted are not directly related to Participant’s own shopping activity. Specifically, Participant may not submit PIN Codes or Receipts resulting from purchases made by someone other than Participant and/or from a site that resells PIN Codes. PIN Codes and Receipts uploaded to the Website will not be viewable by the public and shall be used solely for the purpose of verifying Qualifying
            Purchases and/or Qualifying Activities. If you violate any of the provisions contained in these Terms, Sponsor reserves the right to issue you a warning regarding the violation or immediately terminate or suspend any accounts you have created using the Program. You agree that Sponsor need not provide you notice before terminating or suspending your account(s). Notwithstanding the foregoing, except if due to your negligence or breach, termination of your account will not affect any
            Digital Movies and/or Points you have earned prior to termination. Any and all Points earned are considered a limited, non-exclusive, revocable, non-assignable, personal and nontransferable right solely for use towards the redemption of Digital Movies and Digital Content Rewards. Accordingly, you have no property, proprietary, intellectual property, ownership, or monetary interest in your Points which remain Sponsor’s property at all times. Digital Movies and/or Points have no cash
            or monetary value, cannot be converted to cash, and, if not redeemed within one month of termination of a Participant’s account, they shall be deemed void. Sponsor reserves the right to refuse access to the Program and/or Website without notice for any reason, including, but not limited to, a violation of the Terms. You agree that Sponsor may discontinue the Program or change the content or nature of the Program at any time, for any reason, with or without notice to you, without
            liability. If we make changes that materially affect your use of a Program or the Website, at least thirty (30) days before a material change, we will post a notice of the change on the Website. You are under an obligation to review the updated, current version of these Terms and other published Sponsor policies before using the Program. The Program, Digital Movies and/or the Prizes are void where prohibited by law.{' '}
          </p>
          <p className="terms-description">
            {' '}
            Should Sponsor terminate the Program, any Points remaining in your Program account thirty (30) days after the Program stops operating will be forfeited without compensation. Notwithstanding anything to the contrary stated in these Terms, Sponsor makes no guarantee or representation as to the length of time during which the Program will be offered, and the Program could end at any time. Program Participants will have thirty (30) days after the Program end date during which to redeem
            any remaining Points in their Program account. After such thirty (30) day period, all Points will expire and the Program will no longer be available, and no further liability will be owed by Sponsor to any Participant.{' '}
          </p>
          <p className="terms-description">
            {' '}
            6. <b>PARTICIPANT CONTENT AND USE</b>. You are solely responsible for your account, password, profile, information, and any other content you upload or submit to or through the Website. Sponsor is not responsible for any data lost during transmission. You agree not to access (or attempt to access) the Program or Website by any means other than through the interface that we provide. You specifically agree not to access (or attempt to access) any of the Program through any automated
            means (including use of scripts or web crawlers). Furthermore, you agree that you will not engage in any activity that interferes with or disrupts the Program or Website (or the servers and networks which are connected to the Program/Website), and that should Sponsor suspect such activity, we shall have the right to terminate your account immediately.{' '}
          </p>
          <p className="terms-description">
            {' '}
            SPONSOR EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING: (i) ALL WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NONINFRINGEMENT, AND ANY AND ALL WARRANTIES ARISING FROM COURSE OF DEALING AND USAGE OF TRADE; (ii) THAT THE PROGRAM, DIGITAL MOVIES, PRIZES AND WEBSITE WILL MEET YOUR REQUIREMENTS, WILL ALWAYS BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE OR OPERATE WITHOUT ERROR; (iii) AS TO THE RESULTS THAT MAY BE
            OBTAINED FROM THE OPERATION, USE OR OTHER EXPLOITATION OF THE PROGRAM OR WEBSITE; (iv) AS TO THE ACCURACY OR RELIABILITY OF ANY INFORMATION OBTAINED FROM OR THROUGH THE PROGRAM OR WEBSITE; and (v) ANY PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR USE OF THE PROGRAM, WEBSITE, AND/OR USE OR MISUSE OF A DIGITAL MOVIE, PRIZE, OR OTHER PROGRAM GIFT OR REWARD. SPONSOR’S MAXIMUM LIABILITY TO YOU FOR ANY DAMAGES OR LIABILITY ARISING IN CONNECTION WITH THE
            PROGRAM, DIGITAL MOVIES AND/OR PRIZES, WHETHER ARISING IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, SHALL BE A CREDIT OF THE AMOUNT OWED FOR THE DIGITAL MOVIE OR PRIZE BASED ON SUCH DIGITAL MOVIE OR PRIZE’S POINTS VALUE ASSIGNED BY UNIVERSAL IN ITS SOLE DISCRETION. No advice or information, whether oral or written, obtained by you from Sponsor or through the Program or Website will create any warranty not expressly stated herein.{' '}
          </p>
          <p className="terms-description">
            {' '}
            7. <b>INDEMNIFICATION</b>. You hereby agree to defend, indemnify, and hold harmless Sponsor, 3 tier logic Inc., Votigo, Inc., and each of their parents, subsidiaries and affiliates, and each of their respective employees, officers, directors, stockholders, suppliers, affiliates, contractors, agents and successors, from and against any and all claims, damages, losses, costs, investigations, liabilities and expenses, including reasonable attorneys’ fees, that arise from or relate to
            your use or misuse of, or access to, the Program, Website, your Participant content, violation of these Terms, or violation by you or any third party using your account or identity in the Program, of any intellectual property or other right of any person or entity. You will cooperate as fully required by Sponsor in the defense of any matter otherwise subject to indemnification by you, in which event you will assist and cooperate with us in asserting any available defenses.{' '}
          </p>
          <p className="terms-description">
            8. <b>MISCELLANEOUS</b>.
          </p>
          <ol>
            <li>
              <p className="terms-description">
                A. <u>Force Majeure</u>. We shall not be liable for any failure to perform our obligations hereunder where such failure results from any cause beyond our reasonable control, including, without limitation, mechanical, electronic or communications failure or degradation.
              </p>
            </li>
            <li>
              <p className="terms-description">
                B. <u>Assignment</u>. These Terms are personal to you, and are not assignable, transferable, or sublicensable by you except with our prior written consent. We may assign, transfer or delegate any of our rights and obligations hereunder without consent.
              </p>
            </li>
            <li>
              <p className="terms-description">
                C. <u>Agency</u>. No agency, partnership, joint venture, or employment relationship is created as a result of these Terms and neither party has any authority of any kind to bind the other in any respect.
              </p>
            </li>
            <li>
              <p className="terms-description">
                {' '}
                D. <u>Severability</u>. If any term or change to these Terms is found invalid, void, or for any reason unenforceable, that term or change will be severable and will not affect the validity and enforceability of any remaining terms, changes, or conditions, all of which will remain in full force and effect. Sponsor will not be liable to you in any way for any value or benefits you may lose as a result of any changes made to the Program, including, without limitation, any Points or
                Prizes.{' '}
              </p>
            </li>
            <li>
              <p className="terms-description">
                {' '}
                E. <u>No Waiver</u>. Our failure to enforce any part of these Terms shall not constitute a waiver of our right to later enforce that or any other part of these Terms. Waiver of compliance in any particular instance does not mean that we will waive compliance in the future. In order for any waiver of compliance with these Terms to be binding, we must provide you with written notice of such waiver through one of our authorized representatives.{' '}
              </p>
            </li>
            <li>
              <p className="terms-description">
                F. <u>Headings</u>. The section and paragraph headings in these Terms are for convenience only and shall not affect their interpretation.
              </p>
            </li>
          </ol>
          <p className="terms-description">
            <i>These Terms are effective and were last updated on September 1, 2022</i>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
