import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Row, Col, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';

import IsLoadingHOC from './IsLoadingHOC';
import { eligibleProductListOpenAction } from './../redux/actions';
import TableReport from './TableReport';
const EligibleProductList = props => {
  const { t } = useTranslation();
  const [productList, setProductList] = useState([]);
  const [popupShow, setPopupShow] = useState(false);
  const validationTypes = { 'receipt-upload': 'Receipt Upload', pincode: 'Product Insert Code' };
  const headerFormatter = (column, colIndex) => {
    return (
      <label>
        {' '}
        {column.text}{' '}
        <OverlayTrigger delay={{ hide: 450, show: 300 }} overlay={props => <Tooltip {...props}>{`${t('Submit Code')}: ${t('Look for an insert inside for a unique code to submit for validation')} ${t('Receipt Upload')}: ${t('Upload image of receipt proof-of-purchase to validate')}.`}</Tooltip>} placement="bottom">
          <span className="eligible-product-list-tooltrip-span">?</span>
        </OverlayTrigger>
      </label>
    );
  };
  const columns = [
    { dataField: 'productName', text: t('Title') },
    { dataField: 'validationType', text: t('Validation Type'), headerFormatter: headerFormatter },
    { dataField: 'productType', text: t('Format') },
    { dataField: 'UPC', text: t('UPC') },
  ];
  useEffect(() => {
    props.setLoading(true);
    eligibleProductListOpenAction({})
      .then(responseData => {
        if (responseData.httpCode === 200) {
          responseData.data.content = responseData.data.content.map(v => {
            return { ...v, validationType: validationTypes[v?.validationType || 'na'] ? validationTypes[v?.validationType || 'na'] : v?.validationType || '' };
          });
          setProductList(responseData.data.content);
        } else {
          setProductList([]);
          console.log('responseData', responseData);
        }
        props.setLoading(false);
      })
      .catch(err => {
        props.setLoading(false);
        setProductList([]);
        console.log('Response Error:', JSON.stringify(err));
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {' '}
      <div className="participating-movies py-5 ">
        <Container fluid>
          <h1 className="text-center">
            <Trans>Eligible Products</Trans>
          </h1>
          <Row md={1} xs={1} sm={1} className="text-center eligible_subheadings">
            <div>
              <p className="subheading py-1">Movies may be eligible through submitting a code, receipt upload or as a digital purchase through linking to MOVIES ANYWHERE.</p>
            </div>
            <div>
              <p className="subheading_para pt-2">
                Check our list below to see which disc formats for a movie require using the insert provided inside the case or uploading your receipt after purchase if no insert is present inside. Click <a onClick={() => setPopupShow(true)}>here</a> to see an example of the All-Access Rewards insert.
              </p>
              <p className="subheading_para pt-2">
                For eligible digital movie purchases through linking to MOVIES ANYWHERE, view the list{' '}
                <a href="https://moviesanywhere.com/collection/universal-all-access-rewards " rel="noopener noreferrer" target="_blank">
                  here
                </a>
                .{' '}
              </p>
            </div>
          </Row>
        </Container>
      </div>
      <Container fluid className="px-5 py-5">
        <Row>
          <Col xs lg="12" className="text-center">
            <TableReport searchTextboxEnable={true} keyField="id" data={productList} columns={columns} />
          </Col>
        </Row>
      </Container>
      <Modal show={popupShow} onHide={() => setPopupShow(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <Row md={2} xs={2}>
            <Col className="bg-enable">
              <p className="dialog-color text-center dialog_text">
                IF THIS IS YOUR INSERT,{' '}
                <u>
                  <a className="dialog-color link_text">GO BACK</a>
                </u>{' '}
                AND ENTER YOUR CODE!
              </p>
              <img alt="DVD Cover" width="100%" src="/public/assets/images/Rewards_insert_NEW_SEPT2022_crop.jpg" className="pt-4" />
            </Col>
            <Col>
              <p className="dialog-color text-center f9saga">
                IF THIS IS YOUR INSERT, PLEASE VISIT{' '}
                <u>
                  <a href="/home" rel="noopener noreferrer" target="_blank" className="dialog-color link_text">
                    UNIVERSALREDEEM.COM
                  </a>
                </u>{' '}
                TO REDEEM YOUR DIGITAL COPY
              </p>
              <span className="material-symbols-outlined close-icon" onClick={() => setPopupShow(false)}>
                close
              </span>
              <img alt="DVD Cover" width="100%" src="/public/assets/images/MA_insert_NEW_SEPT2022_crop.jpg" />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IsLoadingHOC(EligibleProductList, 'Loading....');
