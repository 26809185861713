import React, { useState, useEffect } from 'react';
import { Row, Accordion, Card, Nav } from 'react-bootstrap';
import { Trans } from 'react-i18next';

const FAQ = () => {
  const [data, setData] = useState([
    { id: 1, clicked: false, name: ' What is Universal Pictures All-Access Rewards? ', content: `Universal's All-Access Rewards Program is where movie fans can join our community! As a part of the program, you can <a href="/earnpoints">earn points</a> and <a href="/rewards">redeem rewards</a>.` },
    {
      id: 2,
      clicked: false,
      name: ' How do I earn points when I’m an All-Access member?',
      content: ` Many titles are eligible for points, but not necessarily all; they’re always changing.<br/>• For <b>disc purchases</b>, click <a href="/eligible-movies">here</a> to view our current qualifying movie selection.<br/>• For <b>digital purchases</b>, click <a href="https://moviesanywhere.com/collection/universal-all-access-rewards" target="_blank">here</a> to view our current qualifying movie selection. Over 1,000 movies are eligible!<br/><br/>Please note that TV series or episode purchases (disc or digital) are not currently included in our program.`,
    },
    { id: 3, clicked: false, name: ' Are all Universal movie titles eligible for points?', content: `A lot of titles are eligible for points but not necessarily all, they’re always changing. <a href="/validate-code">Click here</a> to take a peek at our current available movie selection, or <a href="/support">contact us</a> with any questions. If your product doesn’t have an insert with a pincode you can always upload your <a href="/upload-receipt">receipt here</a>.` },
    { id: 4, clicked: false, name: ' I tried to register but something went wrong. What’s next?', content: `If you’re having trouble registering, please contact our <a href="/support">support team</a>.` },
  ]);

  const [faqStatus, setFaqStatus] = useState(false);

  const toggleHandler = i => {
    let tempData = JSON.parse(JSON.stringify(data));
    tempData[i].clicked = tempData[i].clicked ? false : true;
    tempData = tempData.map((v, j) => {
      return { ...v, clicked: j != i ? false : v.clicked };
    });
    setData(tempData);
  };
  const bulkActionHandler = () => {
    let status = !faqStatus;
    setFaqStatus(status);
    let tempData = data.map(v => ({ ...v, clicked: status }));
    setData(tempData);
  };
  return (
    <Accordion>
      {data.map((v, i) => {
        return (
          <Card style={{ marginBottom: '0px' }} className={v.clicked ? 'bg-white' : ''}>
            <Card.Header>
              <Accordion.Toggle activeKey={v.clicked} as={Nav} variant="link" onClick={e => toggleHandler(i)} eventKey={v.id} data-toggle="collapse" aria-expanded="true" aria-controls="collapse">
                <Row style={{ cursor: 'pointer' }}>
                  <div className="col-11 header-title">
                    <Trans>{v.name}</Trans>
                  </div>
                  <div className="col-1 text-right pl-0 faq_open-close-icon">{v.clicked ? <span className="black material-symbols-outlined">expand_less</span> : <span className="material-symbols-outlined black">expand_more</span>}</div>
                </Row>
              </Accordion.Toggle>
            </Card.Header>
            {v.clicked ? (
              <Accordion>
                <Card.Body className="black" dangerouslySetInnerHTML={{ __html: v.content }} />
              </Accordion>
            ) : (
              <Accordion.Collapse>
                <Card.Body className="black " dangerouslySetInnerHTML={{ __html: v.content }} />
              </Accordion.Collapse>
            )}
          </Card>
        );
      })}
    </Accordion>
  );
};

export default FAQ;
