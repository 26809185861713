import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import IsLoadingHOC from './IsLoadingHOC';
import { useDispatch } from 'react-redux';
import { secondFactorAuthAction, saveAccessToken, saveRefreshToken, balanceGetAction, notificationGetAction } from '../redux/actions';
import { USER_DATA_SAVE } from '../redux/actions/types';
import Timer from './Timer';
import { toast } from 'react-toastify';

const SecondFactorAuth = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const setLoading = props.setLoading;
  const dispatch = useDispatch();
  // const [resendBtnEnable, setResendBtnEnable] = useState(false);
  const [otpTextbox, setOtpTextbox] = useState('');
  const OTPDigit = !isNaN(props.otpLen) ? parseInt(props.otpLen) : 6;
  const waitingTime = !isNaN(props.waitingTime) ? parseInt(props.waitingTime) : 30;
  //let [otpInputList, setOtpInputList] = useState(new Array(OTPDigit).fill(""));
  const timeCompleted = () => {
    //setResendBtnEnable(true);
    window.location.reload();
  };
  // const handleChange = (v, ind) => {
  // 	let temArr = [...otpInputList];
  // 	temArr[ind] = v;
  // 	setOtpInputList(temArr);
  // };
  const validateOTPHandler = () => {
    setLoading(true);
    //let otp = otpInputList.join("").trim();
    let requestData = Object.assign({}, props.data, { otp: otpTextbox });
    secondFactorAuthAction(requestData)
      .then(response => {
        setLoading(false);
        if (response.data) {
          dispatch({ type: USER_DATA_SAVE, payload: response.data });
          const { accessToken, refreshToken } = response.data;
          dispatch(saveAccessToken(accessToken));
          dispatch(saveRefreshToken(refreshToken));
          dispatch(balanceGetAction());
          dispatch(notificationGetAction());
          toast.success(t('User login successfully'));
          history.push('/home');
        } else {
          toast.error(t('Invalid OTP'));
        }
      })
      .catch(error => {
        toast.error(t('Invalid OTP'));
        setLoading(false);
      });
  };
  const handleTexboxChange = e => {
    setOtpTextbox(e.target.value);
  };
  return (
    <Container fluid>
      <Row xs={1} md={1} lg={1}>
        <div className="position-relative">
          <div className="card p-2 text-center">
            <h6>
              <Trans>Please enter the one time password to verify your account</Trans>
            </h6>
            <Form>
              <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                <Form.Label column sm="2">
                  <Trans>OTP</Trans>
                </Form.Label>
                <Col sm="10">
                  <Form.Control placeholder={t('OTP')} value={otpTextbox} onChange={handleTexboxChange} type="text" id={`otp_input`} maxlength={OTPDigit} />
                </Col>
              </Form.Group>

              {/* {otpInputList.map((v, i) => {
								return <input className="m-2 otp-input text-center form-control rounded" value={v} onChange={(e) => handleChange(e.target.value, i)} type="text" id={`otp_input_${i}`} maxlength="1" />;
							})} */}
              <div className="mt-4">
                <Button disabled={otpTextbox.length === OTPDigit ? false : true} onClick={validateOTPHandler} className="btn btn-danger px-4 validate">
                  <Trans>Validate</Trans>
                </Button>
              </div>
            </Form>
          </div>
          <div className="card-2">
            <Row>
              {/* <Col md={6} className="text-center">
								<Trans>Didn't get the code</Trans>
							</Col>
							<Col md={6} className="text-center">
								{resendBtnEnable ? (
									<Button className="btn btn-default">
										<Trans>Resend</Trans>
									</Button>
								) : (
									<Timer pauseTimer={false} initialMinute={Math.floor(waitingTime / 60)} initialSeconds={waitingTime % 60} timeCompleted={timeCompleted} />
								)}
							</Col> */}
              <Col md={12} className="text-center">
                <Timer pauseTimer={false} initialMinute={Math.floor(waitingTime / 60)} initialSeconds={waitingTime % 60} timeCompleted={timeCompleted} />
              </Col>
            </Row>
          </div>
        </div>
      </Row>
    </Container>
  );
};
export default IsLoadingHOC(SecondFactorAuth, 'Loading....');
