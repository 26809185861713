import React, { useEffect, useState } from 'react';
import Card from './Card';
import { eligibleProductListOpenAction } from './../redux/actions';
import { Button, Container, Col, Row, Modal } from 'react-bootstrap';
import IsLoadingHOC from './IsLoadingHOC';
import { Trans, useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

const RewardsList = props => {
  const [data, setData] = useState([]);
  const [pageRedirect, setPageRedirect] = useState(false);
  useEffect(() => {
    getEligibleMoviesList();
    // eslint-disable-next-line
  }, []);

  const getEligibleMoviesList = () => {
    props.setLoading(true);
    eligibleProductListOpenAction({})
      .then(response => {
        if (response.httpCode === 200) {
          let list = (response?.data?.content || []).filter(v => v.newArrival);
          setData(list);
        }
        props.setLoading(false);
      })
      .catch(ex => {
        console.log('Error In API Call', ex);
        props.setLoading(false);
      });
  };
  const cardClick = async redirectUrl => {
    setPageRedirect(redirectUrl);
  };

  if (pageRedirect) {
    return <Redirect push to={pageRedirect} />;
  } else {
    return (
      <Container fluid>
        <h2 className="card-list-title py-2">
          <Trans>EARN POINTS BY BUYING MOVIES</Trans>
        </h2>
        <p id="p-home" className="ng-star-inserted">
          <p id="p-home">
            <b>HUNDREDS</b> of Movies are Eligible for Rewards Points! Go to our{' '}
            <a className="link_text" href="/eligible-movies">
              Eligible Movies
            </a>{' '}
            page for a Complete List of Disc purchases. Visit{' '}
            <a href="https://moviesanywhere.com/welcome" className="link_text">
              MOVIES ANYWHERE
            </a>{' '}
            for Digital Purchases.
          </p>
        </p>
        <Row xs={2} md={6} lg={8} className="card_holder">
          {data.map((item, index) => {
            return (
              <Col key={index}>
                <Card>
                  <div onClick={e => cardClick(item)} className="activity-block">
                    <div className="activity-img-holder d-flex align-items-center">
                      <img src={item.image1} alt={item.productName} style={{ width: '100%' }} />
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    );
  }
};

export default IsLoadingHOC(RewardsList, 'Loading....');
