import React, { useState } from 'react';
import { logoutAction } from '../redux/actions';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Modal, Button, CloseButton } from 'react-bootstrap';
import IsLoadingHOC from './IsLoadingHOC';

const Logout = props => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  const handleLogout = () => {
    props.setLoading(true);
    setShow(false);
    props
      .logoutAction()
      .then(response => {
        props.setLoading(false);
        window.location.href = '/home';
      })
      .catch(ex => {
        console.log('Error In API Call', ex);
        props.setLoading(false);
        window.location.href = '/home';
      });
  };
  return (
    <>
      <Link id="button-logout" onClick={handleShow} className="nav-link" to="#">
        <span className="material-symbols-outlined">exit_to_app</span>
        <Trans>LOGOUT</Trans>
      </Link>

      <Modal show={show}>
        {/* <Modal.Header>
					<Modal.Title>
						<Trans>Confirm Logout</Trans>
					</Modal.Title>
					<CloseButton onClick={handleClose} />
				</Modal.Header> */}
        <Modal.Body>
          <div className="justify-content-center text-center">
            <Modal.Title className="mb-3">
              <Trans>Confirm Logout</Trans>
            </Modal.Title>
            <Trans>Are you sure you want to logout now</Trans>?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="justify-content-center text-center" style={{ margin: 'auto' }}>
            <Button variant="secondary" size="sm" onClick={handleClose} className="mr-2">
              <Trans>Close</Trans>
            </Button>
            <Button variant="secondary" size="sm" onClick={handleLogout}>
              <Trans>Logout</Trans>
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default connect(null, { logoutAction })(IsLoadingHOC(Logout, 'Loading....'));
