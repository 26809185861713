import React from 'react';
import { Container } from 'react-bootstrap';
//import {footerCopyRight} from "../data";
import { useTranslation } from 'react-i18next';

const CopyRight = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center">
      <div className="copy-text text-center">
        <p className="text-center">©{new Date().getFullYear()} Universal Studios. All Rights Reserved.</p>
      </div>
      <p className="tierlogic text-center py-4">
        {/* {footerCopyRight.poweredText} */}
        {/* {t("Powered By ")} */}
        <span className="d-block d-sm-inline-block text-center">
          {t('Powered By ')}
          <a href="https://www.3tierlogic.com/" target="_blank">
            <img alt="3TL logo" src="/public/assets/images/3tl-logo-footer.png" height="22px" />
          </a>
        </span>
      </p>
    </div>
  );
};

export default CopyRight;
