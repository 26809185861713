import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import IsLoadingHOC from '../IsLoadingHOC';
import { toast } from 'react-toastify';
import { uploadImageAction, validateUploadReceiptAction } from '../../redux/actions';
import PreviousReceiptList from './PreviousReceiptList';
import FileDropZone from '../FileDropZone';
import { useSelector } from 'react-redux';
import Captcha from '../CaptchaClick';
import { generatePdfFromImages } from '../../hooks/ImagesToPDF';
import { Link } from 'react-router-dom';

const UploadReceipt = props => {
  const { t } = useTranslation();
  const config = useSelector(state => state.app.config);
  const [popupShow, setPopupShow] = useState(false);
  const [guideShow, setGuideShow] = useState(false);
  let [captchaValue, setCaptchaValue] = useState(false);
  let [resetCaptchaValue, setResetCaptchaValue] = useState(0);
  let [captchaEnable, setCaptchaEnable] = useState(false);
  let [multiReceiptUpload, setMultiReceiptUpload] = useState(false);
  let [selectedFile, setSelectedFile] = useState([]);
  const [receiptLoadCounter, setReceiptLoadCounter] = useState(1);
  let [enableUploading, setEnableUploading] = useState(true);
  React.useEffect(() => {
    if ((config?.captchaConfig?.recaptchaVersion || '').toLowerCase() === 'v3') {
      const interval = setInterval(() => {
        setResetCaptchaValue(resetCaptchaValue + 1);
      }, 45000);
      return () => clearInterval(interval);
    }
  }, [resetCaptchaValue, config]);
  React.useEffect(() => {
    if (config.receiptUploadPreValidation) {
      checkUploadEnableOrNot(false);
    }
    if (config.captchaConfig) {
      if (config.captchaConfig.uploadReceiptCaptcha) {
        setCaptchaEnable(true);
      }
      if (config.multiReceiptUpload) {
        setMultiReceiptUpload(config.multiReceiptUpload);
      }
    }
    //eslint-disable-next-line
  }, [config]);
  const checkUploadEnableOrNot = uploadApiCall => {
    props.setLoading(true);
    validateUploadReceiptAction({})
      .then(responseData => {
        props.setLoading(false);
        setReceiptLoadCounter(receiptLoadCounter + 1);
        if (responseData['httpCode'] >= 200 && responseData['httpCode'] <= 299) {
          setEnableUploading(responseData.data.valid);
          if (uploadApiCall) {
            uploadFileHandler(responseData.data.token);
          }
          return true;
        } else {
          toast.error(`${t('Error')}! ${t('You have reached the program limit for rewards')}.`);
        }
      })
      .catch(err => {
        console.log('error', err);
        toast.error(`${t('Error')}! ${t('You have reached the program limit for rewards')}.`);
        props.setLoading(false);
      });
  };
  const uploadImage = () => {
    if (selectedFile.length === 0) {
      toast.error(`${t('Error')}! ${t('Select a receipt')}.`);
      return false;
    }
    if (!captchaValue && captchaEnable) {
      toast.error(`${t('Error')}! ${t('Please select captcha')}.`);
      return false;
    }
    if (config.receiptUploadPreValidation) {
      checkUploadEnableOrNot(true);
    } else {
      uploadFileHandler('');
    }
  };
  const uploadFileHandler = async token => {
    let fileData = selectedFile[0];
    if (selectedFile.length > 1) {
      fileData = await generatePdfFromImages(selectedFile);
    }
    const formData = new FormData();
    formData.append('type', 'receipt');
    formData.append('image', fileData);
    if (token) {
      formData.append('token', token);
    }
    if (captchaEnable) {
      formData.append('captchaVal', captchaValue);
    }
    setPopupShow(false);
    props.setLoading(true);
    uploadImageAction(formData)
      .then(responseData => {
        props.setLoading(false);
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        setResetCaptchaValue(resetCaptchaValue + 1);
        setReceiptLoadCounter(receiptLoadCounter + 1);
        if (responseData['httpCode'] === 200) {
          toast.success(`${t('Your receipt has been uploaded successfully. Please allow up to 5 business days for it to be processed.')}`);
          setSelectedFile([]);
          return true;
        } else if (responseData['httpCode'] === 409) {
          toast.error(`${t('Error')}! This receipt has already been uploaded. Please upload another receipt.`);
          setSelectedFile([]);

          return true;
        } else {
          toast.error(`${t('Error')}! ${t('Something went wrong')}.`);
          return false;
        }
      })
      .catch(err => {
        setResetCaptchaValue(resetCaptchaValue + 1);
        console.log('error', err);
        props.setLoading(false);
        if (typeof err === 'object') {
          if (err['response']) {
            if (err['response'].httpCode === 409) {
              //duplicate Receipt message
              toast.error(`${t('Error')}! This receipt has already been uploaded. Please upload another receipt.`);
              setSelectedFile([]);
              return false;
            } else {
              toast.error(`${t('Error')}! ${t('Something went wrong')}.`);
              return false;
            }
          } else {
            toast.error(`${t('Error')}! ${t('Something went wrong')}.`);
            return false;
          }
        } else {
          toast.error(`${t('Error')}! ${t('Something went wrong')}.`);
          return false;
        }
      });
  };
  const resetAllSelectionBtnClick = () => {
    setSelectedFile([]);
    setResetCaptchaValue(resetCaptchaValue + 1);
  };
  const handlePopupClose = () => {
    setPopupShow(false);
  };
  return (
    <>
      <div className="w-100 position-relative">
        <div className="banner-wrapper contest mb-5">
          <div className="top-banner">
            <div className="">
              {/* <img className="banner-img d-none d-sm-block" src="/public/assets/images/upload/PP_upload-Web Banner .jpg" alt="Upload receipt" />
              <img className="w-100 banner-img d-block d-sm-none" src="/public/assets/images/upload/PP_upload-Web mobile .jpg" alt="Upload receipt" /> */}
              <img className="banner-img d-none d-sm-block" src="/public/assets/images/homepage/desktop_banner_without_button.png" />
              <img className="w-100 banner-img d-block d-sm-none" src="/public/assets/images/homepage/mobile_banner_without_button.png" />
              <div className="container">
                <div className="d-sm-flex card-img-overlay">
                  <div className="col-sm-6 earnpoint-hero-banner white">
                    <h1 className="header-1">Plaid Perks™ is being discontinued.</h1>
                    <h4>
                      <strong>
                        Members have until August 21, 2023, to submit valid receipts or points codes to earn points and until September 5, 2023 to redeem their points for rewards at PlaidPerks.com. Click{' '}
                        <Link to="/faq" className="white-link">
                          here
                        </Link>{' '}
                        to learn more.
                      </strong>
                    </h4>
                    <p>Members will receive an email with instructions for how to redeem points after September 5, 2023.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container>
        <div className="post-login page-section-cut mt-5">
          <Row xs={1} md={1} lg={1} className="text-center justify-content-center">
            <Col md={12}>
              <h2 className="mb-4">
                <Trans>Upload your receipt</Trans>
              </h2>
            </Col>
            <Col md="12">
              <p className="text-center mb-0">
                <strong>Upload your receipt so you can get credit for your Nutrena purchases by taking a picture of your receipt within 180 days (or 6 months) of purchase.</strong>
              </p>

              <p className="text-center mt-2">For an approved receipt, make sure the photo of your receipt shows the following items:</p>
              <p className="text-center">
                - The store’s name <br />
                - The purchase date and time <br />
                - The Nutrena product’s name or SKU, price, quantity, and product total <br />- The purchase total
              </p>
              <p className="text-center">
                Please double-check requirements{' '}
                <button className="btn span-text-link" onClick={() => setGuideShow(true)}>
                  here
                </button>
                .
              </p>
            </Col>
          </Row>

          <div className="mb-3">
            <FileDropZone multiReceiptUpload={multiReceiptUpload} enableUploading={enableUploading} parentCallBack={setSelectedFile} selectedFile={selectedFile} />
          </div>
          <Row className="text-center justify-content-center">
            <Col md="12">
              <p className="text-center">Note: Receipt / invoice must be in JPG, JPEG, PNG, or PDF format and maximum 5MB.</p>
            </Col>
          </Row>
          {captchaEnable ? (
            <Row md={1} className="text-center justify-content-center">
              <Col className="text-center justify-content-center mx-auto captcha">
                <label htmlFor="g-recaptcha-response" className="col-sm-12 invisible" aria-hidden="true">
                  Google captcha
                </label>
                <Captcha reset={resetCaptchaValue} parentCallback={setCaptchaValue} />
              </Col>
            </Row>
          ) : (
            ''
          )}
          {enableUploading ? (
            <Row className="justify-content-center">
              <div className="row my-4">
                <div className="d-inline col-6">
                  <Button size="lg" type="button" onClick={() => (selectedFile.length > 0 ? setPopupShow(true) : uploadImage())} className="btn btn-primary" id="upload-btn">
                    <Trans>Submit</Trans>
                    {}
                  </Button>
                </div>
                <div className="d-inline col-6">
                  <Button size="lg" type="button" variant="light" className="px-5" onClick={resetAllSelectionBtnClick} id="upload-btn">
                    <Trans>Reset</Trans>
                  </Button>
                </div>
              </div>
            </Row>
          ) : (
            ''
          )}
          <Row className="justify-content-center my-4">
            <Col md="9">
              <PreviousReceiptList reloadCounter={receiptLoadCounter} />
            </Col>
          </Row>
        </div>

        <Modal show={popupShow} centered>
          <Modal.Body>
            <div className="justify-content-center text-center">
              <p className="text-center">
                <Trans>Are you sure</Trans>?
              </p>
              <button className="btn btn-primary mr-3" onClick={uploadImage}>
                Yes
              </button>
              <button className="btn btn-light ml-3" onClick={() => setPopupShow(false)}>
                No
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={guideShow} onHide={() => setGuideShow(false)} size="xl">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="justify-content-center text-center overflow-auto">
              <div className="">
                <img src="/public/assets/images/earn-points/PlaidPerks_Infographic.png" width="100%" />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};

export default IsLoadingHOC(UploadReceipt, 'Loading....');
