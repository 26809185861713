import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Contests from '../../components/Contests';
import { Button, Container, Col, Row, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
// import WordSearchGame from '../../components/contest/WordSearchGame';
const Contest = props => {
  const history = useHistory();
  // useEffect(() => {
  //   toast.error(`This webpage is not available.`);
  //   history.push("/home");
  // }, []);

  return (
    <>
      {/* products card */}
      <div className="w-100 position-relative">
        <div className="banner-wrapper contest mb-5">
          <div className="top-banner">
            <div className="">
              <img className="banner-img d-none d-sm-block" src="/public/assets/images/contest/NUT_PPWeb_Contest_DT.png" alt="Contest" />
              <img className="w-100 banner-img d-block d-sm-none" src="/public/assets/images/contest/NUT_PPWeb_Contest_Mob.png" alt="Contest" />
              {/* <img className="w-100 banner-img" src="/public/assets/images/contest/NUT_PPWeb_Contest_DT.png" sizes="(min-width: 400px) 80vw, 100vw" srcSet="/public/assets/images/contest/NUT_PPWeb_Contest_Mob.png 375w, /public/assets/images/contest/NUT_PPWeb_Contest_DT.png 1500w" alt="Contest" /> */}
              <div className="container">
                <div className="d-sm-flex card-img-overlay">
                  <div className="col-sm-8 white contest-hero-banner">
                    <h1 className="">Feed your inner winner</h1>
                    <h4 className="white text-left">
                      <strong>Every purchase fuels your chance to bag big prizes.</strong>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Container className="rewards-list-container reward-product-card my-5">
          <div className="rewards-list-body">
            <Contests pagesize={8} />
          </div>
          {/* <WordSearchGame /> */}
        </Container>
      </div>
      {/* products card */}
    </>
  );
};

export default Contest;
