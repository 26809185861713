import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import ProfileForm from './ProfileForm';
import { useSelector } from 'react-redux';
const Profile = () => {
  const user = useSelector(({ user }) => user);
  return (
    <>
      <div className="user-pages">
        <div className="w-100 mb-5 position-relative" id="rd">
          <div className="">
            <div className="reward-banner">
              <div className="top-banner">
                <img className="banner-img d-none d-sm-block" src="/public/assets/images/homepage/desktop_banner_without_button.png" />
                <img className="w-100 banner-img d-block d-sm-none" src="/public/assets/images/homepage/mobile_banner_without_button.png" />
                <div className="container">
                  <div className="d-sm-flex card-img-overlay">
                    <div className="col-sm-6 earnpoint-hero-banner white">
                      <h1 className="header-1">Plaid Perks™ is being discontinued.</h1>
                      <h4>
                        <strong>
                          We are no longer accepting new receipt submissions. You may use your available points to redeem rewards at PlaidPerks.com, while supplies last, until September 5, 2023. Click{' '}
                          <Link to="/faq" className="white-link">
                            here
                          </Link>{' '}
                          to learn more.
                        </strong>
                        {/* <strong>
                          Members have until August 21, 2023, to submit valid receipts or points codes to earn points and until September 5, 2023 to redeem their points for rewards at PlaidPerks.com. Click{' '}
                          <Link to="/faq" className="white-link">
                            here
                          </Link>{' '}
                          to learn more.
                        </strong> */}
                      </h4>
                      <p>Members will receive an email with instructions for how to redeem points after September 5, 2023.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" page-section post-login">
          <div className="container">
            <form role="form">
              <div className="">
                <div className="d-flex justify-content-center">
                  <h2>
                    {' '}
                    <Trans>Manage Your Account</Trans>{' '}
                  </h2>
                </div>
                <div className="d-flex justify-content-center">
                  <h6>
                    Current Point Balance: <strong>{user.pointBalance}</strong>
                  </h6>
                </div>
                <div className="d-flex justify-content-between mt-3 mb-5 mya-tabs">
                  <Link to="/points-history" className="btn btn-light">
                    Points History
                  </Link>
                  <Link to="/my-rewards" className="btn btn-light">
                    My Rewards
                  </Link>
                  <Link to="/profile" className="btn btn-primary">
                    Profile
                  </Link>
                </div>
              </div>
              <ProfileForm />
            </form>
          </div>
        </div>
      </div>
      <div className="w-100">
        <div className="faq-wrapper">
          <div className="faq-bot-banner">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-md-5 d-flex align-items-center">
                  <div className="">
                    <h2 className="text-left">Have questions about your account?</h2>
                    <a href="/faq" className="btn btn-primary">
                      Go to FAQs
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
