import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import IsLoadingHOC from './IsLoadingHOC';
import { helpQueryAction } from '../redux/actions';
import { toast } from 'react-toastify';
import Captcha from './CaptchaClick';
import { useSelector } from 'react-redux';
import SupportFAQ from './SupportFAQ';
import { TextField, InputAdornment, InputLabel } from '@material-ui/core';
const Support = props => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    name: '',
    email: '',
    description: '',
    captchaVal: '',
  });
  const config = useSelector(state => state.app.config);
  let [captchaValue, setCaptchaValue] = useState(false);
  let [resetCaptchaValue, setResetCaptchaValue] = useState(0);
  React.useEffect(() => {
    if ((config?.captchaConfig?.recaptchaVersion || '').toLowerCase() === 'v3') {
      const interval = setInterval(() => {
        setResetCaptchaValue(resetCaptchaValue + 1);
      }, 45000);
      return () => clearInterval(interval);
    }
  }, [resetCaptchaValue, config]);

  const [error, setError] = useState({ name: '', email: '', description: '', captchaVal: '' });
  // eslint-disable-next-line
  const validationError = { description: '', name: /^[A-Za-z]+$/, email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, lastName: /^[A-Za-z]+$/, captchaVal: '' };
  let [captchaEnable, setCaptchaEnable] = useState(false);
  useEffect(() => {
    let errorArray = JSON.parse(JSON.stringify(error));
    if (captchaValue) {
      errorArray['captchaVal'] = '';
      let reqd = JSON.parse(JSON.stringify(data));
      reqd.captchaVal = captchaValue;
      setData(reqd);
    }
    setError(errorArray);
    // eslint-disable-next-line
  }, [captchaValue]);
  useEffect(() => {
    if (config.captchaConfig) {
      if (config.captchaConfig.supportCaptcha) {
        setCaptchaEnable(true);
      }
    }
  }, [config]);
  const handleChange = e => {
    let key = e.target.name;
    let val = e.target.value;
    let reqd = JSON.parse(JSON.stringify(data));
    let errd = JSON.parse(JSON.stringify(error));
    if (val.match(validationError[key]) || val === '' || key === 'email') {
      reqd[key] = val;
    }
    if (key === 'name') {
      if (!reqd[key] || reqd[key].trim().length < 1) {
        errd[key] = `Name is required.`;
      } else {
        errd[key] = ``;
      }
    } else if (key === 'email') {
      if (reqd[key] === '') {
        errd[key] = `Email is required.`;
      } else if (!reqd[key].match(validationError[key])) {
        errd[key] = `Please enter a valid email address.`;
      } else {
        errd[key] = ``;
      }
    } else if (key === 'description') {
      if (!reqd[key] || val.trim().length < 1) {
        errd[key] = `Message is required.`;
      } else {
        errd[key] = ``;
      }
    }
    setError(errd);
    setData(reqd);
  };
  const onSubmit = () => {
    let reqd = JSON.parse(JSON.stringify(data));
    let errd = JSON.parse(JSON.stringify(error));
    let isError = false;
    for (let key in reqd) {
      let val = reqd[key].trim();
      if (key === 'name') {
        if (!val || val.length < 1) {
          isError = true;
          errd[key] = `Name is required.`;
        } else {
          errd[key] = ``;
        }
      } else if (key === 'email') {
        if (val === '') {
          errd[key] = `Email is required.`;
          isError = true;
        } else if (!val.match(validationError[key])) {
          errd[key] = `Please enter a valid email address.`;
          isError = true;
        } else {
          errd[key] = ``;
        }
      } else if (key === 'description') {
        if (!val || val.length < 1) {
          errd[key] = `Message is required.`;
          isError = true;
        } else {
          errd[key] = ``;
        }
      } else if (key === 'captchaVal' && captchaEnable) {
        if (!val || val.length < 2) {
          errd[key] = `Please check Captcha.`;
          isError = true;
        } else {
          errd[key] = ``;
        }
      }
    }
    setError(errd);
    if (!isError) {
      props.setLoading(true);
      let requestData = { email: reqd.email, name: reqd.name, message: reqd.description };
      if (captchaEnable) {
        requestData['captchaVal'] = reqd.captchaVal;
      }
      helpQueryAction(requestData)
        .then(response => {
          for (let key in reqd) {
            reqd[key] = '';
          }
          setResetCaptchaValue(resetCaptchaValue + 1);
          setData(reqd);
          toast.success(`Thank you for contacting us. Your message has been successfully sent. We will contact you very soon!`);
          props.setLoading(false);
        })
        .catch(error => {
          toast.error(t('Something went wrong'));
          props.setLoading(false);
          setResetCaptchaValue(resetCaptchaValue + 1);
        });
    }
  };
  return (
    <Container fluid className="section-content">
      <h1 className="support-main-heading">Hello, we are here to help</h1>
      <p class="support-description">Please note that we will respond within 3 business days.</p>
      <Row md={2} xs={1}>
        <Col>
          <Form role="form">
            <Row md={1} xs={1} sm={1}>
              {/*[START:FIRSTNAME]*/}
              <Col className="pt-2">
                <TextField
                  label=""
                  fullWidth
                  id="filled-start-adornment"
                  data-test="name"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">NAME</InputAdornment>,
                  }}
                  variant="filled"
                  value={data.name}
                  onChange={handleChange}
                  aria-required="true"
                  aria-autocomplete="Name"
                  error={error?.name?.length > 0 ? true : false}
                  className={error?.name?.length > 0 ? '' : 'textfield-bottom-border'}
                  name="name"
                  helperText={error.name}
                />
              </Col>
              <Col className="pt-2">
                <TextField
                  label=""
                  fullWidth
                  id="filled-start-adornment"
                  data-test="email"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">EMAIL</InputAdornment>,
                  }}
                  variant="filled"
                  value={data.email}
                  onChange={handleChange}
                  aria-required="true"
                  aria-autocomplete="email"
                  error={error?.email?.length > 0 ? true : false}
                  name="email"
                  className={error?.email?.length > 0 ? '' : 'textfield-bottom-border'}
                  helperText={error.email}
                />
              </Col>
              <Col className="pt-5">
                <InputLabel shrink={false} className="input-label">
                  MESSAGE
                </InputLabel>
                <TextField variant="outlined" fullWidth id="outlined-multiline-static" data-test="description" value={data.description} onChange={handleChange} aria-required="true" aria-autocomplete="description" error={error?.description?.length > 0 ? true : false} name="description" helperText={error.description} multiline minRows="5" className={error?.description?.length > 0 ? '' : 'textarea-bottom-border'} />
              </Col>
            </Row>
            {captchaEnable ? (
              <Row md={2}>
                <Col className="mb-4">
                  <Captcha reset={resetCaptchaValue} parentCallback={setCaptchaValue} />
                  <span className="input-error" role="alert">
                    {error.captchaVal}
                  </span>
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row md={1} xs={1} sm={1}>
              <Col className="pt-3">
                <p>
                  By submitting you agree to our{' '}
                  <a href="https://www.nbcuniversal.com/privacy?intake=Universal_Pictures_Home_Entertainment" target="_blank" className="support-form-text-p">
                    <u>Privacy Policy</u>
                  </a>{' '}
                  and{' '}
                  <a href="/public/universal_all_access_rewards_program.pdf" target="_blank" className="support-form-text-p">
                    <u>Terms of Service</u>
                  </a>
                  .
                </p>
              </Col>
              <Col className="pt-3 mb-5">
                <Button variant="primary" className="btn btn-primary2 themeBackground" size="lg" id="register-submit-btn" data-test="register-submit" onClick={onSubmit}>
                  <Trans>Submit</Trans>
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col>
          <SupportFAQ />
        </Col>
      </Row>
    </Container>
  );
};

export default IsLoadingHOC(Support, 'Wait .....');
