import React, { useState, useEffect } from 'react';
import { Typography, Box, TextField, InputAdornment, MenuItem, Tooltip } from '@material-ui/core';
// eslint-disable-next-line
import { signup, getLockupAction, preRegistrationAction } from '../../redux/actions';
import { connect } from 'react-redux';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { isValid, differenceInYears } from 'date-fns';
import GooglePlaces from '../../components/GooglePlaces';
import Required from '../../components/Required';
import Captcha from '../../components/CaptchaClick';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import InputMask from 'react-input-mask';
import SocialLogin from '../../components/SocialLogin';
import { Row, Col, Modal } from 'react-bootstrap';

var validator = require('validator');

const formatedDateStr = value => {
  let d = value.split('/home');
  return d[0] + '/15/' + d[1];
};

const Register = ({ signup, setLoading, bonusPointInfo }) => {
  const { t } = useTranslation();
  const confige = useSelector(state => state.app.config);
  const history = useHistory();
  const [place, setPlace] = useState({});
  const query = new URLSearchParams(window.location.search);
  const referralid = query.get('refCode') ? query.get('refCode') : '';
  const source = query.get('src') ? query.get('src') : '';
  const promoCode = query.get('promoCode') ? query.get('promoCode') : '';
  let [captchaValue, setCaptchaValue] = useState(false);
  let [captchaEnable, setCaptchaEnable] = useState(false);
  let [animalList, setAnimalList] = useState([]);
  let [countryList, setCountryList] = useState([]);
  let [stateList, setStateList] = useState([]);
  let [captchaReset, setCaptchaReset] = useState(0);
  const [popupShow, setPopupShow] = useState(false);
  React.useEffect(() => {
    if ((confige?.captchaConfig?.recaptchaVersion || '').toLowerCase() === 'v3') {
      const interval = setInterval(() => {
        setCaptchaReset(captchaReset + 1);
      }, 45000);
      return () => clearInterval(interval);
    }
  }, [captchaReset, confige]);
  const errorMessages = {
    firstName: { reqMsg: 'First name is required.', invalidMsg: 'First Name must be 1-100 characters long and must  contain alphabets and special characters only.' },
    lastName: { reqMsg: 'Last name is required.', invalidMsg: 'Last Name must be 1-100 characters long and must  contain alphabets and special characters only.' },
    email: { reqMsg: 'Email is required.', invalidMsg: 'Please enter a valid email address.' },
    confirmEmail: { reqMsg: 'Confirm Email is required.', invalidMsg: 'Please enter a valid email address.' },
    password: { reqMsg: 'Password is required.', invalidMsg: 'Your password must be between 8 and 15 characters. It must contain at least one uppercase, one numeric digit and one special character.' },
    passwordConfirm: { reqMsg: 'Confirm Password is required.', invalidMsg: 'Please enter a valid confirm password.' },
    city: { reqMsg: 'Please enter your city.', invalidMsg: 'Please enter a valid city name.' },
    address: { reqMsg: 'Please enter your address.', invalidMsg: 'Please enter a valid address.' },
    suite: { reqMsg: 'Please enter your SUITE/APT. NO.', invalidMsg: 'Please enter a valid SUITE/APT. NO.' },
    pincode: { reqMsg: 'Code is required.', invalidMsg: 'Code is required.' },
    zip: { reqMsg: 'Zipcode is required.', invalidMsg: 'Zipcode should be numeric, no whitespace is allowed and should be of maximum 5 characters or 10 characters.' },
    gender: { reqMsg: 'Gender is required.', invalidMsg: 'Please select gender.' },
    birth: { reqMsg: 'Required.', invalidMsg: 'Age must be 18+' },
  };

  /*[START:INITIALIZE_DECLARATION]*/
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    username: '',
    birth: '',
    homePhone: '',
    cellPhone: '',
    address: '',
    address2: '',
    country: '',
    state: '',
    suite: '',
    city: '',
    zip: '',
    gender: '',
    receiveEmail: true,
    receiveSms: true,
    companyName: '',
    shippingInstructions: '',
    password: '',
    passwordConfirm: '',
    instagramHandle: '',
    facebookHandle: '',
    tiktokHandle: '',
    twitterHandle: '',
    socialSecurityNumber: '',
    latitude: 0,
    longitude: 0,
    secondaryEmail: '',
    pincode: '',
    additionalInfo: { activity_tags_list: '', visitorId: '', requestId: '', locationType: '', occupation: '', purchaseType: '', pincode: '' },
  });
  /*[END:INITIALIZE_DECLARATION]*/
  const [validation, setValidation] = useState({});
  const [error, setError] = useState({
    firstName: '',
    email: '',
    confirmEmail: '',
    homePhone: '',
    cellPhone: '',
    lastName: '',
    username: '',
    address: '',
    address2: '',
    country: '',
    state: '',
    suite: '',
    zip: '',
    city: '',
    shippingInstructions: '',
    receiveEmail: '',
    receiveSms: '',
    gender: '',
    passwordConfirm: '',
    password: '',
    instagramHandle: '',
    facebookHandle: '',
    tiktokHandle: '',
    twitterHandle: '',
    socialSecurityNumber: '',
    secondaryEmail: '',
    birth: '',
    pincode: '',
    additionalInfo: { activity_tags_list: '', terms: '', purchaseType: '' },
  });
  useEffect(() => {
    loadAnimalList();
  }, []);
  const loadAnimalList = () => {
    let requestData = { lookups: ['ACTIVITY_TAGS_LIST', 'STATE_LIST', 'COUNTRY_LIST'] };
    setLoading(true);
    getLockupAction(requestData)
      .then(response => {
        if (response.httpCode === 200 && response.data) {
          if (response.data.content) {
            let animalListInd = response.data.content.findIndex(v => v.name === 'ACTIVITY_TAGS_LIST');
            let comList = [];
            if (animalListInd >= 0) {
              comList = response.data.content[animalListInd].data;
              let dt = response.data.content[animalListInd].data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
              setAnimalList(dt);
            }
            let stListInd = response.data.content.findIndex(v => v.name === 'STATE_LIST');
            if (stListInd >= 0) {
              setStateList(response.data.content[stListInd].data);
            }
            let cnListInd = response.data.content.findIndex(v => v.name === 'COUNTRY_LIST');
            if (cnListInd >= 0) {
              setCountryList(response.data.content[cnListInd].data);
            }
          }
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
      });
  };
  useEffect(() => {
    let items = {};
    let oldState = { ...data, password: '', passwordConfirm: '' };
    setData(oldState);
    if (confige.uiConfig) {
      confige.uiConfig.userFields.forEach(element => {
        if (element.name === 'email') {
          items['confirmEmail'] = element.validation;
          // items['secondaryEmail'] = element.validation;
        }
        if (['birth'].indexOf(element.name) >= 0) {
          element.validation.mandatory = true;
        }
        items[element.name] = element.validation;
      });
      items.password = confige.uiConfig.passwordRule;
      items.passwordConfirm = confige.uiConfig.passwordRule;
      if (confige.fingerprinting) {
        if (confige.fingerprinting.enable) {
          const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
          // Get the visitor identifier when you need it.
          fpPromise
            .then(fp => fp.get())
            .then(result => {
              let fdata = JSON.parse(JSON.stringify(data));
              fdata.additionalInfo.visitorId = result.visitorId;
              fdata.additionalInfo.requestId = result.requestId;
              setData(fdata);
            });
        }
      }
    }
    if (confige.captchaConfig) {
      if (confige.captchaConfig.registrationCaptcha) {
        setCaptchaEnable(true);
      }
    }
    setValidation(items);
    // eslint-disable-next-line
  }, [confige, confige.uiConfig]);

  const handleChange = e => {
    const { name, value } = e.target;
    let errorMessage = '';
    let validationArray = validation[name];
    let check = false;
    for (let key in validationArray) {
      if (key === 'minLength') {
        if (value.length < validationArray[key] && value.length !== 0) {
          errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t('Should not be less then', { count: validationArray[key] });
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'maxLength') {
        if (value.length > validationArray[key]) {
          errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t('Should not be greater then', { count: validationArray[key] });
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'mandatory' && validationArray[key]) {
        if (!value.length) {
          errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : t('Should not be empty');
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'hasNumeric' && validationArray[key] && value.search(/[0-9]/) < 0) {
        errorMessage = `${t('Should be contain number')}.`;
        if (name === 'password') {
          errorMessage = errorMessages.password.invalidMsg;
        }
        setError({ ...error, [name]: errorMessage });
        check = true;
      }
      if (key === 'hasAlpha' && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
        errorMessage = `${t('Should be contain alphabets')}.`;
        if (name === 'password') {
          errorMessage = errorMessages.password.invalidMsg;
        }
        setError({ ...error, [name]: errorMessage });
        check = true;
      }
      if (key === 'email' && validationArray[key] && value) {
        if (!validator.isEmail(value)) {
          errorMessage = `${t('Please enter a valid email')}.`;
          if (name === 'password') {
            errorMessage = errorMessages.password.invalidMsg;
          }
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'upperCaseReq' && validationArray[key]) {
        if (value.search(/[A-Z]/) < 0 && value.length !== 0) {
          errorMessage = `${t('Should have at least one Upper Case latter')}.`;
          if (name === 'password') {
            errorMessage = errorMessages.password.invalidMsg;
          }
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'lowerCaseReq' && validationArray[key]) {
        if (value.search(/[a-z]/) < 0 && value.length !== 0) {
          errorMessage = `${t('Should have at least one Lower Case latter')}.`;
          if (name === 'password') {
            errorMessage = errorMessages.password.invalidMsg;
          }
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'specialCharReq' && validationArray[key]) {
        // eslint-disable-next-line
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (!format.test(value) && value.length !== 0) {
          errorMessage = `${t('Should have at least one special letter')}.`;
          if (name === 'password') {
            errorMessage = errorMessages.password.invalidMsg;
          }
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }

      if (name === 'passwordConfirm') {
        if (data.password !== value) {
          setError({ ...error, passwordConfirm: errorMessages.passwordConfirm.invalidMsg });
        }
      }
    }

    if ((name === 'firstName' || name === 'lastName') && value.length !== 0) {
      const nameFormat = /^[a-zA-z]+([\s|'|’|-][a-zA-Z]+)*$/;
      const nameString = value.trim();
      if (!nameFormat.test(nameString)) {
        errorMessage = `Should not have any specific character (except ' or - between 2 character) and more then single space.`;
        setError({ ...error, [name]: errorMessage });
        check = true;
      }
    }
    // if (name === 'birth' && value.length !== 0) {
    //   const dob = formatedDateStr(value);
    //   if (!isValid(new Date(dob))) {
    //     errorMessage = errorMessages.birth.invalidMsg;
    //     setError({ ...error, [name]: errorMessage });
    //     check = true;
    //   }
    // }
    if (!check) {
      setError({ ...error, [name]: '' });
    }
    let newAdditionalInfo = { ...data.additionalInfo };
    if (name === 'purchaseType') {
      newAdditionalInfo[name] = e.target.value;
    }
    let newData = { ...data };
    setData({ ...newData, [name]: value, additionalInfo: { ...newAdditionalInfo } });
  };

  const onSubmit = async () => {
    let errorArray = {};
    for (let name in data) {
      let value = data[name];
      let validationArray = validation[name];
      let errorMessage = '';
      if ((name === 'firstName' || name === 'lastName') && value.length !== 0) {
        const nameFormat = /^[a-zA-z]+([\s|'|’|-][a-zA-Z]+)*$/;
        const nameString = value.trim();
        if (!nameFormat.test(nameString)) {
          errorMessage = `Should not have any specific character (except ' or - between 2 character) and more then single space.`;
        }
      }

      if (name === 'birth' && value.length !== 0) {
        let dobMaxAllowDate = new Date(`${new Date().getFullYear() - 18}-${new Date().getMonth() + 1}-${new Date().getDay()}`);
        if (new Date(value) > dobMaxAllowDate) {
          errorMessage = errorMessages.birth.invalidMsg;
        }
      }
      for (let key in validationArray) {
        if (key === 'email' && validationArray[key] && !validator.isEmail(value) && value) {
          errorMessage = `${t('Please enter a valid email')}.`;
        } else if (key === 'minLength' && value.length < validationArray[key] && value.length !== 0) {
          errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t('Should not be less then', { count: validationArray[key] });
        } else if (key === 'maxLength' && value.length > validationArray[key]) {
          errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t('Should not be greater thenn', { count: validationArray[key] });
        } else if (key === 'mandatory' && validationArray[key] && !value.length) {
          errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : 'Should not be empty.';
        } else if (key === 'upperCaseReq' && value.search(/[A-Z]/) < 0 && value.length !== 0) {
          errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : 'Should have at least one Upper Case latter.';
        } else if (key === 'lowerCaseReq' && value.search(/[a-z]/) < 0 && value.length !== 0) {
          errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : 'Should have at least one Lower Case latter.';
        } else if (key === 'hasNumeric' && validationArray[key] && value.search(/[0-9]/) < 0) {
          errorMessage = `${t('Should be contain number')}.`;
        } else if (key === 'hasAlpha' && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
          errorMessage = `${t('Should be contain alphabets')}.`;
        } else if (key === 'specialCharReq' && value.length !== 0) {
          // eslint-disable-next-line
          var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
          if (!format.test(value)) {
            errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : 'Should have at least one special letter.';
          }
        }
      }
      if (name === 'additionalInfo') {
        let additionalInfoError = { activity_tags_list: '', purchaseType: '' };
        // if (data['additionalInfo']['activity_tags_list'].length === 0) {
        //   additionalInfoError.activity_tags_list = `${t('Please select animals.')}`;
        // }
        if (!data['additionalInfo'].purchaseType) {
          additionalInfoError.purchaseType = errorMessages.purchaseType.reqMsg;
        }
        errorArray[name] = { ...additionalInfoError };
      } else if (name === 'pincode') {
        if (data.pincode.length < 1) {
          errorArray[name] = errorMessages.pincode.reqMsg;
        }
      } else {
        errorArray[name] = errorMessage;
      }
      if (name === 'password' || name === 'passwordConfirm') {
        if (data['password'] !== data['passwordConfirm']) {
          errorMessage = `${t('Password and confirm password should be same')}.`;
        }
        errorArray['passwordConfirm'] = errorMessage;
      }
      if (name === 'confirmEmail') {
        if (data['email'] !== data['confirmEmail']) {
          errorMessage = `${t('Email and confirm email should be same.')}`;
        }
        errorArray['confirmEmail'] = errorMessage;
      }
    }
    setError(errorArray);
    let check = false;
    for (let key in errorArray) {
      if (errorArray[key] && key !== 'additionalInfo') {
        check = true;
      }
    }
    if (errorArray['additionalInfo']['purchaseType']) {
      check = true;
    }
    if (data.secondaryEmail !== '' && data.secondaryEmail) {
      if (!validator.isEmail(data.secondaryEmail)) {
        let errorMessage = `${t('Please enter a valid email')}.`;
        setError({ ...error, secondaryEmail: errorMessage });
        check = true;
      }
      if (data.secondaryEmail === data.email) {
        let errorMessage = `${t('Secondary email should not be the same as Primary email')}.`;
        setError({ ...error, secondaryEmail: errorMessage });
        check = true;
      }
    }
    if (!captchaValue && captchaEnable) {
      toast.error(`${t('Error')}! ${t('Please select captcha')}.`);
      return false;
    }
    const newData = { ...data };
    if (captchaEnable) {
      newData['captchaVal'] = captchaValue;
    }
    delete newData.passwordConfirm;
    if (errorArray.country && !errorArray.address) {
      toast.error(`${t('Country is not selected')}.`);
    } else if (errorArray.state && !errorArray.address) {
      toast.error(`${t('State is not selected')}.`);
    } else if (errorArray.zip && !errorArray.address) {
      toast.error(`${t('Zipcode is not selected')}.`);
    } else if (errorArray.city && !errorArray.address) {
      toast.error(`${t('City is not selected')}.`);
    }
    if (!check) {
      // if (newData.birth) {
      //   let datevalue = new Date(formatedDateStr(newData.birth));
      //   const dd = String(datevalue.getDate()).padStart(2, '0');
      //   const mm = String(datevalue.getMonth() + 1).padStart(2, '0');
      //   const yyyy = datevalue.getFullYear();
      //   const formatedDate = yyyy + '-' + mm + '-' + dd;
      //   newData.birth = formatedDate;
      // }
      if (!newData.hasOwnProperty('additionalInfo')) {
        newData['additionalInfo'] = {};
      }
      if (promoCode) {
        newData['additionalInfo']['promoCode'] = promoCode;
      }
      if (source) {
        newData['additionalInfo']['source'] = source;
      }
      setLoading(true);
      try {
        newData.additionalInfo.pincode = newData.pincode;
        let signupCall = true;
        if (confige.usePreRegistration) {
          signupCall = false;
          let preRegistrationResp = await preRegistrationAction(newData);
          if (preRegistrationResp.data) {
            if (preRegistrationResp.data.valid) {
              signupCall = true;
              newData.additionalInfo.token = preRegistrationResp.data.token;
            } else {
              setLoading(false);
              toast.error(preRegistrationResp.data.errorReason);
            }
          } else {
            setLoading(false);
            toast.error(`${t('Something went wrong')}.`);
          }
        }
        if (signupCall) {
          let signupResp = await signup(newData, referralid, 1);
          if (captchaEnable) {
            setCaptchaReset(captchaReset + 1);
          }

          toast.success(signupResp);
          window.scrollTo(0, 0);
          history.push('/login');
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (captchaEnable) {
          setCaptchaReset(captchaReset + 1);
        }
        if (typeof error === 'object') {
          error.forEach(item => {
            toast.error(`Something went wrong! ${item.message}`);
          });
        } else {
          toast.error(`${t('Something went wrong')}.`);
        }
      }
    }
  };

  const handleDate = value => {
    setData({ ...data, birth: value });
  };

  const handleAnimalList = event => {
    let values = data.additionalInfo.activity_tags_list.length > 0 ? data.additionalInfo.activity_tags_list.split(',') : [];
    values = event.target.checked ? [...values, event.target.value] : values.filter(item => item !== event.target.value);
    if (values.length === 0) {
      setError({ ...error, additionalInfo: { ...error.additionalInfo, activity_tags_list: `${t('Please select animals')}.` } });
    } else {
      setError({ ...error, additionalInfo: { ...error.additionalInfo, activity_tags_list: '' } });
    }
    setData({ ...data, additionalInfo: { ...data.additionalInfo, activity_tags_list: values.join(',') } });
  };
  useEffect(() => {
    if (place.hasOwnProperty('address')) {
      let err = { ...error };
      let curStateData = Object.assign({}, data);
      if (curStateData.hasOwnProperty('address')) {
        curStateData.address = place.address;
        err.address = '';
      }
      if (curStateData.hasOwnProperty('country')) {
        curStateData.country = place.country;
      }
      if (curStateData.hasOwnProperty('state')) {
        curStateData.state = place.state;
        err.state = '';
      }
      if (curStateData.hasOwnProperty('city')) {
        curStateData.city = place.city;
        err.city = '';
      }
      // if (curStateData.hasOwnProperty('suite')) {
      //   curStateData.suite = place.street;
      //   err.suite = '';
      // }
      if (curStateData.hasOwnProperty('zip')) {
        curStateData.zip = place.zip;
        err.zip = '';
      }
      if (curStateData.hasOwnProperty('latitude')) {
        curStateData.latitude = place.latitude;
      }
      if (curStateData.hasOwnProperty('longitude')) {
        curStateData.longitude = place.longitude;
      }
      setData(curStateData);
      setError(err);
    }
    // eslint-disable-next-line
  }, [place]);
  const googleSelectedAddressHandler = place => {
    setPlace(place);
  };
  const formatChars = {
    '1': '[12]',
    '0': '[0123456789]',
  };
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = e => {
    let err = { ...error };
    let isError = false;
    if (activeStep === 0) {
      if (!data.firstName) {
        err.firstName = errorMessages.firstName.reqMsg;
        isError = true;
      } else {
        err.firstName = '';
      }
      if (!data.lastName) {
        err.lastName = errorMessages.lastName.reqMsg;
        isError = true;
      } else {
        err.lastName = '';
      }
      if (!data.email) {
        err.email = errorMessages.email.reqMsg;
        isError = true;
      } else {
        err.email = '';
      }
      if (!data.confirmEmail) {
        err.confirmEmail = errorMessages.confirmEmail.reqMsg;
        isError = true;
      } else {
        err.confirmEmail = '';
      }
      if (!data.password) {
        err.password = errorMessages.password.reqMsg;
        isError = true;
      } else {
        err.password = '';
      }
      if (!data.passwordConfirm) {
        err.passwordConfirm = errorMessages.passwordConfirm.reqMsg;
        isError = true;
      } else {
        err.passwordConfirm = '';
      }
      if (!isError) {
        setActiveStep(1);
      }
    }
    if (activeStep === 1) {
      let dobMaxAllowDate = new Date(`${new Date().getFullYear() - 18}-${new Date().getMonth() + 1}-${new Date().getDay()}`);
      if (!data.birth) {
        err.birth = errorMessages.birth.reqMsg;
        isError = true;
      } else if (new Date(data.birth) > dobMaxAllowDate) {
        err.birth = errorMessages.birth.invalidMsg;
        isError = true;
      } else {
        err.birth = '';
      }
      if (!data.gender) {
        err.gender = errorMessages.gender.reqMsg;
        isError = true;
      } else {
        err.gender = '';
      }
      if (!data.zip) {
        err.zip = errorMessages.zip.reqMsg;
        isError = true;
      } else {
        err.zip = '';
      }
      if (!data.additionalInfo.purchaseType) {
        err.additionalInfo.purchaseType = 'Please select one';
        isError = true;
      } else {
        err.additionalInfo.purchaseType = '';
      }
      if (!isError) {
        if (data.additionalInfo.purchaseType === 'disc-purchase') {
          setActiveStep(2);
        }
      }
    }
    setError(err);
    //setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className="page balloons-background">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 col-sm-12 col-xs-12">
            <div className="mat-card py-5 px-5">
              <h1 className="main-heading" style={{ margin: '10px 0px 20px 0px' }}>
                Register Now
              </h1>
              <p className="text-center">The first step is easy - register for free to become part of our All-Access program to use points towards amazing movie rewards!</p>
              <img src={`/public/assets/images/steper${activeStep + 1}.png`} alt={`steper${activeStep + 1}.png`} width={'100%'} />
              <React.Fragment>
                {activeStep === 0 && (
                  <div className="row">
                    {/*[START:FIRSTNAME]*/}
                    <div className="form-group col-md-6 theme-signup-firstname">
                      <TextField
                        label=""
                        fullWidth
                        id="filled-start-adornment"
                        data-test="firstName"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="input-label" position="start">
                              <Trans>lang_key_fname</Trans>
                            </InputAdornment>
                          ),
                        }}
                        variant="filled"
                        value={data.firstName}
                        onChange={handleChange}
                        aria-required="true"
                        aria-autocomplete="firstName"
                        error={error?.firstName?.length > 0 ? true : false}
                        className={error?.firstName?.length > 0 ? 'yelloy-text' : 'yelloy-text textfield-bottom-border'}
                        name="firstName"
                        helperText={error.firstName}
                      />
                    </div>
                    {/*[END:FIRSTNAME]*/}

                    {/*[START:LASTNAME]*/}
                    <div className="form-group col-md-6 theme-signup-lastname">
                      <TextField
                        label=""
                        fullWidth
                        id="filled-start-adornment"
                        data-test="lastName"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="input-label" position="start">
                              <Trans>lang_key_lname</Trans>
                            </InputAdornment>
                          ),
                        }}
                        variant="filled"
                        value={data.lastName}
                        onChange={handleChange}
                        aria-required="true"
                        aria-autocomplete="lastName"
                        error={error?.lastName?.length > 0 ? true : false}
                        className={error?.lastName?.length > 0 ? 'yelloy-text' : 'yelloy-text textfield-bottom-border'}
                        name="lastName"
                        helperText={error.lastName}
                      />
                    </div>
                    {/*[END:LASTNAME]*/}
                    {/*[START:EMAIL]*/}
                    <div className="form-group col-md-6 theme-signup-email">
                      <TextField
                        label=""
                        fullWidth
                        id="filled-start-adornment"
                        data-test="email"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="input-label" position="start">
                              <Trans>lang_key_email</Trans>
                            </InputAdornment>
                          ),
                        }}
                        variant="filled"
                        value={data.email}
                        onChange={handleChange}
                        aria-required="true"
                        aria-autocomplete="email"
                        error={error?.email?.length > 0 ? true : false}
                        className={error?.email?.length > 0 ? 'yelloy-text' : 'yelloy-text textfield-bottom-border'}
                        name="email"
                        helperText={error.email}
                      />
                    </div>
                    {/*[START:CONF EMAIL]*/}
                    <div className="form-group col-md-6 theme-signup-conf-email">
                      <TextField
                        label=""
                        fullWidth
                        id="filled-start-adornment"
                        data-test="confirmEmail"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="input-label" position="start">
                              <Trans>CONFIRM EMAIL</Trans>
                            </InputAdornment>
                          ),
                        }}
                        variant="filled"
                        value={data.confirmEmail}
                        onChange={handleChange}
                        aria-required="true"
                        aria-autocomplete="confirmEmail"
                        error={error?.confirmEmail?.length > 0 ? true : false}
                        className={error?.confirmEmail?.length > 0 ? 'yelloy-text' : 'yelloy-text textfield-bottom-border'}
                        name="confirmEmail"
                        helperText={error.confirmEmail}
                      />
                    </div>
                    {/*[END:CONF EMAIL]*/}
                    {/*[START:PASSWORD]*/}
                    <div className="form-group col-md-6 theme-signup-password">
                      <TextField
                        label=""
                        fullWidth
                        id="filled-start-adornment"
                        type={'password'}
                        data-test="password"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="input-label" position="start">
                              <Trans>Password</Trans>
                            </InputAdornment>
                          ),
                        }}
                        variant="filled"
                        value={data.password}
                        onChange={handleChange}
                        aria-required="true"
                        aria-autocomplete="password"
                        error={error?.password?.length > 0 ? true : false}
                        className={error?.password?.length > 0 ? 'yelloy-text' : 'yelloy-text textfield-bottom-border'}
                        name="password"
                        helperText={error.password}
                      />
                    </div>
                    <div className="form-group col-md-6 theme-signup-password">
                      <TextField
                        label=""
                        fullWidth
                        id="filled-start-adornment"
                        type={'password'}
                        data-test="passwordConfirm"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="input-label" position="start">
                              <Trans>Confirm Password</Trans>
                            </InputAdornment>
                          ),
                        }}
                        variant="filled"
                        value={data.passwordConfirm}
                        onChange={handleChange}
                        aria-required="true"
                        aria-autocomplete="passwordConfirm"
                        error={error?.passwordConfirm?.length > 0 ? true : false}
                        className={error?.passwordConfirm?.length > 0 ? 'yelloy-text' : 'yelloy-text textfield-bottom-border'}
                        name="passwordConfirm"
                        helperText={error.passwordConfirm}
                      />
                    </div>
                    {/*[END:PASSWORD]*/}
                  </div>
                )}
                {activeStep === 1 && (
                  <div className="row">
                    {/*[START:birth]*/}
                    <div className="form-group col-md-6 theme-signup-birth">
                      <TextField
                        label=""
                        fullWidth
                        type={'date'}
                        max="9999-12-31"
                        pattern="\d{4}-\d{2}-\d{2}"
                        placeholder="DD/MM/YYYY"
                        data-test="birth"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="input-label" position="start">
                              <Trans>DATE OF BIRTH</Trans>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <Tooltip style={{ position: 'absolute', right: '0' }} className="pt-4 tooltrip-inner" title="We are collecting this information so we can wish you a happy birthday!">
                              <span className="material-icons">help</span>
                            </Tooltip>
                          ),
                        }}
                        variant="filled"
                        value={data.birth}
                        onChange={handleChange}
                        aria-required="true"
                        aria-autocomplete="birth"
                        error={error?.birth?.length > 0 ? true : false}
                        className={error?.birth?.length > 0 ? 'yelloy-text' : 'yelloy-text textfield-bottom-border'}
                        name="birth"
                        helperText={error.birth}
                      />
                    </div>
                    {/*[END:birth]*/}

                    {/*[START:gender]*/}
                    <div className="form-group col-md-6 theme-signup-gender">
                      <TextField
                        label=""
                        fullWidth
                        id="filled-start-adornment"
                        data-test="gender"
                        select
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="input-label" position="start">
                              <Trans>GENDER</Trans>
                            </InputAdornment>
                          ),
                        }}
                        variant="filled"
                        value={data.gender}
                        onChange={handleChange}
                        aria-required="true"
                        aria-autocomplete="gender"
                        error={error?.gender?.length > 0 ? true : false}
                        className={error?.gender?.length > 0 ? 'yelloy-text' : 'yelloy-text textfield-bottom-border'}
                        name="gender"
                        helperText={error.gender}>
                        <MenuItem value="m" key="m">
                          {t('Male')}
                        </MenuItem>
                        <MenuItem value="f" key="f">
                          {t('Female')}
                        </MenuItem>
                        <MenuItem value="na" key="na">
                          {t('Prefer not to disclose')}
                        </MenuItem>
                      </TextField>
                    </div>
                    {/*[END:gender]*/}
                    {/*[START:zip]*/}
                    <div className="form-group col-md-6 theme-signup-zip">
                      <TextField
                        label=""
                        fullWidth
                        id="filled-start-adornment"
                        data-test="zip"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="input-label" position="start">
                              <Trans>ZIPCODE</Trans>
                            </InputAdornment>
                          ),
                        }}
                        variant="filled"
                        value={data.zip}
                        onChange={handleChange}
                        aria-required="true"
                        aria-autocomplete="zip"
                        error={error?.zip?.length > 0 ? true : false}
                        className={error?.zip?.length > 0 ? 'yelloy-text' : 'yelloy-text textfield-bottom-border'}
                        name="zip"
                        helperText={error.zip}
                      />
                    </div>
                    {/*[START:CONF zip]*/}
                    <div className="form-group col-md-6 theme-signup-purchaseType">
                      <TextField
                        label=""
                        fullWidth
                        id="filled-start-adornment"
                        data-test="purchaseType"
                        select
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="input-label" style={{ fontSize: '0.8em' }} position="start">
                              <Trans>HOW DID YOU PURCHASE YOUR MOVIES?</Trans>
                            </InputAdornment>
                          ),
                        }}
                        variant="filled"
                        value={data.additionalInfo.purchaseType}
                        onChange={handleChange}
                        aria-required="true"
                        aria-autocomplete="purchaseType"
                        error={error.additionalInfo?.purchaseType?.length > 0 ? true : false}
                        className={error.additionalInfo?.purchaseType?.length > 0 ? 'mui-small-input' : 'mui-small-input textfield-bottom-border'}
                        name="purchaseType"
                        helperText={error.additionalInfo.purchaseType}>
                        <MenuItem value="digital-purchase" key="digital-purchase">
                          Digital Purchase
                        </MenuItem>
                        <MenuItem value="disc-purchase" key="disc-purchase">
                          Disc Purchase
                        </MenuItem>
                      </TextField>
                    </div>
                    {/*[END:CONF purchaseType]*/}
                  </div>
                )}
                {activeStep === 2 && (
                  <div className="row">
                    <div className="form-group col-md-12 theme-signup-birth">
                      <h3 className="sub-heading text-center welcome" style={{ textTransform: 'uppercase' }}>
                        <span style={{ fontWeight: '700' }}>Make sure your code isn't expired, and that it's not for a digital copy.</span> <br />
                        <a className="underline" style={{ cursor: 'pointer' }} onClick={() => setPopupShow(true)}>
                          Still unsure? Click here to see the inserts
                        </a>
                      </h3>
                    </div>
                    <div className="form-group col-md-6 theme-signup-zip">
                      <TextField
                        label=""
                        fullWidth
                        id="filled-start-adornment"
                        data-test="pincode"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="input-label" position="start">
                              <Trans>ENTER CODE</Trans>
                            </InputAdornment>
                          ),
                        }}
                        variant="filled"
                        value={data.pincode}
                        onChange={handleChange}
                        aria-required="true"
                        aria-autocomplete="pincode"
                        error={error?.pincode?.length > 0 ? true : false}
                        className={error?.pincode?.length > 0 ? 'yelloy-text' : 'yelloy-text textfield-bottom-border'}
                        name="pincode"
                        helperText={error.pincode}
                      />
                    </div>
                  </div>
                )}
                {/*[START:GOOGLECAPTCHA]*/}
                {captchaEnable ? (
                  <div className="form-group col-md-6">
                    <label htmlFor="g-recaptcha-response" className="col-sm-12 invisible" aria-hidden="true">
                      Google captcha
                    </label>
                    <div className="col-sm-12">
                      <Captcha reset={captchaReset} parentCallback={setCaptchaValue} />
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {activeStep < 2 && (
                  <div className="row">
                    <div className="col-md-12">
                      <button onClick={handleNext} data-test="sign-in-sign-in-button" type="button" className="login-button submit-btn-full-width btn btn-primary">
                        <Trans>Next</Trans>
                      </button>
                    </div>
                  </div>
                )}
                {activeStep == 0 && (
                  <>
                    <p className="pt-3" align="center">
                      or
                    </p>
                    <div className="my-3">
                      <SocialLogin />
                    </div>
                  </>
                )}
                {activeStep === 2 && (
                  <div className="row">
                    <div className="col-md-12 text-center pt-4">
                      <button onClick={onSubmit} data-test="sign-in-sign-in-button" type="button" style={{ float: 'none' }} className="login-button btn btn-primary">
                        <Trans>Submit Code</Trans>
                      </button>
                    </div>
                  </div>
                )}
              </React.Fragment>
              <Modal show={popupShow} onHide={() => setPopupShow(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                  <Row md={2} xs={2}>
                    <Col className="bg-enable">
                      <p className="dialog-color text-center dialog_text">
                        IF THIS IS YOUR INSERT,{' '}
                        <u>
                          <a className="dialog-color link_text" style={{ cursor: 'pointer' }} onClick={() => setPopupShow(false)}>
                            GO BACK
                          </a>
                        </u>{' '}
                        AND ENTER YOUR CODE!
                      </p>
                      <img alt="DVD Cover" width="100%" src="/public/assets/images/Rewards_insert_NEW_SEPT2022_crop.jpg" className="pt-4" />
                    </Col>
                    <Col>
                      <p className="dialog-color text-center f9saga">
                        IF THIS IS YOUR INSERT, PLEASE VISIT{' '}
                        <u>
                          <a href="/home" rel="noopener noreferrer" target="_blank" className="dialog-color link_text">
                            UNIVERSALREDEEM.COM
                          </a>
                        </u>{' '}
                        TO REDEEM YOUR DIGITAL COPY
                      </p>
                      <span className="material-symbols-outlined close-icon" onClick={() => setPopupShow(false)}>
                        close
                      </span>
                      <img alt="DVD Cover" width="100%" src="/public/assets/images/MA_insert_NEW_SEPT2022_crop.jpg" />
                    </Col>
                  </Row>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  const onRegistration = state?.app?.config?.bonusPointInfo?.onRegistration;
  return {
    bonusPointInfo: onRegistration ? onRegistration : 102,
  };
};
export default connect(mapStateToProps, { signup })(IsLoadingHOC(Register, 'Wait .....'));
