import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Row, Col, InputGroup, FormControl, Modal, Form } from 'react-bootstrap';
import IsLoadingHOC from '../IsLoadingHOC';
import { Link } from 'react-router-dom';
import { defaultOptions } from '../../config/index';
import { validateCodeAction, balanceGetAction, getLockupAction } from '../../redux/actions';
import Captcha from '../CaptchaClick';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

const SubmitCode = props => {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const config = useSelector(state => state.app.config);
  let [codeStatusList, setcodeStatusList] = useState([]);
  let [flaverList, setFlaverList] = useState([]);
  let [codelist, setCodeList] = useState([{ code: '', flavor: '' }]);
  let [error, setError] = useState([{ code: '', flavor: '' }]);
  let [showPopup, setShowPopup] = useState(false);
  let [captchaValue, setCaptchaValue] = useState(false);
  let [resetCaptcha, setResetCaptcha] = useState(0);
  let [captchaEnable, setCaptchaEnable] = useState(false);
  let [options, setOptions] = useState([[]]);
  React.useEffect(() => {
    if ((config?.captchaConfig?.recaptchaVersion || '').toLowerCase() === 'v3') {
      const interval = setInterval(() => {
        setResetCaptcha(resetCaptcha + 1);
      }, 45000);
      return () => clearInterval(interval);
    }
  }, [resetCaptcha, config]);

  //console.log('op', options);
  useEffect(() => {
    if (flaverList.length > 0) {
      let allopt = [];
      for (let i in codelist) {
        let len = codelist[i].code.length;
        let findInd = flaverList.findIndex(v => v.numberOfDigit === len);
        if (findInd >= 0) {
          let opt = [];
          for (let i in flaverList[findInd].flover) {
            opt.push({ value: flaverList[findInd].flover[i].name, label: flaverList[findInd].flover[i].id });
          }
          allopt.push(opt);
        } else {
          allopt.push([]);
        }
      }
      setOptions(allopt);
    }
  }, [codelist]);
  useEffect(() => {
    loadFlavorList();
  }, []);
  const loadFlavorList = () => {
    let requestData = { lookups: ['PRODUCT_FLOVER'] };
    props.setLoading(true);
    getLockupAction(requestData)
      .then(response => {
        if (response.httpCode === 200 && response.data) {
          if (response.data.content) {
            let comListInd = response.data.content.findIndex(v => v.name === 'PRODUCT_FLOVER');
            let comList = [];
            if (comListInd >= 0) {
              comList = response.data.content[comListInd].data;
              setFlaverList(comList);
            }
          }
        }
        props.setLoading(false);
      })
      .catch(e => {
        props.setLoading(false);
      });
  };
  const codeListUpdate = ind => {
    let codeTextFiled = [...codelist];
    let errorList = [...error];
    if (ind === codeTextFiled.length - 1) {
      codeTextFiled.push({ code: '', flavor: '' });
      errorList.push({ code: '', flavor: '' });
    } else {
      codeTextFiled.splice(ind, 1);
      errorList.splice(ind, 1);
    }
    setCodeList(codeTextFiled);
    setError(errorList);
  };
  useEffect(() => {
    if (config.captchaConfig) {
      if (config.captchaConfig.packageCodeCaptcha) {
        setCaptchaEnable(true);
      }
    }
    //eslint-disable-next-line
  }, [config]);

  const updateCodeList = (e, ind) => {
    let codeTextFiled = [...codelist];
    let errorList = [...error];
    // if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
    codeTextFiled[ind][e.target.name] = e.target.value;
    if (e.target.name === 'code') {
      if (!e.target.value) {
        errorList[ind].code = 'Product code is required.';
      } else {
        errorList[ind].code = '';
      }
      codeTextFiled[ind]['flavor'] = '';
    } else if (e.target.name === 'flavor') {
      if (!e.target.value) {
        errorList[ind].flavor = 'Please select.';
      } else {
        errorList[ind].flavor = '';
      }
    }
    setError(errorList);
    setCodeList(codeTextFiled);
  };
  const resetComponent = () => {
    let tempresetCaptcha = resetCaptcha + 1;
    setResetCaptcha(tempresetCaptcha);
    setCodeList([{ code: '', flavor: '' }]);
    setCaptchaValue(false);
    setcodeStatusList([]);
  };
  const formSubmit = () => {
    let codeTextFiled = [...codelist];
    let errorList = [...error];
    let isError = false;
    for (let ind in codeTextFiled) {
      if (!codeTextFiled[ind].code) {
        errorList[ind].code = 'Product code is required.';
        isError = true;
      } else {
        errorList[ind].code = '';
      }
      //   if (!codeTextFiled[ind].flavor) {
      //     errorList[ind].flavor = 'Please select.';
      //     isError = true;
      //   } else {
      //     errorList[ind].flavor = '';
      //   }
    }
    setError(errorList);
    if (isError) {
      return false;
    }
    if (!captchaValue && captchaEnable) {
      toast.error(`${t('Error')}! ${t('Please select captcha')}.`);
      return false;
    }
    let submitCodes = codeTextFiled.filter(v => v !== '');
    props.setLoading(true);
    let formData = { packageCodes: submitCodes };
    if (captchaEnable) {
      formData['captchaVal'] = captchaValue;
    }
    validateCodeAction(formData)
      .then(responseData => {
        if (responseData.httpCode >= 200 && responseData.httpCode <= 299) {
          resetComponent();
          dispatch(balanceGetAction());
          let data = responseData.data;
          for (let i in data) {
            if (data[i]['isValid'] && data[i]['points']) {
              data[i]['message'] = `Congratulations, you’ve earned ${data[i].points} points!`;
            } else {
              let statusCode = data[i]?.statusCode || 411;
              let msg = getErrorMessageByStatusCode(statusCode);
              data[i]['message'] = msg ? msg : data[i]['message'] ? data[i]['message'] : '';
              data[i]['error'] = true;
            }
          }
          let dataUnique = [];
          for (let i in data) {
            let ind = dataUnique.findIndex(v => v.code === data[i].code);
            if (ind < 0) {
              dataUnique.push(data[i]);
            }
          }
          setcodeStatusList(dataUnique);
        } else {
          if (responseData.errors && responseData.errors.length > 0 && formData.packageCodes && formData.packageCodes.length > 0) {
            let statusCode = 411;
            if (Array.isArray(responseData.errors)) {
              statusCode = responseData.errors[0]['statusCode'];
            }
            if (Array.isArray(responseData.data)) {
              statusCode = responseData.data[0]['statusCode'];
            }
            let msg = getErrorMessageByStatusCode(statusCode);
            msg = msg ? msg : 'Sorry! This code is not valid';
            setcodeStatusList([{ error: true, errors: responseData.errors, code: formData.packageCodes[0]['code'], message: msg }]);
          } else {
            setcodeStatusList([{ error: true, errors: responseData.message, code: formData.packageCodes[0]['code'], message: responseData.message }]);
          }
        }
        props.setLoading(false);
      })
      .catch(err => {
        toast.error(`Sorry, something went wrong. Please email support@receiptprocessor.com.`);
        props.setLoading(false);
        console.log('Response Error:', JSON.stringify(err));
        setResetCaptcha(resetCaptcha + 1);
      });
  };
  const getErrorMessageByStatusCode = errorCode => {
    if (errorCode === 411 || errorCode === 412) {
      return 'Sorry! This code is not valid';
    } else if (errorCode === 413 || errorCode === 414) {
      return 'You have reached your redemption limit for this code';
    } else {
      return null;
    }
  };
  return (
    <Container fluid className="page-section">
      <Row className="justify-content-center">
        <Col xs lg="12" className="text-center">
          <h2>Code Redemption</h2>
          <h3 className="my-3">Have a bonus code? Enter it here to claim your points!</h3>
        </Col>
      </Row>
      <Row className="justify-content-center my-3">{/* <Col xs lg="8" className="text-center">
          <label className="label-disc">
            <Trans>Make sure your code isn’t expired, and that it’s not for a digital copy</Trans>.
          </label>
        </Col> */}</Row>
      {/* <Row className="justify-content-center mb-5">
        <Col xs lg="6" className="text-center mr-1">
          <label className="label-link" onClick={e => setShowPopup(true)}>
            <Trans>Still unsure</Trans>?{' '}
            <Trans>
              <u>Click here</u> to see the inserts
            </Trans>
          </label>
        </Col>
      </Row> */}
      {codeStatusList.length === 0 ? (
        <>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              {codelist.map((v, i) => {
                return (
                  <Row key={`row${i}`}>
                    <Col md={flaverList.length > 0 ? 6 : 12} sm={flaverList.length > 0 ? 6 : 12} className="form-group">
                      <Form.Label htmlFor={`enter-code-textbox-${i}`}>
                        <Trans>Enter Code Here</Trans>
                      </Form.Label>
                      <Form.Control
                        name={`code`}
                        onChange={e => {
                          updateCodeList(e, i);
                        }}
                        value={v.code}
                        maxLength={100}
                        id={`enter-code-textbox-${i}`}
                        className="form-control"
                        aria-required="true"
                        placeholder=""
                      />
                      <span style={{ color: 'red' }}>{error[i]?.code}</span>
                    </Col>

                    {flaverList.length > 0 ? (
                      <Col md={5} sm={5}>
                        {options[i] && options[i].length > 0 ? (
                          <>
                            <Form.Label>Select Flavor</Form.Label>
                            <Form.Control
                              as="select"
                              onChange={e => {
                                updateCodeList(e, i);
                              }}
                              value={v.flavor}
                              id={`select-flavor-${i}`}>
                              <option>Choose Flavor</option>
                              {options[i].map((f, j) => {
                                return <option value={f.value}>{f.label}</option>;
                              })}
                            </Form.Control>
                            <span style={{ color: 'red' }}>{error[i]?.flavor}</span>
                          </>
                        ) : (
                          ''
                        )}
                      </Col>
                    ) : (
                      ''
                    )}

                    {/* <Col md={1} sm={1} className="pt-4 mt-1">
                      <button
                        type="button"
                        onClick={e => {
                          codeListUpdate(i);
                        }}
                        className="btn btn-primary px-0"
                        id={i === codelist.length - 1 ? `add-code-btn` : `del-code-btn-${i}`}>
                        {i === codelist.length - 1 ? '+' : '-'}
                      </button>
                    </Col> */}
                  </Row>
                );
              })}
            </Col>
          </Row>
          {captchaEnable ? (
            <Row className="justify-content-md-center pt-2">
              <Col xs lg="6" className="text-center">
                <Captcha reset={resetCaptcha} parentCallback={setCaptchaValue} />
              </Col>
            </Row>
          ) : (
            ''
          )}
          <Row className="justify-content-md-center pt-2">
            <Col xs lg="6" className="text-center">
              <div className="btn-div">
                <button type="button" onClick={formSubmit} className="btn btn-primary" id="submit-code-btn">
                  <Trans>Submit</Trans>
                </button>
                {/* <p align="center">
                  <Link id="href-reset" to="#" onClick={resetComponent}>
                    <Trans>Reset</Trans>
                  </Link>
                </p> */}
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <>
          {codeStatusList.map((v, i) => {
            return (
              <Row className="justify-content-md-center mt-5 py-3" key={i}>
                <Col xs lg="6" className={v.isValid ? 'response-message-div text-center bg-success pt-3' : 'response-message-div text-center bg-warning pt-3'}>
                  <h4 className="text-center">{v.code}</h4>
                  {v.isValid ? (
                    <p className="validate-code-response-msg text-center f-size-24" id={`msg-${v.code}-${i}`}>
                      {v.points ? v.message : <Trans>Congratulations! You have successfully validated your code.</Trans>}
                    </p>
                  ) : (
                    <p className="validate-code-response-msg text-center f-size-24" id={`msg-${v.code}-${i}`}>
                      <Trans>{v.message}</Trans>.
                    </p>
                  )}
                </Col>
              </Row>
            );
          })}
          <Row className="justify-content-md-center">
            <Col xs lg="6" className="text-center">
              <div className="btn-div">
                <button type="button" onClick={resetComponent} className="btn btn-primary" id="submit-more-code-btn">
                  <Trans>ENTER MORE CODES</Trans>
                </button>
              </div>
            </Col>
          </Row>
        </>
      )}
      <Modal dialogClassName="modal-80w" show={showPopup} onHide={e => setShowPopup(false)} size="lg" aria-labelledby="example-custom-modal-styling-title">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <p>
                  <Trans>IF THIS IS YOUR INSERT</Trans>,{' '}
                  <Link id="href-goback" to="#" className="validate-purchase-href-model-link" onClick={e => setShowPopup(false)}>
                    <Trans>GO BACK</Trans>
                  </Link>{' '}
                  <Trans>AND ENTER YOUR CODE</Trans>!
                </p>
              </Col>
              <Col xs={6} md={6}>
                <p>
                  <Trans>IF THIS IS YOUR INSERT</Trans>, <Trans>PLEASE VISIT</Trans>
                  <a id="validate-purchase-href-go-others-link" className="validate-purchase-href-model-link" target="new" href="https://dev.userportal-terra.rsi-rnd.com/validate-code">
                    {' '}
                    DEV.USERPORTAL-TERRA.RSI-RND.COM{' '}
                  </a>
                  <Trans>TO REDEEM YOUR DIGITAL COPY</Trans>
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <img alt="image1" className="validate-purchase-div-modal-image" src={defaultOptions.imageBaseUrl + '/public/webstatic/images/vci.png'} />
              </Col>
              <Col xs={6} md={6}>
                <img alt="image2" className="validate-purchase-div-modal-image" src={defaultOptions.imageBaseUrl + '/public/webstatic/images/online-trolls.jpg'} />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default IsLoadingHOC(SubmitCode, 'Loading....');
