import React from 'react';
import { FacebookProvider, LoginButton } from 'react-facebook';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import MicrosoftLogin from 'react-microsoft-login';
import AppleSignin from 'react-apple-signin-auth';
import { connect } from 'react-redux';
import { facebookLogin, googleLoign, microsoftLogin, appleLoignAction } from '../redux/actions';
import IsLoadingHOC from './IsLoadingHOC';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Button, Container, Modal, Row, Col } from 'react-bootstrap';
import OAuthRegistration from './OAuthRegistration';
import { store } from '../redux/store';
function HookWrapper({ responseGoogle, render }) {
  const hookValue = useGoogleLogin({
    onSuccess: responseGoogle,
    onFailure: responseGoogle,
  });
  return render(hookValue);
}
class SocialLogin extends React.Component {
  constructor(props) {
    super(props);
    const config = store.getState('app').app.config ? store.getState('app').app.config : {};
    this.state = {
      error: null,
      name: '',
      accessToken: '',
      email: '',
      roles: [],
      app: '',
      showModel: false,
      userData: {},
      updateId: '',
      msalInstance: '',
      designProp: { height: 40 },
      campaignConfig: config,
    };
    this.responseApple = this.responseApple.bind(this);
  }
  amazoneSSOLogin = async () => {
    const { t } = this.props;
    window.amazon.Login.setClientId(this.state.campaignConfig.amazonAuthentication.clientId);
    let options = { scope: 'profile' };
    window.amazon.Login.authorize(options, resp => {
      if (resp.error) {
        toast.error('oauth error ' + resp.error);
        return;
      }
      const requestOptions = {
        accessToken: resp.access_token,
      };
      this.props.setLoading(true);
      this.props
        .amazonLogin(requestOptions)
        .then(response => {
          if (response.httpCode === 202) {
            let oldStat = {
              ...this.state,
              showModel: true,
              userData: response.data,
              updateId: 'amazon',
            };
            this.setState(oldStat);
            this.props.setLoading(false);
          } else {
            this.props.setLoading(false);
            this.props.history.push('/home');
            toast.success(t('User login successfully'));
          }
        })
        .catch(error => {
          console.log('err', error);
          this.props.setLoading(false);
        });
    });
  };
  responseFacebook = resp => {
    console.log('resp', resp);
    const { t } = this.props;
    const requestOptions = {
      id: resp.id,
      email: resp.email,
      name: resp.name,
      accessToken: resp.accessToken,
    };
    this.props.setLoading(true);
    this.props
      .facebookLogin(requestOptions)
      .then(response => {
        if (response.httpCode === 202) {
          let oldStat = {
            ...this.state,
            showModel: true,
            userData: response.data,
            updateId: 'facebook',
          };
          this.setState(oldStat);
          this.props.setLoading(false);
        } else {
          this.props.setLoading(false);
          this.props.history.push('/home');
          toast.success(t('User login successfully'));
        }
      })
      .catch(error => {
        console.log('err', error);
        this.props.setLoading(false);
      });
  };
  responseGoogle = resp => {
    const { t } = this.props;
    if (!resp.credential) {
      console.log('Google SSO Error', resp);
      toast.error(`Google Client verification failed.`);
      return false;
    }
    const respData = JSON.parse(atob(resp.credential.split('.')[1]));
    const requestOptions = { ...respData, id: resp?.googleId || '', email: respData?.email || '', name: respData?.name || '', accessToken: resp?.credential || '', select_by: resp?.select_by || '' };
    this.props.setLoading(true);
    this.props
      .googleLoign(requestOptions)
      .then(response => {
        if (response.httpCode === 202) {
          let oldStat = {
            ...this.state,
            showModel: true,
            userData: response.data,
            updateId: 'google',
          };
          this.setState(oldStat);
          this.props.setLoading(false);
        } else {
          this.props.setLoading(false);
          this.props.history.push('/home');
          toast.success(t('User login successfully'));
        }
      })
      .catch(error => {
        console.log('err', error);
        this.props.setLoading(false);
      });
  };
  responseMicrosoft = (err, resp, msal) => {
    const { t } = this.props;
    try {
      if (!err && resp) {
        this.setState({ msalInstance: msal });
      }

      const requestOptions = {
        id: resp.idToken.objectId,
        email: resp.account.userName ? resp.account.userName : '',
        name: resp.account.name ? resp.account.name : '',
        accessToken: resp.accessToken,
      };
      this.props.setLoading(true);
      this.props
        .microsoftLogin(requestOptions)
        .then(response => {
          if (response.httpCode === 202) {
            let oldStat = {
              ...this.state,
              showModel: true,
              userData: response.data,
              updateId: 'microsoft',
            };
            this.setState(oldStat);
            this.props.setLoading(false);
          } else {
            this.props.setLoading(false);
            this.props.history.push('/home');
            toast.success(t('User login successfully'));
          }
        })
        .catch(error => {
          console.log('err', error);
          this.props.setLoading(false);
        });
    } catch (error) {
      console.log('err', error);
      this.props.setLoading(false);
    }
  };
  parseJwt = token => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };
  responseApple(resp) {
    console.log('hhh', resp);
    if (resp.authorization) {
      const { t } = this.props;
      const tokenAppleData = this.parseJwt(resp.authorization.id_token);
      const requestOptions = {
        id: resp.authorization.id_token,
        email: tokenAppleData ? tokenAppleData.email : '',
        code: resp.authorization.code,
      };
      this.props.setLoading(true);
      this.props
        .appleLoignAction(requestOptions)
        .then(response => {
          if (response.httpCode === 202) {
            let oldStat = {
              ...this.state,
              showModel: true,
              userData: response.data,
              updateId: 'apple',
            };
            this.setState(oldStat);
            this.props.setLoading(false);
          } else {
            this.props.setLoading(false);
            this.props.history.push('/home');
            toast.success(t('User login successfully'));
          }
        })
        .catch(error => {
          console.log('err', error);
          this.props.setLoading(false);
        });
    }
  }
  render() {
    const { error } = this.state;
    const { t } = this.props;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else {
      return (
        <Container>
          <Row md={1} xs={1}>
            {this.state.campaignConfig.amazonAuthentication && this.state.campaignConfig.amazonAuthentication.clientId && (
              <Col className="pt-2 text-center">
                <div className="amazon-login">
                  <Button onClick={this.amazoneSSOLogin} className="btn-sso">
                    <img src="/public/assets/images/amazon.png" width="40" alt="amaozon SSO" /> Continue with Amazon
                  </Button>
                </div>
              </Col>
            )}
            {this.state.campaignConfig.googleAuthentication && this.state.campaignConfig.googleAuthentication.clientId && (
              <Col className="pt-2 text-center">
                <div className="google-login">
                  <GoogleOAuthProvider clientId={this.state.campaignConfig.googleAuthentication.clientId}>
                    <HookWrapper
                      responseGoogle={this.responseGoogle}
                      render={hookValue => (
                        <Button onClick={() => hookValue()} className="btn-sso">
                          <img src="/public/assets/images/google.png" width="25" alt="Google SSO" /> Continue with Google
                        </Button>
                      )}
                    />
                  </GoogleOAuthProvider>
                </div>
              </Col>
            )}
            {this.state.campaignConfig.facebookAuthentication && this.state.campaignConfig.facebookAuthentication.appId && (
              <Col className="pt-2 text-center">
                <div className="facebook-login">
                  <FacebookProvider appId={this.state.campaignConfig.facebookAuthentication.appId}>
                    <LoginButton className="btn btn-primary btn-sso" fields="name,email,picture" scope="public_profile,email" onError={this.responseFacebook} onSuccess={this.responseFacebook}>
                      <img src="/public/assets/images/facebook.png" width="35" alt="Facebook SSO" /> Continue with Facebook
                    </LoginButton>
                  </FacebookProvider>
                </div>
              </Col>
            )}
            {this.state.campaignConfig.appleAuthentication && this.state.campaignConfig.appleAuthentication.clientId && (
              <Col className="pt-2 text-center">
                <div className="apple-login">
                  <AppleSignin
                    authOptions={{
                      clientId: this.state.campaignConfig.appleAuthentication.clientId,
                      scope: 'email name',
                      redirectURI: this.state.campaignConfig.appleAuthentication.redirectURI,
                      state: `${new Date().getTime()}`,
                      nonce: 'nonce',
                      usePopup: true,
                    }} // REQUIRED
                    uiType="dark"
                    className="apple-auth-btn"
                    noDefaultStyle={false}
                    buttonExtraChildren={t('login')}
                    onSuccess={this.responseApple} // default = undefined
                    onError={error => console.log(',,,,,,,,,,,,,,', error)} // default = undefined
                    skipScript={false} // default = undefined
                  />
                </div>
              </Col>
            )}
            {this.state.campaignConfig.microsoftAuthentication && this.state.campaignConfig.microsoftAuthentication.clientId && (
              <div className="microsoft-login">
                {!this.state.msalInstance && (
                  <Col className="pt-2 text-center">
                    <MicrosoftLogin buttonText={t('login')} clientId={this.state.campaignConfig.microsoftAuthentication.clientId} authCallback={this.responseMicrosoft} cookiePolicy={'single_host_origin'} />{' '}
                  </Col>
                )}
              </div>
            )}

            <Modal size="xl" show={this.state.showModel} animation={false}>
              <Modal.Body>
                <OAuthRegistration userData={this.state.userData} updateId={this.state.updateId} />
              </Modal.Body>
            </Modal>
          </Row>
        </Container>
      );
    }
  }
}

export default connect(null, { facebookLogin, googleLoign, microsoftLogin, appleLoignAction })(IsLoadingHOC(withRouter(withTranslation()(SocialLogin))));
