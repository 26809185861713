import React, { useState, useEffect } from 'react';
import { getUserDetails, getLockupAction, updateUserDetails } from '../../redux/actions';
import { connect } from 'react-redux';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { isValid, differenceInYears } from 'date-fns';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Trans, useTranslation } from 'react-i18next';
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import GooglePlaces from '../../components/GooglePlaces';
import InputMask from 'react-input-mask';
import Required from '../../components/Required';
var validator = require('validator');
const getFormattedDate = date => {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '/home' + year;
};
const formatedDateStr = value => {
  let d = value?.split('/home');
  return d[0] + '/15/' + d[1];
};
export const camelToTitle = camelCase =>
  camelCase
    .replace(/([A-Z])/g, match => ` ${match}`)
    .replace(/^./, match => match.toUpperCase())
    .replace(/[_]/, ' ')
    .trim();
const ProfileForm = ({ getUserDetails, updateUserDetails, setLoading, hideModal, userdata }) => {
  const { t } = useTranslation();
  const confige = useSelector(state => state.app.config);
  const user = useSelector(state => state.user);
  const history = useHistory();
  const [place, setPlace] = useState({});
  const [placeCompleteAddress, setPlaceCompleteAddress] = useState('');
  let [animalList, setAnimalList] = useState([]);
  let [countryList, setCountryList] = useState([]);
  let [stateList, setStateList] = useState([]);

  const [redirectUrl, setRedirectUrl] = useState('/home');
  /*[START:INITIALIZE_DECLARATION]*/
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    birth: '',
    homePhone: '',
    cellPhone: '',
    address: '',
    address2: '',
    country: '',
    state: '',
    suite: '',
    city: '',
    zip: '',
    shippingInstructions: '',
    gender: '',
    receiveEmail: true,
    receiveSms: true,
    companyName: '',
    instagramHandle: '',
    facebookHandle: '',
    tiktokHandle: '',
    twitterHandle: '',
    socialSecurityNumber: '',
    secondaryEmail: '',
    additionalInfo: { activity_tags_list: '', terms: false, visitorId: '', requestId: '', locationType: '', occupation: '', birthNotDisclose: false },
  });
  /*[END:INITIALIZE_DECLARATION]*/
  const [validation, setValidation] = useState({});
  const [error, setError] = useState({ firstName: '', email: '', homePhone: '', cellPhone: '', lastName: '', username: '', address: '', address2: '', country: '', state: '', suite: '', zip: '', city: '', shippingInstructions: '', receiveEmail: '', receiveSms: '', gender: '', instagramHandle: '', facebookHandle: '', tiktokHandle: '', twitterHandle: '', socialSecurityNumber: '', secondaryEmail: '', locationType: '', occupation: '' });
  //const [calender, setCalender] = useState(false);
  const [bonusPoints, setBonusPoints] = useState(0);
  useEffect(() => {
    if (confige.bonusPointInfo) {
      if (confige.bonusPointInfo.onProfileComplete) {
        setBonusPoints(confige.bonusPointInfo.onProfileComplete.points ? confige.bonusPointInfo.onProfileComplete.points : 0);
      }
    }
  }, [confige, confige.bonusPointInfo]);
  useEffect(() => {
    if (userdata) {
      if (userdata.birth != null && userdata.birth !== '') {
        userdata.birth = getFormattedDate(new Date(userdata.birth));
      }

      let tagList = '';
      if (userdata.additionalInfo?.activity_tags_list) {
        let tagListArr = userdata.additionalInfo.activity_tags_list.split(',').map(v => v.trim().toLowerCase());
        tagList = tagListArr.join(',');
      }
      let address = '';
      if (userdata.address) {
        address = userdata.address;
      }
      if (userdata.city) {
        address = `${address}${address ? ', ' : ''}${userdata.city}`;
      }
      if (userdata.state) {
        address = `${address}${address ? ', ' : ''}${userdata.state}`;
      }
      if (userdata.country) {
        address = `${address}${address ? ', ' : ''}${userdata.country}`;
      }
      setPlaceCompleteAddress(address);
      setData({ ...data, ...userdata, additionalInfo: { ...userdata.additionalInfo, activity_tags_list: tagList, birthNotDisclose: userdata?.additionalInfo?.birthNotDisclose === 'true' } });
    } else {
      setLoading(true);
      getUserDetails(user.id)
        .then(response => {
          setLoading(false);
          if (response.data.birth) {
            response.data.birth = getFormattedDate(new Date(response.data.birth));
          }
          let tagList = '';
          if (response.data.additionalInfo?.activity_tags_list) {
            let tagListArr = response.data.additionalInfo.activity_tags_list.split(',').map(v => v.trim());
            tagList = tagListArr.join(',');
          }
          let address = '';
          if (response.data.address) {
            address = response.data.address;
          }
          if (response.data.city) {
            address = `${address}${address ? ', ' : ''}${response.data.city}`;
          }
          if (response.data.state) {
            address = `${address}${address ? ', ' : ''}${response.data.state}`;
          }
          if (response.data.country) {
            address = `${address}${address ? ', ' : ''}${response.data.country}`;
          }
          setPlaceCompleteAddress(address);
          response.data['secondaryEmail'] = response.data['secondaryEmail'] ? response.data['secondaryEmail'] : '';
          setData({ ...data, ...response.data, additionalInfo: { ...response.data.additionalInfo, activity_tags_list: tagList, birthNotDisclose: response.data?.additionalInfo?.birthNotDisclose === 'true' } });
        })
        .catch(error => {
          setLoading(false);
          console.log('error', error);
        });
    }

    let items = {};
    if (confige.uiConfig) {
      confige.uiConfig.userFields.forEach(element => {
        if (['birth'].indexOf(element.name) >= 0) {
          element.validation.mandatory = true;
        }
        items[element.name] = element.validation;
      });
    }
    setValidation(items);
    // eslint-disable-next-line
    loadAnimalList();
  }, [userdata]);
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const redtUrl = query.get('route') ? query.get('route') : '/home';
    if (redirectUrl !== redtUrl) {
      setRedirectUrl(redtUrl);
    }
    // eslint-disable-next-line
  }, [window.location]);
  const loadAnimalList = () => {
    let requestData = { lookups: ['ACTIVITY_TAGS_LIST', 'STATE_LIST', 'COUNTRY_LIST'] };
    setLoading(true);
    getLockupAction(requestData)
      .then(response => {
        if (response.httpCode === 200 && response.data) {
          if (response.data.content) {
            let animalListInd = response.data.content.findIndex(v => v.name === 'ACTIVITY_TAGS_LIST');
            let comList = [];
            if (animalListInd >= 0) {
              comList = response.data.content[animalListInd].data;
              let dt = response.data.content[animalListInd].data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
              setAnimalList(dt);
            }
            let stListInd = response.data.content.findIndex(v => v.name === 'STATE_LIST');
            if (stListInd >= 0) {
              setStateList(response.data.content[stListInd].data);
            }
            let cnListInd = response.data.content.findIndex(v => v.name === 'COUNTRY_LIST');
            if (cnListInd >= 0) {
              setCountryList(response.data.content[cnListInd].data);
            }
          }
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
      });
  };
  const handleChange = e => {
    const { name, value } = e.target;
    let errorMessage = '';
    let validationArray = validation[name];
    let check = false;
    for (let key in validationArray) {
      if (key === 'minLength' && value.length !== 0) {
        if (value.length < validationArray[key]) {
          errorMessage = `${t('Should not be less then', { count: validationArray[key] })}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'maxLength') {
        if (value.length > validationArray[key]) {
          errorMessage = `${t('Should not be greater then', { count: validationArray[key] })}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'mandatory' && validationArray[key]) {
        if (!value.length) {
          errorMessage = `${t('Should not be empty')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'email' && validationArray[key] && value) {
        if (!validator.isEmail(value)) {
          errorMessage = `${t('Please enter a valid email')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'upperCaseReq' && validationArray[key]) {
        if (value.search(/[A-Z]/) < 0 && value.length !== 0) {
          errorMessage = `${t('Should have at least one Upper Case latter')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'lowerCaseReq' && validationArray[key] && value.length !== 0) {
        if (value.search(/[a-z]/) < 0) {
          errorMessage = `${t('Should have at least one Lower Case latter')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'specialCharReq' && validationArray[key]) {
        // eslint-disable-next-line
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (!format.test(value) && value.length !== 0) {
          errorMessage = `${t('Should have at least one special letter')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
    }

    if ((name === 'firstName' || name === 'lastName') && value.length !== 0) {
      const nameFormat = /^[a-zA-z]+([\s|'|’|-][a-zA-Z]+)*$/;
      const nameString = value.trim();
      if (!nameFormat.test(nameString)) {
        errorMessage = `Should not have any specific character (except ' or - between 2 character) and more then single space.`;
        setError({ ...error, [name]: errorMessage });
        check = true;
      }
    }

    if (name === 'birth' && value != null && value !== '') {
      const dob = formatedDateStr(value);
      if (!isValid(new Date(dob)) || differenceInYears(new Date(), new Date(dob)) < 1) {
        errorMessage = `${t('Invalid')} ${t('Birth Date')}.`;
        setError({ ...error, [name]: errorMessage });
        check = true;
      }
    }
    if (name === 'birthNotDisclose' && e.target.checked) {
      setError({ ...error, birth: undefined });
      check = true;
    }
    let additionalInfo = { ...data.additionalInfo };

    if (!check) {
      setError({ ...error, [name]: '' });
    }
    if (name === 'locationType' || name === 'occupation' || name === 'birthNotDisclose') {
      if (name === 'birthNotDisclose') {
        setData({ ...data, additionalInfo: { ...additionalInfo, [name]: e.target.checked }, birth: '' });
      } else {
        setData({ ...data, additionalInfo: { ...additionalInfo, [name]: value } });
      }
    } else {
      setData({ ...data, [name]: value });
    }
  };
  const onSubmit = () => {
    let errorArray = {};
    for (let name in data) {
      let value = data[name];
      let validationArray = validation[name];
      let errorMessage = '';
      if ((name === 'firstName' || name === 'lastName') && value.length !== 0) {
        const nameFormat = /^[a-zA-z]+([\s|'|’|-][a-zA-Z]+)*$/;
        const nameString = value.trim();
        if (!nameFormat.test(nameString)) {
          errorMessage = `Should not have any specific character (except ' or - between 2 character) and more then single space.`;
        }
      }

      if (name === 'birth' && value !== null && value !== '') {
        const dob = formatedDateStr(value);
        if (!isValid(new Date(dob)) || differenceInYears(new Date(), new Date(dob)) < 1) {
          errorMessage = `${t('Invalid')} ${t('Birth Date')}.`;
        }
      }
      for (let key in validationArray) {
        if (key === 'email' && validationArray[key] && !validator.isEmail(value) && value) {
          errorMessage = `${t('Please enter a valid email')}.`;
        } else if (key === 'minLength' && value != null && value.length < validationArray[key]) {
          if (value?.length !== 0) {
            errorMessage = `${t('Should not be less then', { count: validationArray[key] })}.`;
          }
        } else if (key === 'maxLength' && value != null && value.length > validationArray[key]) {
          errorMessage = `${t('Should not be greater then', { count: validationArray[key] })}.`;
        } else if (key === 'mandatory' && validationArray[key] && (value === null || !value.length)) {
          if (name !== 'birth') {
            errorMessage = 'Should not be empty.';
          } else if (name === 'birth' && !data.additionalInfo.birthNotDisclose) {
            errorMessage = `${t('Should not be empty.')}`;
          }
        } else if (key === 'upperCaseReq' && value != null && value.search(/[A-Z]/) < 0 && value.length !== 0) {
          errorMessage = `${t('Should have at least one Upper Case latter')}.`;
        } else if (key === 'lowerCaseReq' && value != null && value.search(/[a-z]/) < 0 && value.length !== 0) {
          errorMessage = `${t('Should have at least one Lower Case latter')}.`;
        } else if (key === 'specialCharReq' && value != null && value.length !== 0) {
          // eslint-disable-next-line
          var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
          if (!format.test(value)) {
            errorMessage = `${t('Should have at least one special letter')}.`;
          }
        }
      }
      errorArray[name] = errorMessage;
    }
    setError(errorArray);

    let check = false;
    for (let key in errorArray) {
      if (errorArray[key]) {
        check = true;
      }
    }
    if (data.additionalInfo.activity_tags_list === '') {
      let msg = `${t('Please select animals')}.`;
      setError({ ...error, additionalInfo: { ...error.additionalInfo, activity_tags_list: msg } });
      check = true;
    }
    if (data.secondaryEmail) {
      if (!validator.isEmail(data.secondaryEmail)) {
        let errorMessage = `${t('Please enter a valid email')}.`;
        setError({ ...error, secondaryEmail: errorMessage });
        check = true;
      }
      if (data.secondaryEmail === data.email) {
        let errorMessage = `${t('Secondary email should not be the same as Primary email')}.`;
        setError({ ...error, secondaryEmail: errorMessage });
        check = true;
      }
    }
    if (errorArray.country && !errorArray.address) {
      toast.error(`${t('Country is not selected')}.`);
    } else if (errorArray.state && !errorArray.address) {
      toast.error(`${t('State is not selected')}.`);
    } else if (errorArray.zip && !errorArray.address) {
      toast.error(`${t('Zipcode is not selected')}.`);
    } else if (errorArray.city && !errorArray.address) {
      toast.error(`${t('City is not selected')}.`);
    }

    if (!check) {
      setLoading(true);
      let newData = { ...data, birth: data.birth != null && data.birth !== '' ? formatedDateStr(data.birth) : null };
      updateUserDetails(user.id, newData)
        .then(response => {
          if (response.httpCode === 201) {
            toast.success(`Thanks for coming back! You’ve earned ${bonusPoints} points.`);
          } else {
            toast.success(response.message);
          }
          setLoading(false);
          hideModal && hideModal();
          history.push(redirectUrl);
        })
        .catch(error => {
          let errorMessage = 'Something went wrong!';
          if (Array.isArray(error)) {
            error.forEach(item => {
              if (item.message) {
                toast.error(`Something went wrong! ${Array.isArray(item.message) ? item.message.join(',') : item.message}`);
              } else {
                toast.error(errorMessage);
              }
            });
          } else {
            toast.error(errorMessage);
          }
          setLoading(false);
        });
    }
  };

  const handleAnimalList = event => {
    let values = data.additionalInfo.activity_tags_list.length > 0 ? data.additionalInfo.activity_tags_list.split(',') : [];
    values = event.target.checked ? [...values, event.target.value] : values.filter(item => item !== event.target.value);
    if (values.length === 0) {
      setError({ ...error, additionalInfo: { ...error.additionalInfo, activity_tags_list: `${t('Please select animals')}.` } });
    } else {
      setError({ ...error, additionalInfo: { ...error.additionalInfo, activity_tags_list: '' } });
    }
    setData({ ...data, additionalInfo: { ...data.additionalInfo, activity_tags_list: values.join(',') } });
  };
  useEffect(() => {
    if (place.hasOwnProperty('address')) {
      let curStateData = Object.assign({}, data);
      if (curStateData.hasOwnProperty('address')) {
        curStateData.address = place.address;
      }
      if (curStateData.hasOwnProperty('country')) {
        curStateData.country = place.country;
      }
      if (curStateData.hasOwnProperty('state')) {
        curStateData.state = place.state;
      }
      if (curStateData.hasOwnProperty('city')) {
        curStateData.city = place.city;
      }
      // if (curStateData.hasOwnProperty('suite')) {
      //   curStateData.suite = place.street;
      // }
      if (curStateData.hasOwnProperty('zip')) {
        curStateData.zip = place.zip;
      }
      setData(curStateData);
    }
    // eslint-disable-next-line
  }, [place]);
  const googleSelectedAddressHandler = place => {
    setPlace(place);
  };
  const formatChars = {
    '1': '[12]',
    '0': '[0123456789]',
  };

  const ssnTooltip = <Tooltip id="tooltip">PLEASE TYPE IN YOUR ADDRESS AND SELECT IT FROM THE DROP-DOWN. IF YOU LIVE IN AN APARTMENT OR TOWNHOME, PLEASE ENTER THAT UNDER ‘APT/UNIT NUMBER'. IF NOT, PLEASE LEAVE THAT BLANK.</Tooltip>;

  return (
    <div className="row">
      {/*[START:FIRSTNAME]*/}
      <div className="form-group col-md-6 theme-profile-firstName">
        <label htmlFor="first-name" className="col-sm-12 col-form-label profile-firstName-label">
          <Trans>lang_key_fname</Trans>
          {validation['firstName'] && validation['firstName'].mandatory ? <Required /> : ''}
        </label>
        <div className="col-sm-12">
          <input type="text" data-test="profile-firstName" name="firstName" className="profile-firstName form-control" value={data.firstName !== null ? data.firstName : ''} onChange={handleChange} id="first-name" placeholder={`${t('Enter ')}${t('lang_key_fname')}`} />
          <span className="input-error" role="alert">
            {error.firstName}
          </span>
        </div>
      </div>
      {/*[END:FIRSTNAME]*/}

      {/*[START:LASTNAME]*/}
      <div className="form-group col-md-6 theme-profile-lastName">
        <label htmlFor="last-name" className="col-sm-12 col-form-label profile-lastName-label">
          <Trans>lang_key_lname</Trans>
          {validation['lastName'] && validation['lastName'].mandatory ? <Required /> : ''}
        </label>
        <div className="col-sm-12">
          <input type="text" data-test="profile-lasttName" name="lastName" className="profile-lastName form-control" value={data.lastName !== null ? data.lastName : ''} onChange={handleChange} id="last-name" placeholder={`${t('Enter ')}${t('lang_key_lname')}`} />
          <span className="input-error" role="alert">
            {error.lastName}
          </span>
        </div>
      </div>
      {/*[END:LASTNAME]*/}

      {/*[START:USERNAME]*/}
      <div className="form-group col-md-6 theme-profile-username">
        <label htmlFor="user-name" className="col-sm-12 col-form-label">
          <Trans>lang_key_username</Trans>
          {validation['username'] && validation['username'].mandatory ? <Required /> : ''}
        </label>
        <div className="col-sm-12">
          <input type="text" data-test="profile-username" name="username" className="profileUserName form-control" value={data.username !== null ? data.username : ''} id="user-name" placeholder={`${t('Enter ')}${t('lang_key_username')}`} disabled />
          <span className="input-error" role="alert">
            {error.username}
          </span>
        </div>
      </div>
      {/*[END:USERNAME]*/}

      {/*[START:EMAIL]*/}
      <div className="form-group col-md-6 theme-profile-email">
        <label htmlFor="user-email" className="col-sm-12 col-form-label">
          <Trans>lang_key_email</Trans>
          {validation['email'] && validation['email'].mandatory ? <Required /> : ''}
        </label>
        <div className="col-sm-12">
          <input type="email" data-test="profile-email" name="email" className="profileEmail form-control" value={data.email} id="email" placeholder={`${t('Enter ')}${t('lang_key_email')}`} disabled />
          <span className="input-error" role="alert">
            {error.email}
          </span>
        </div>
      </div>
      {/*[END:EMAIL]*/}

      {/*[START:SECONDARY EMAIL]*/}
      <div className="form-group col-md-6 theme-profile-email">
        <label htmlFor="secondary-email" className="col-sm-12 col-form-label">
          <Trans>Secondary Email</Trans>
          {validation['secondaryEmail'] && validation['secondaryEmail'].mandatory ? <Required /> : ''}
        </label>
        <div className="col-sm-12">
          <input type="email" data-test="profile-secondary-email" name="secondaryEmail" className="profileEmail form-control" value={data.secondaryEmail ? data.secondaryEmail : ''} onChange={handleChange} id="secondaryEmail" placeholder={`${t('Enter ')}${t('Secondary Email')}`} />
          <span className="input-error" role="alert">
            {error.secondaryEmail}
          </span>
        </div>
      </div>
      {/*[END:SECONDARYEMAIL]*/}

      {/*[START:GENDER]*/}
      <div className="form-group col-md-6 theme-profile-gender">
        <label htmlFor="gender" className="col-sm-12 col-form-label theme-profile-label-gender">
          <Trans>Gender</Trans>
          {validation['gender'] && validation['gender'].mandatory ? <Required /> : ''}
        </label>
        <div className="col-sm-12">
          <select className="form-control" data-test="profile-gender" name="gender" onChange={handleChange} value={data.gender !== null ? data.gender : ''} id="gender" placeholder={t('Gender')}>
            <option value="">
              {t('Select')} {t('Gender')}
            </option>
            <option value="m">{t('Male')}</option>
            <option value="f">{t('Female')}</option>
            <option value="na">{t('Prefer not to disclose')}</option>
          </select>
          <span className="input-error" role="alert">
            {error.gender}
          </span>
        </div>
      </div>
      {/*[END:GENDER]*/}

      {/*[START:BIRTH]*/}
      <div className="form-group col-md-6 theme-profile-birth">
        <label htmlFor="birth" className="col-sm-12 col-form-label theme-profile-label-birth">
          <Trans>Birth Date</Trans>
          {validation['birth'] && validation['birth'].mandatory ? <Required /> : ''}
        </label>
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-4">
              <InputMask className="form-control show-placegholder" mask="00/1000" name="birth" maskChar={''} value={data.birth} id="birth" formatChars={formatChars} onChange={handleChange} placeholder="MM/YYYY" disabled={data.additionalInfo.birthNotDisclose}></InputMask>
              <span className="input-error" role="alert">
                {error.birth}
              </span>
            </div>
            <div className="col-sm-6">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.additionalInfo.birthNotDisclose}
                    onChange={handleChange}
                    name="birthNotDisclose"
                    color="primary"
                    value={data.additionalInfo.birthNotDisclose}
                    inputProps={{
                      'aria-label': `Prefer not to disclose`,
                    }}
                  />
                }
                label="Prefer not to disclose"
              />
            </div>
          </div>
        </div>
      </div>
      {/*[END:BIRTH]*/}
      {/*[START:OCCUPATION]*/}
      <div className="form-group col-md-6 theme-profile-firstName">
        <label htmlFor="first-name" className="col-sm-12 col-form-label profile-firstName-label">
          <Trans>Occupation</Trans>
          {validation['occupation'] && validation['occupation'].mandatory ? <Required /> : ''}
        </label>
        <div className="col-sm-12">
          <input type="text" name="occupation" className={data.additionalInfo.occupation ? (data.additionalInfo.occupation !== '' ? 'form-control' : 'form-control req-highlight') : 'form-control req-highlight'} value={data.additionalInfo.occupation !== null ? data.additionalInfo.occupation : ''} onChange={handleChange} id="first-name" placeholder={`${t('Enter ')}${t('Occupation')}`} />
          <span className="input-error" role="alert">
            {error.occupation}
          </span>
        </div>
      </div>
      {/*[END:OCCUPATION]*/}
      {/*[START:ADDRESS]*/}
      {/* <div className="form-group col-md-6 theme-signup-address-finder d-none">
        <div className="custom-tooltip-wrap">
          <label htmlFor="addressFinder" className="col-sm-12 col-form-label">
            <Trans>lang_key_select_address</Trans>
            {validation['address'] && validation['address'].mandatory ? <Required /> : ''}
            <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={ssnTooltip}>
              <button type='button' className="btn default">?</button>
            </OverlayTrigger>
          </label>
        </div>
        <div className="col-sm-12">
          <GooglePlaces getPlaceHandler={googleSelectedAddressHandler} placeholder={`${t('Enter ')}${t('lang_key_select_address')}`} value={placeCompleteAddress} />
          <span className="input-error" role="alert">
            {error.address}
          </span>
        </div>
      </div> */}

      {/*[START:ADDRESS]*/}
      <div className="form-group col-md-6 theme-profile-address">
        <label htmlFor="address" className="col-sm-12 col-form-label custom-tooltip-wrap">
          <Trans>lang_key_add_address</Trans>
          {validation['address'] && validation['address'].mandatory ? <Required /> : ''}
          {/* <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={ssnTooltip}>
            <button type='button' className="btn default">?</button>
          </OverlayTrigger> */}
        </label>
        <div className="col-sm-12">
          <input type="text" data-test="register-address" name="address" onChange={handleChange} className="profileAddress form-control" value={data.address ? data.address : ''} id="address" placeholder={`${t('Enter ')}${t('lang_key_add_address')}`} />
          <span className="input-error" role="alert">
            {error.address}
          </span>
        </div>
      </div>
      {/*[END:ADDRESS]*/}
      {/*[START:SUITE]*/}
      <div className="form-group col-md-6 theme-profile-suite">
        <label htmlFor="suite" className="col-sm-12 col-form-label">
          <Trans>Apt/Unit Number</Trans>
          {validation['suite'] && validation['suite'].mandatory ? <Required /> : ''}
        </label>
        <div className="col-sm-12">
          <input type="text" data-test="profile-suite" name="suite" onChange={handleChange} value={data.suite ? data.suite : ''} className="form-control" id="suite" placeholder={`${t('Enter ')}${t('Apt/Unit Number')}`} />
          <span className="input-error" role="alert">
            {error.suite}
          </span>
        </div>
      </div>
      {/*[END:SUITE]*/}
      {/*[START:ADDRESS2]*/}
      {/* <div className="form-group col-md-6 theme-signup-address">
    <label htmlFor="address2" className="col-sm-12 col-form-label">
      <Trans>lang_key_add_address2</Trans>
      {validation['address2'] && validation['address2'].mandatory ? <Required /> : ''}
    </label>
    <div className="col-sm-12">
      <input type="text" data-test="register-address2" value={data.address2} name="address2" className="form-control" id="address2" placeholder={`${t('Enter ')}${t('lang_key_add_address2')}`} />
      <span className="input-error" role="alert">
        {error.address2}
      </span>
    </div>
  </div> */}
      {/*[END:ADDRESS2]*/}
      {/*[START:COUNTRY]*/}
      <div className="form-group col-md-6 theme-profile-country">
        <label htmlFor="country" className="col-sm-12 col-form-label">
          <Trans>lang_key_add_country</Trans>
          {validation['country'] && validation['country'].mandatory ? <Required /> : ''}
        </label>
        <div className="col-sm-12">
          <select data-test="profile-country" name="country" onChange={handleChange} value={data.country ? data.country : ''} className="form-control" id="country" placeholder={`${t('Enter ')}${t('lang_key_add_country')}`}>
            <option value="">
              {t('Select')} {t('Country')}
            </option>
            {countryList.map(v => (
              <option value={v.id}>{v.name}</option>
            ))}
          </select>
          <span className="input-error" role="alert">
            {error.country}
          </span>
        </div>
      </div>
      {/*[END:COUNTRY]*/}

      {/*[START:STATE]*/}
      <div className="form-group col-md-6 theme-profile-state">
        <label htmlFor="state" className="col-sm-12 col-form-label">
          {t('lang_key_add_state')}
          {validation['state'] && validation['state'].mandatory ? <Required /> : ''}
        </label>
        <div className="col-sm-12">
          <select data-test="profile-state" name="state" onChange={handleChange} value={data.state ? data.state : ''} className="form-control" id="state" placeholder={`${t('Enter ')}${t('lang_key_add_state')}`}>
            <option value="">
              {t('Select')} {t('State')}
            </option>
            {stateList
              .filter(v => ['AE', 'DC', 'FM', 'GU', 'PR', 'VI'].indexOf(v.id) < 0)
              .map(v => (
                <option value={v.id}>{v.name}</option>
              ))}
            <hr />
            {stateList
              .filter(v => ['AE', 'DC', 'FM', 'GU', 'PR', 'VI'].indexOf(v.id) >= 0)
              .map(v => (
                <option value={v.id}>{v.name}</option>
              ))}
          </select>
          <span className="input-error" role="alert">
            {error.state}
          </span>
        </div>
      </div>
      {/*[END:STATE]*/}

      {/*[START:CITY]*/}
      <div className="form-group col-md-6 theme-profile-city">
        <label htmlFor="city" className="col-sm-12 col-form-label">
          <Trans>City</Trans>
          {validation['city'] && validation['city'].mandatory ? <Required /> : ''}
        </label>
        <div className="col-sm-12">
          <input type="text" data-test="profile-city" name="city" onChange={handleChange} value={data.city ? data.city : ''} className="form-control" id="city" placeholder={`${t('Enter ')}${t('City')}`} />
          <span className="input-error" role="alert">
            {error.city}
          </span>
        </div>
      </div>
      {/*[END:CITY]*/}

      {/*[START:ZIP]*/}
      <div className="form-group col-md-6 theme-profile-zip">
        <label htmlFor="zip" className="col-sm-12 col-form-label">
          <Trans>Zip</Trans>
          {validation['zip'] && validation['zip'].mandatory ? <Required /> : ''}
        </label>
        <div className="col-sm-12">
          <input type="text" data-test="profile-zip" name="zip" onChange={handleChange} value={data.zip ? data.zip : ''} className="form-control" id="zip" placeholder={`${t('Enter ')}${t('Zip')}`} />
          <span className="input-error" role="alert">
            {error.zip}
          </span>
        </div>
      </div>
      {/*[END:ZIP]*/}

      {/*[START:LOCATION TYPE]*/}
      <div className="form-group col-md-6 theme-profile-gender">
        <label htmlFor="locationType" className="col-sm-12 col-form-label theme-profile-label-gender">
          <Trans>Location Type</Trans>
          {validation['locationType'] && validation['locationType'].mandatory ? <Required /> : ''}
        </label>
        <div className="col-sm-12">
          <select className={data.additionalInfo.locationType ? (data.additionalInfo.locationType !== '' ? 'form-control' : 'form-control req-highlight') : 'form-control req-highlight'} data-test="profile-location-type" name="locationType" onChange={handleChange} value={data.additionalInfo.locationType !== null ? data.additionalInfo.locationType : ''} id="locationType" placeholder={t('Location Type')}>
            <option value="">
              {t('Select')} {t('Location Type')}
            </option>
            <option value="farm">{t('Farm')}</option>
            <option value="rural">{t('Rural')}</option>
            <option value="urban">{t('Urban')}</option>
            <option value="other">{t('Other')}</option>
          </select>
          <span className="input-error" role="alert">
            {error.locationType}
          </span>
        </div>
      </div>
      {/*[END:LOCATION TYPE]*/}

      {/*[START:RECEIVEEMAIL]*/}
      {/* <div className="form-group col-md-6 theme-profile-receiveEmail">
    <label htmlFor="receiveEmail" className="col-sm-12 col-form-label theme-profile-label-receiveEmail">
      <Trans>Receive Email</Trans>
      {validation['receiveEmail'] && validation['receiveEmail'].mandatory ? <Required /> : ''}
    </label>
    <div className="col-sm-12">
      <select className="form-control" data-test="profile-email" name="receiveEmail" onChange={handleChange} value={data.receiveEmail !== null ? data.receiveEmail : ''} id="receiveEmail" placeholder={t('Receive Email')}>
        <option value="">{t('Select')}</option>
        <option value={true}>{t('Yes')}</option>
        <option value={false}>{t('No')}</option>
      </select>
      <span className="input-error" role="alert">
        {error.receiveEmail}
      </span>
    </div>
  </div> */}
      {/*[END:RECEIVEEMAIL]*/}

      {/*[START:ANIMAL-LIST]*/}
      <div className="form-group col-md-12 theme-signup-lastname mt-3">
        <label htmlFor="animalList" className="col-sm-12 col-form-label theme-profile-label-animalList">
          <Trans>Animal Preference</Trans>
          <Required />
        </label>
        <div className="animal-list col">
          {animalList.map(listItem => {
            return <FormControlLabel key={listItem.id} control={<Checkbox checked={data.additionalInfo?.activity_tags_list.includes(listItem.id)} onChange={handleAnimalList} name={listItem.id} color="primary" value={listItem.id} inputProps={{ 'aria-label': `Select ${listItem.name}` }} />} label={listItem.name} />;
          })}
          <span className="input-error" role="alert">
            {error.additionalInfo?.activity_tags_list}
          </span>
        </div>
      </div>
      {/*[END:ANIMAL-LIST]*/}

      <div className="col-md-12 text-center mt-5">
        <button type="button" id="profile-submit-btn" data-test="profile-submit" onClick={onSubmit} className="btn btn-primary">
          <Trans>Save Profile</Trans>
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  ...state,
  hideModal: ownProps.hideModal,
  userdata: ownProps.userdata,
});
export default connect(mapStateToProps, { getUserDetails, updateUserDetails })(IsLoadingHOC(ProfileForm, 'Wait .....'));
