import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Row, Col, Form, Button } from 'react-bootstrap';

import { toast } from 'react-toastify';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { connect, useSelector } from 'react-redux';
import { login } from '../../redux/actions';
import { Link, useHistory } from 'react-router-dom';
import SocialLogin from '../../components/SocialLogin';
import { Trans, useTranslation } from 'react-i18next';
import SecondFactorAuth from '../../components/SecondFactorAuth';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import Captcha from '../../components/CaptchaClick';
import { TextField, InputAdornment, InputLabel } from '@material-ui/core';

const Login = ({ setLoading, login }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const confige = useSelector(state => state.app.config);
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [remember_me, setRememberMe] = useState(false);
  const [showSecondFactorPopup, setShowSecondFactorPopup] = useState(false);
  const [secondFactorAuthData, setSecondFactorAuthData] = useState({});
  const [errors, setErrors] = useState({ username: '', password: '', captchaVal: '' });
  const [fpData, setFpData] = useState({ visitorId: '', requestId: '' });
  let [captchaValue, setCaptchaValue] = useState(false);
  let [captchaEnable, setCaptchaEnable] = useState(false);
  let [captchaReset, setCaptchaReset] = useState(0);
  React.useEffect(() => {
    if ((confige?.captchaConfig?.recaptchaVersion || '').toLowerCase() === 'v3') {
      const interval = setInterval(() => {
        setCaptchaReset(captchaReset + 1);
      }, 45000);
      return () => clearInterval(interval);
    }
  }, [captchaReset, confige]);
  const onChangeHandle = e => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUserName(e.target.value);
      value.length < 1 ? setErrors({ ...errors, username: `${t('Username or Email is required')}.` }) : setErrors({ ...errors, username: '' });
    }
    if (name === 'password') {
      value.length < 1 ? setErrors({ ...errors, password: `${t('Password is required')}.` }) : setErrors({ ...errors, password: '' });
      setPassword(e.target.value);
    }
  };
  const [redirectUrl, setRedirectUrl] = useState('/home');
  let [passwordViewOpt, setPasswordViewOpt] = useState({ password: false });

  React.useEffect(() => {
    if (confige.fingerprinting) {
      if (confige.fingerprinting.enable) {
        const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
        // Get the visitor identifier when you need it.
        fpPromise
          .then(fp => fp.get())
          .then(result => {
            setFpData({ visitorId: result.visitorId, requestId: result.requestId });
          });
      }
    }
    if (confige.captchaConfig) {
      if (confige.captchaConfig.loginCaptcha) {
        setCaptchaEnable(true);
      }
    }
  }, [confige]);
  React.useEffect(() => {
    let errorArray = JSON.parse(JSON.stringify(errors));
    if (captchaValue) {
      errorArray['captchaVal'] = '';
    }
    setErrors(errorArray);
    // eslint-disable-next-line
  }, [captchaValue]);
  React.useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const redtUrl = query.get('route') ? query.get('route') : '/home';
    if (redirectUrl !== redtUrl) {
      setRedirectUrl(redtUrl);
    }
    // eslint-disable-next-line
  }, [window.location]);
  const handleError = () => {
    let value = false;
    const error = {};
    if (username.length < 1) {
      error.username = `${t('Username or Email is required')}.`;
      value = true;
    }
    if (password.length < 1) {
      error.password = `${t('Password is required')}.`;
      value = true;
    }
    if (!captchaValue && captchaEnable) {
      error.captchaVal = `${t('Please select captcha')}.`;
      value = true;
    }
    return { value, error };
  };

  const onSubmit = () => {
    const { value, error } = handleError();
    if (value) {
      setErrors({ ...errors, ...error });
      return false;
    }
    setLoading(true);
    let requestBody = { username, password, visitorId: fpData.visitorId, requestId: fpData.requestId };
    if (captchaEnable) {
      requestBody['captchaVal'] = captchaValue;
    }

    login(requestBody)
      .then(response => {
        setLoading(false);
        if (captchaEnable) {
          setCaptchaReset(captchaReset + 1);
        }
        if (response.httpCode === 204) {
          setSecondFactorAuthData({ id: response.data });
          setShowSecondFactorPopup(true);
        } else if (response.httpCode === 205) {
          history.push(`/reset-password?lgu=1&token=${response.data.token}`);
          toast.success(`${t('Please create new password')}.`);
        } else {
          //Call remember fn
          lsRememberMe(remember_me, username, password);
          toast.success(`${t('User login successfully')}.`);
          history.push(redirectUrl);
        }
      })
      .catch(error => {
        console.log('Error in login', error);
        if (error['httpCode'] === 403) {
          toast.error(`${t('Your account is suspended. Please contact helpdesk for more information')}.`);
        } else if (error['httpCode'] === 406) {
          toast.error(`${t('Error')}! ${t('User not verified')}.`);
        } else {
          let errorMessage = (error && error.errors && error.errors[0]['message']) || 'Invalid credentials.';
          toast.error(`${t(errorMessage)}`);
        }
        setLoading(false);
        if (captchaEnable) {
          setCaptchaReset(captchaReset + 1);
        }
      });
  };

  function lsRememberMe(rmCheck, username, pass) {
    if (rmCheck && username !== '' && pass !== '') {
      localStorage.username = username;
      localStorage.password = pass;
      localStorage.rmCheck = rmCheck;
    } else {
      localStorage.password = '';
      localStorage.username = '';
      localStorage.rmCheck = '';
    }
  }

  return (
    <div className="page balloons-background">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9 col-sm-12 col-xs-12">
            <div className="mat-card py-5 px-5">
              <h3 className="welcome">Welcome</h3>
              <h1 className="main-heading" style={{ margin: '10px 0px 20px 0px' }}>
                Account Log-In
              </h1>
              <h3 className="welcome">
                New to All-Access? Create an account{' '}
                <u>
                  <a href="/register">here</a>
                </u>
                .
              </h3>
              <form role="form">
                <Row md={1} xs={1} sm={1}>
                  {/*[START:FIRSTNAME]*/}
                  <Col className="pt-2">
                    <TextField
                      label=""
                      fullWidth
                      id="filled-start-adornment"
                      data-test="name"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="input-label" position="start">
                            EMAIL{' '}
                          </InputAdornment>
                        ),
                      }}
                      variant="filled"
                      value={username}
                      onChange={onChangeHandle}
                      aria-required="true"
                      aria-autocomplete="username"
                      error={errors?.username?.length > 0 ? true : false}
                      className={errors?.username?.length > 0 ? 'yelloy-text' : 'yelloy-text textfield-bottom-border'}
                      name="username"
                      helperText={errors.username}
                    />
                  </Col>
                  <Col className="pt-1">
                    <TextField
                      label=""
                      type={passwordViewOpt.password ? 'text' : 'password'}
                      fullWidth
                      id="filled-start-adornment"
                      data-test="name"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="input-label" position="start">
                            PASSWORD
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <span
                              onClick={() => {
                                setPasswordViewOpt({ ...passwordViewOpt, password: !passwordViewOpt.password });
                              }}
                              className="material-symbols-outlined">
                              {!passwordViewOpt.password ? 'visibility' : 'visibility_off'}
                            </span>
                          </InputAdornment>
                        ),
                      }}
                      variant="filled"
                      value={password}
                      onChange={onChangeHandle}
                      aria-required="true"
                      aria-autocomplete="password"
                      error={errors?.password?.length > 0 ? true : false}
                      className={errors?.password?.length > 0 ? 'yelloy-pass' : 'yelloy-pass textfield-bottom-border'}
                      name="password"
                      onKeyPress={e => e.key === 'Enter' && onSubmit()}
                      helperText={errors.password}
                    />
                  </Col>
                  {captchaEnable && (
                    <Col className="pt-1">
                      <Captcha align="left" reset={captchaReset} parentCallback={setCaptchaValue} />
                      <span className="input-error" role="alert">
                        {errors.captchaVal}
                      </span>
                    </Col>
                  )}
                </Row>
                <Row md={2} xs={1}>
                  <Col className="register-forgot-pass-links pt-5">
                    <Link to="forget-password">Forgot password? Reset now</Link>
                  </Col>
                  <Col className="pt-4">
                    <button onClick={onSubmit} data-test="sign-in-sign-in-button" type="button" className="login-button btn btn-primary">
                      <Trans>login</Trans>
                    </button>
                  </Col>
                </Row>
                <h3 className="welcome mt-2" style={{ fontSize: '1.5em' }}>
                  If you were previously a part of the All-Access Program as an Extra, please{' '}
                  <u>
                    <a href="/support" target="_blank">
                      click here{' '}
                    </a>
                  </u>
                  to learn more.
                </h3>
                <p className="pt-3" align="center">
                  or
                </p>
                <div className="my-3">
                  <SocialLogin />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showSecondFactorPopup}>
        <Modal.Body>{showSecondFactorPopup ? <SecondFactorAuth otpLen={6} waitingTime={120} data={secondFactorAuthData} /> : ''}</Modal.Body>
      </Modal>
    </div>
  );
};

export default connect(null, { login })(IsLoadingHOC(Login, 'Wait ....'));
