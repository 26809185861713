import React, { useState } from 'react';
import Loader from 'react-loader-spinner';

const Loading = props => {
  return (
    <div className="loaderHolder">
      <div className="loaderMain">
        <Loader type="Circles" color="#CCC" height={80} width={80} />
        {/* <div>{props.message}</div> */}
      </div>
    </div>
  );
};

export const IsLoadingHOC = (WrappedComponent, loadingMessage) => {
  function HOC(props) {
    const [isLoading, setLoading] = useState(false);

    const setLoadingState = isComponentLoading => {
      setLoading(isComponentLoading);
    };

    return (
      <>
        {isLoading && <Loading message={loadingMessage} />}
        <WrappedComponent {...props} setLoading={setLoadingState} />
      </>
    );
  }
  return HOC;
};

export default IsLoadingHOC;
