import React from 'react';
import { Carousel, Container } from 'react-bootstrap';
import { carouselData } from '../data';

const CarouselCustom = () => {
  return (
    <Container className="carouselCustom" fluid>
      <div className="row justify-content-center" id="home">
        <Carousel>
          {carouselData.map((item, index) => {
            return (
              <Carousel.Item key={index}>
                <img className="d-block w-100" src={window.innerWidth > 768 ? item.img : item.imgM} alt={item.title} />
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </Container>
  );
};

export default CarouselCustom;
