import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { connect } from 'react-redux';
import errorHtml from '../../components/Error';
import { resetPassword, resetPasswordTokenValidationCheckAction } from '../../redux/actions';
import { useHistory, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TextField, InputAdornment } from '@material-ui/core';

const ResetPassword = ({ setLoading, resetPassword, location }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const confige = useSelector(state => state.app.config);
  const [passwordConfirm, passwordConfirmSet] = useState('');
  const [password, setPassword] = useState('');
  const [isValidToken, setIsValidToken] = useState(true);
  const [errors, setErrors] = useState({ passwordConfirm: '', password: '' });
  const [token, setToken] = useState('');
  const [isLegacyFirstTime, setIsLegacyFirstTime] = useState(0);
  const [passwordValidation, setPasswordValidation] = useState({});
  let [passwordViewOpt, setPasswordViewOpt] = useState({ password: false, confirmPassword: false });
  useEffect(() => {
    if (confige.uiConfig) {
      if (confige.uiConfig.passwordRule) {
        setPasswordValidation(confige.uiConfig.passwordRule);
      }
    }
  }, [confige, confige.uiConfig]);
  const validatePassword = (value, validationArray) => {
    let isValidPassword = true;
    for (let key in validationArray) {
      if (key === 'minLength') {
        if (value.length < validationArray[key] && value.length !== 0) {
          setErrors({ ...errors, password: `${t('Should not be less then', { count: validationArray[key] })}.` });
          isValidPassword = false;
        }
      }
      if (key === 'maxLength') {
        if (value.length > validationArray[key]) {
          setErrors({ ...errors, password: `${t('Should not be greater then', { count: validationArray[key] })}.` });
          isValidPassword = false;
        }
      }
      if (key === 'mandatory' && validationArray[key]) {
        if (!value.length) {
          setErrors({ ...errors, password: `${t('Should not be empty')}.` });
          isValidPassword = false;
        }
      }
      if (key === 'upperCaseReq' && validationArray[key]) {
        if (value.search(/[A-Z]/) < 0 && value.length !== 0) {
          setErrors({ ...errors, password: `${t('Should have at least one Upper Case latter')}.` });
          isValidPassword = false;
        }
      }
      if (key === 'lowerCaseReq' && validationArray[key]) {
        if (value.search(/[a-z]/) < 0 && value.length !== 0) {
          setErrors({ ...errors, password: `${t('Should have at least one Lower Case latter')}.` });
          isValidPassword = false;
        }
      }
      if (key === 'specialCharReq' && validationArray[key]) {
        // eslint-disable-next-line
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (!format.test(value) && value.length !== 0) {
          setErrors({ ...errors, password: `${t('Should have at least one special letter')}.` });
          isValidPassword = false;
        }
      }
    }
    return isValidPassword;
  };
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    setIsLegacyFirstTime(query.get('lgu') ? parseInt(query.get('lgu')) : 0);
    if (!token) {
      setIsValidToken(false);
    } else {
      setLoading(true);
      resetPasswordTokenValidationCheckAction(token, {})
        .then(data => {
          if (data.httpCode === 404) {
            setIsValidToken(false);
            toast.error(`${t('Your password reset link is invalid')}.`);
          }
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          setIsValidToken(false);
        });
    }
    setToken(token);
    // eslint-disable-next-line
  }, []);

  const onChangeHandle = e => {
    const { name, value } = e.target;
    if (name === 'passwordConfirm') {
      if (password !== value && value.length) {
        setErrors({ ...errors, passwordConfirm: `${t('Password and confirm password should be same')}.` });
      } else {
        value.length < 1 ? setErrors({ ...errors, passwordConfirm: `${t('password')} ${t('Should not be empty')}.` }) : setErrors({ ...errors, passwordConfirm: '' });
      }
      passwordConfirmSet(value);
    }
    if (name === 'password') {
      value.length < 1 ? setErrors({ ...errors, password: `${t('password')} ${t('Should not be empty')}.` }) : setErrors({ ...errors, password: '' });
      setPassword(e.target.value);
    }
  };

  const handleError = () => {
    let value = false;
    const error = {};
    if (password.length < 1) {
      error.password = `${t('password')} ${t('Should not be empty')}.`;
      value = true;
    }
    if (passwordConfirm.length < 1) {
      error.passwordConfirm = `${t('Confirm New Password')} ${t('Should not be empty')}.`;
      value = true;
    }
    if (password !== passwordConfirm && passwordConfirm.length) {
      error.passwordConfirm = `${t('Password and confirm password should be same')}.`;
      value = true;
    } else if (!passwordConfirm.length) {
      error.passwordConfirm = `${t('Confirm New Password')} ${t('Should not be empty')}.`;
      value = true;
    } else {
      error.passwordConfirm = '';
    }
    return { value, error };
  };

  const onSubmit = () => {
    const { value, error } = handleError();
    if (value) {
      setErrors({ ...errors, ...error });
      return false;
    }
    if (validatePassword(password, passwordValidation)) {
      setLoading(true);
      resetPassword({ token, password })
        .then(response => {
          setLoading(false);
          if (isLegacyFirstTime !== 1) {
            toast.success('Your password is reset successfully.');
          }
          history.push('/login');
        })
        .catch(error => {
          console.log(error);
          error.map(item => {
            toast.error(item.message);
          });
          setLoading(false);
        });
    }
  };
  if (!isValidToken) {
    return <Redirect to={'login'} />;
  } else {
    return (
      <div className="page forgot-password-page">
        <div className="container">
          <div className="page-section">
            <h3 className="col-12 text-center forgot-heading-3">{`${t('Reset')} ${t('password')}`}</h3>
            <p className="text-center">{isLegacyFirstTime === 1 ? `As part of the new program you’ll need to change your password! ` : ''}Please follow the below instructions. Once your password is reset you’ll be prompted to login again.</p>
            <div className="row justify-content-center">
              <div className="col-md-6 col-sm-10">
                <div className="pre-info my-5">
                  <p>
                    Use a new, unique password that’s at least 8 characters long and includes these four requirements:
                    <ul>
                      <li>a lowercase letter</li>
                      <li>an UPPERCASE letter</li>
                      <li>a number</li>
                      <li>a special character, like ! @ # $ % ^ &</li>
                    </ul>
                  </p>
                </div>
                <form role="form">
                  <div className="form-group col-md-12 theme-signup-password MuiFilledInput-root-trans">
                    <TextField
                      label=""
                      fullWidth
                      id="filled-start-adornment"
                      type={'password'}
                      data-test="password"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="input-label" position="start">
                            New Password
                          </InputAdornment>
                        ),
                      }}
                      variant="filled"
                      value={password}
                      onChange={onChangeHandle}
                      aria-required="true"
                      aria-autocomplete="password"
                      error={errors?.password?.length > 0 ? true : false}
                      className={errors?.password?.length > 0 ? 'yelloy-text' : 'yelloy-text textfield-bottom-border'}
                      name="password"
                      helperText={errors.password}
                    />
                  </div>
                  <div className="form-group col-md-12 theme-signup-password MuiFilledInput-root-trans">
                    <TextField
                      label=""
                      fullWidth
                      id="filled-start-adornment"
                      type={'password'}
                      data-test="passwordConfirm"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="input-label" position="start">
                            Confirm Password
                          </InputAdornment>
                        ),
                      }}
                      variant="filled"
                      value={passwordConfirm}
                      onChange={onChangeHandle}
                      aria-required="true"
                      aria-autocomplete="passwordConfirm"
                      error={errors?.passwordConfirm?.length > 0 ? true : false}
                      className={errors?.passwordConfirm?.length > 0 ? 'yelloy-text' : 'yelloy-text textfield-bottom-border'}
                      name="passwordConfirm"
                      helperText={errors.passwordConfirm}
                    />
                  </div>
                  <div className="row justify-content-center mt-4">
                    <button onClick={onSubmit} type="button" className="btn btn-primary">
                      {t('Save')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default connect(null, { resetPassword })(IsLoadingHOC(ResetPassword, 'Wait ....'));
