import React from 'react';
import { getManu } from '../../redux/actions';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import CopyRight from '../../components/CopyRight';
import { Link } from 'react-router-dom';
//import { useTranslation } from "react-i18next";
const Footer = () => {
  //const { t } = useTranslation();
  return (
    <footer>
      <Container fluid className="footer">
        <div className="row">
          <div className="col-sm-12">
            <div className="social-links">
              <ul className="footer-social">
                <li>
                  <a href="https://www.facebook.com/UniversalPicturesAllAccess" target="_blank">
                    <FontAwesomeIcon icon={faFacebookF} alt="connect on Facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/UniAllAccess" target="_blank">
                    <FontAwesomeIcon icon={faTwitter} alt="connect on Twitter" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/uniallaccess/" target="_blank">
                    <FontAwesomeIcon icon={faInstagram} alt="connect on Instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="footer-link ml-auto" aria-label="Footer Navigation">
              <ul>
                <li>
                  <Link to="/support">FAQ</Link>
                </li>
                <li>
                  <Link to="/support">Support</Link>
                </li>
                <li>
                  <Link to="/termsofuse">Terms Of Use</Link>
                </li>
                <li id="teconsent">
                  <a id="ot-sdk-btn one-trust" rel="noopener noreferrer" target="_blank" className="ot-sdk-show-settings universal-footer-links one-trust">
                    Cookie Notice
                  </a>
                </li>
                <li>
                  <a href="https://www.nbcuniversal.com/privacy?intake=Universal_Pictures_Home_Entertainment" target="_blank">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="https://www.nbcuniversal.com/privacy/california-consumer-privacy-act?intake=Universal_Pictures_Home_Entertainment" target="_blank">
                    CA NOTICE
                  </a>
                </li>
                <li>
                  <a href="https://www.nbcuniversal.com/privacy/cookies#accordionheader2" target="_blank">
                    AD Choices
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-link ml-auto my-4" aria-label="Footer Navigation">
              <ul>
                <li>
                  <a href="https://www.filmratings.com/" target="_blank">
                    FILM RATINGS
                  </a>
                </li>
                <li>
                  <a href="https://www.motionpictures.org/" target="_blank">
                    MOTION PICTURES ASSOCIATION
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-12 text-center">
            <CopyRight />
          </div>
        </div>
      </Container>
    </footer>
  );
};
export default connect(null, { getManu })(Footer);
