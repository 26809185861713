import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import TopRight from './TopRight';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Menu = ({ data }) => {
  const { t } = useTranslation();
  const user = useSelector(({ user }) => user);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      document.getElementById('navbarSupportedContent').classList.remove('show');
    });
    return window.removeEventListener('scroll', () => {});
  }, []);
  const navslide = () => {
    document.getElementById('navbarSupportedContent').classList.remove('show');
  };

  return (
    <Fragment>
      <button id="button-navbar-toggler" className="navbar-toggler mob-menu-pos order-4" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span id="span-navbar-toggler-icon" className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
          {!user.accessToken ? (
            <>
              <li className="nav-item">
                <Link className="nav-link" id="href-faq" to="/eligible-movies" onClick={navslide}>
                  {t('Eligible Movies')}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="btn menu-btn" id="href-faq" to="/login" onClick={navslide}>
                  {t('Register / Login')}
                </Link>
              </li>
            </>
          ) : (
            <>
              {/*[START:SUBMITCODE]*/}
              <li className="nav-item">
                <Link className="nav-link" id="href-submit-code" to="/validate-code" onClick={navslide}>
                  {t('SUBMIT CODE')}
                </Link>
              </li>
              {/*[END:SUBMITCODE]*/}
              {/*[START:DIGITALMOVIE]*/}
              <li className="nav-item">
                <Link className="nav-link" id="href-digital-movie" to="/manage-account/movies-anywhere" onClick={navslide}>
                  {t('Digital Movie')}
                </Link>
              </li>
              {/*[END:DIGITALMOVIE]*/}
              {/*[START:RECEIPTUPLOAD]*/}
              <li className="nav-item">
                <Link className="nav-link" id="href-receipt-upload" to="/upload-receipt" onClick={navslide}>
                  {t('Receipt Upload ')}
                </Link>
              </li>
              {/*[END:RECEIPTUPLOAD]*/}
              <li className="nav-item">
                <Link className="nav-link" id="href-faq" to="/eligible-movies" onClick={navslide}>
                  {t('Eligible Movies')}
                </Link>
              </li>
              {/*[START:REWARDS]*/}
              <li className="nav-item">
                <Link className="nav-link" id="href-rewards" to="/rewards" onClick={navslide}>
                  {t('Rewards')}
                </Link>
              </li>
              {/*[END:REWARDS]*/}

              <li className="nav-item">
                <Link className="nav-link" id="href-referafriend" to="/referafriend" onClick={navslide}>
                  {t('Refer a Friend ')}
                </Link>
              </li>
              {/* <TopRight /> */}
            </>
          )}
        </ul>
        {/* My All-Access , Points History, My Rewards, Profile, Connect Movie ANYHERE, Logout */}
      </div>
    </Fragment>
  );
};

export default Menu;
