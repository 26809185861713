import React, { useState, useEffect } from 'react';
import WheelEarn from './WheelEarn';
import MysteryBox from './MysteryBox';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getContestPreviewAction } from '../../redux/actions';
import { Container } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
const Contest = props => {
  const [data, setData] = useState({});
  const [apiCallStatus, setApiCallStatus] = useState(false);
  let { id } = useParams();
  let history = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    props.setLoading(true);
    props
      .getContestPreviewAction(id, props.urlType)
      .then(responseData => {
        if (responseData['data']) {
          setData(responseData.data);
          props.setLoading(false);
        } else {
          setData({});
          props.setLoading(false);
          history.push('/home');
          toast.error(`${t('Error')}! ${t('Invalid request')}`);
        }
        setApiCallStatus(true);
      })
      .catch(err => {
        setData({});
        props.setLoading(false);
        setApiCallStatus(true);
        history.push('/home');
        toast.error(`Invalid request`);
        console.log('Response Error:', JSON.stringify(err));
      });
    // eslint-disable-next-line
  }, [id]);
  if (data.contestType === 'mysteryBox') {
    return (
      <div className="">
        <Container>{apiCallStatus ? <MysteryBox data={data} urlType={props.urlType} /> : ''}</Container>
      </div>
    );
  } else {
    return (
      <div className="">
        <Container>{apiCallStatus ? <WheelEarn data={data} urlType={props.urlType} /> : ''}</Container>
      </div>
    );
  }
};
export default connect(null, { getContestPreviewAction })(IsLoadingHOC(Contest, 'Loading....'));
