import React from 'react';
import { Typography, Box, TextField, InputAdornment, MenuItem, Tooltip } from '@material-ui/core';
import { toast } from 'react-toastify';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { connect } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useHistory, Link } from 'react-router-dom';
import { forgetPassword } from '../../redux/actions';
import { Trans, useTranslation } from 'react-i18next';
const ForgetPassword = ({ setLoading, login, forgetPassword }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isEmailSent, setIsEmailSent] = React.useState(false);
  return (
    <div className="page forgot-password-page">
      <div className="container">
        <div className="page-section">
          {isEmailSent ? (
            <>
              <div className="row justify-content-center">
                <img alt="" src="/public/assets/images/email-confirm.svg" />
              </div>
              <div className="row justify-content-center">
                <h1 className="forgot-body-text m-1">An email to reset password was</h1>
              </div>
              <div className="row justify-content-center">
                <h1 className="forgot-body-text m-0">sent to your email. Please check your inbox.</h1>
              </div>
            </>
          ) : (
            <div className="row justify-content-center">
              <h3 className="col-12 text-center forgot-heading-3">Forgot Password</h3>
              <h1 className="col-12 text-center forgot-heading-1 m-0">Don't worry we got you!</h1>
              <div className="col-md-8 col-sm-10">
                <Formik
                  initialValues={{ email: '' }}
                  validationSchema={Yup.object({
                    email: Yup.string().required(`This field is required.`),
                  })}
                  validate={values => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = 'This field is required.';
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    //console.log(values);
                    setLoading(true);
                    forgetPassword(values.email)
                      .then(response => {
                        console.log(response);
                        setSubmitting(false);
                        setLoading(false);
                        //toast.success(response.message);
                        setIsEmailSent(true);
                        // toast.success('Password reset link has been sent to your email. Please check your email.');
                        // history.push('/login');
                      })
                      .catch(error => {
                        setSubmitting(false);
                        setLoading(false);
                        toast.error('Snap! Please provide your registered email/username.');
                        //   error.forEach(item => {
                        //     toast.error(item.message);
                        //   });
                      });
                  }}>
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form role="form">
                      <p className="forgot-body-text mx-5 px-5 pt-3 pb-2  text-center">Please confirm your email address below and we will send you a verification code.</p>
                      <div className="form-group row MuiFilledInput-root-trans input-label-min">
                        <TextField
                          label=""
                          fullWidth
                          id="filled-start-adornment"
                          data-test="email"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment className="input-label" position="start">
                                <Trans>USERNAME OR EMAIL </Trans>
                              </InputAdornment>
                            ),
                          }}
                          variant="filled"
                          value={values?.email}
                          onChange={handleChange}
                          aria-required="true"
                          aria-autocomplete="email"
                          error={errors?.email?.length > 0 ? true : false}
                          className={errors?.email?.length > 0 ? 'yelloy-text' : 'yelloy-text textfield-bottom-border'}
                          name="email"
                          helperText={errors.email}
                        />
                      </div>

                      <div className="col-sm-12 pt-4 text-center mb-5">
                        <button data-test="reset-password-button" type="submit" className="btn btn-primary text-center" disabled={values.email.length > 0 && Object.values(errors).length === 0 && !isSubmitting ? false : true}>
                          <Trans>Submit</Trans>
                        </button>
                      </div>
                      {/* <div className="text-center">
                      <Link to="/login" className="text-link-plain">
                        <Trans>Back to login</Trans>
                      </Link>
                    </div> */}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(null, { forgetPassword })(IsLoadingHOC(ForgetPassword, 'Wait ....'));
